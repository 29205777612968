import { RestClientInstance, HttpMethod, NordicRequestConfig } from "nordic/restclient"

import type { HttpClient } from "./types"

import ApiConfigurator from "./ApiConfigurator";

import Middlewares from "./Middlewares";

import ApiResponse from "./ApiResponse";

export default class Service implements HttpClient {
    private repository: RestClientInstance;
    private middlewares: Middlewares

    constructor() {
        this.middlewares = new Middlewares();
        const apiConfig = new ApiConfigurator();
        const getConfig = apiConfig.getConfig();
        this.repository = apiConfig.setConfig(getConfig);
    }

    private validateRoute(route: string): void {
        if (!route) {
            throw (ApiResponse.serverError({ message: "Rota não informada" }));
        }
    }
  
    public async doGet(route: string): Promise<HttpMethod> {
        try {
            this.validateRoute(route);
            
            const middlewares = this.middlewares.getMiddlewares() as unknown;
            const response = await this.repository.get(route, middlewares as NordicRequestConfig);
            return ApiResponse.handleSideError(response.data) as HttpMethod;
        } catch (error) {
            throw (ApiResponse.serverError(error as Error));
        }
    }

    public async doPost(route: string, data: {}): Promise<HttpMethod> {
        try {
            this.validateRoute(route);

            const middlewares = this.middlewares.getMiddlewares(data) as unknown;
            const response = await this.repository.post(route, middlewares as NordicRequestConfig);
            return ApiResponse.handleSideError(response.data) as HttpMethod;
        } catch (error) {
            throw (ApiResponse.serverError(error as Error));
        }
    }
}