import PropsSettings from "./PropsSettings";

import type {
    ContainerData,
    PropsDataSettingReturn,
    FlexData,
    RowData,
    ColData,
    BreakColProps
} from "./types";

const Container: React.FC<ContainerData> = ({ children, ...props }) => {
    const propsSetting = new PropsSettings(props);
    const {
        className,
        widthClass
    }: PropsDataSettingReturn = propsSetting.getProps();

    return (
        <div className={`container${widthClass} ${className}`}>{children}</div>
    );
};

const Flex: React.FC<FlexData> = ({ children, ...props }) => {
    const propsSetting = new PropsSettings(props);
    const {
        className,
        direction,
        gap,
        wrapClass,
        justifyClass,
        alignClass,
        alignSelfClass,
        fullWidthClass,
    }: PropsDataSettingReturn = propsSetting.getProps();

    return (
        <div
            className={`d-flex flex-${direction} flex-${wrapClass} 
            gap-${gap} ${justifyClass} ${alignClass} ${alignSelfClass} ${fullWidthClass} ${className}`}
        >
            {children}
        </div>
    );
};

const Row: React.FC<RowData> = ({ children, ...props }) => {
    const propsSetting = new PropsSettings(props);
    const {
        className,
        gap,
        justify, 
        align
    }: PropsDataSettingReturn = propsSetting.getProps();

    return (
        <div
            className={`row g-${gap} justify-content-${justify} align-items-${align} ${className}`}
        >
            {children}
        </div>
    );
};

const Col: React.FC<ColData> = ({ children, ...props }) => {
    const propsSetting = new PropsSettings(props);
    const {
        className,
        colClass,
        smClass,
        mdClass, 
        lgClass,
        xlClass,
        xxlClass,
        alignSelfClass
    }: PropsDataSettingReturn = propsSetting.getProps();

    return (
        <div
            className={`${colClass} ${smClass} ${mdClass} ${lgClass} ${xlClass} 
            ${xxlClass} ${alignSelfClass} ${className}`}
            onClick={props.onClick}
        >
            {children}
        </div>
    );
};

const BreakCol: React.FC<BreakColProps> = ({ ...props }) => (
    <div className={`w-100 ${props?.className}`} data-testid="break-col"/>
);

export { Container, Flex, Row, Col, BreakCol };
