import { Button } from "@andes/button";
import { Text } from "@andes/typography";
import StarFilled24 from "@andes/icons/StarFilled24";

import { Flex } from "../grid";

import { FC, MouseEvent, useState } from "react";
import { RatingStarsProps } from "./types";

import "./styles.scss";

const NUMBER_OF_STARS = 5;

const RatingStars: FC<RatingStarsProps> = ({ setUserRated }) => {
  const [isBtnActive, setIsBtnActive] = useState(
    Array.from({ length: NUMBER_OF_STARS }, () => false)
  );

  const handleRating = (index: number, e?: MouseEvent) => {
    const ratingNote = NUMBER_OF_STARS - index;

    const newState: Array<boolean> = [];

    [...Array(NUMBER_OF_STARS)].forEach((_, i) => {
      if (i === index) {
        if (isBtnActive[index - 1]) {
          newState[i] = true;
        } else {
          newState[i] = !isBtnActive[i];
        }

        return;
      }

      newState[i] = i > index;
    });

    setIsBtnActive(newState);

    if (setUserRated) {
      setUserRated(ratingNote || false);
    }

    if (e?.currentTarget instanceof HTMLButtonElement) {
      e.currentTarget.blur();
    }
  };

  return (
    <>
      <Flex
        direction="row-reverse"
        gap={4}
        align="center"
        justify="between"
        fullWidth
        className="ratingContainer justify-content-md-center gap-lg-5"
      >
        {[...Array(5)].map((_, index) => (
          <Button
            key={index}
            hierarchy="transparent"
            size="large"
            className={[
              "p-1 h-auto ratingBtn",
              isBtnActive[index] ? "active" : null,
            ].join(" ")}
            onClick={(e: MouseEvent) => handleRating(index, e)}
          >
            <StarFilled24 color="secondary" />
          </Button>
        ))}
      </Flex>

      <Flex
        direction="row"
        justify="between"
        gap={2}
        fullWidth
        className="justify-content-md-evenly px-1 px-md-0 px-xl-5"
      >
        <Button
          hierarchy="transparent"
          size="small"
          onClick={() => handleRating(4)}
        >
          <Text size="s" color="secondary">
            Ruim
          </Text>
        </Button>

        <Button
          hierarchy="transparent"
          size="small"
          onClick={() => handleRating(0)}
        >
          <Text size="s" color="secondary">
            Excelente
          </Text>
        </Button>
      </Flex>
    </>
  );
};

export default RatingStars;
