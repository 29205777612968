import { type FC, type RefObject, useEffect, useState, memo } from "react";

import ReCAPTCHA from "react-google-recaptcha";

interface RecaptchaProps {
  readonly siteKey?: string;
  readonly recaptchaRef: RefObject<ReCAPTCHA>;
}

const Recaptcha: FC<RecaptchaProps> = ({ siteKey, recaptchaRef }) => {
  const [recaptchaKey, setRecaptchaKey] = useState<string>("");

  useEffect(() => {
    if (!recaptchaKey && siteKey) {
      setRecaptchaKey(siteKey);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      recaptchaRef.current?.reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaKey, siteKey]);

  return (
    recaptchaKey && (
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaKey} />
    )
  );
};

export default memo(Recaptcha);
