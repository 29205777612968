import type { InformativeCardProps } from "./types";
import {useCallback, useState} from "react";

import { useI18n } from "nordic/i18n";

import Card, { CardContent } from "@andes/card";
import TextField from "@andes/textfield";
import Typography, { Title, Text } from "@andes/typography";
import { ProgressButton } from "@andes/button";

import { Flex } from "../../../../components/grid";
import {useAtom} from "jotai/index";
import {snackbarStateAtom, SnackbarStateProps} from "../../../../states/snackbarState";
import {DataFetchService} from "../../../../services/types";
import {DataUrls} from "./enum";
import applyMask from "../../../../utils/mask";
import Loading from "../../../../components/loading";

const InformativeCard = (props: InformativeCardProps) => {
  const { i18n } = useI18n();

  const [isLoading, setIsLoading] = useState(false);

  const {
    titleMessage,
    fullName,
    email,
    phoneNumber,
    address,
    buttonMessage,
    showTerms = false,
    document
  } = props;

  const [_, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);
  const showSnackbarErrors = useCallback(
      (error: unknown) => {
        const getError = error as DataFetchService;
        let message = "";

        if (getError?.exception) {
          message = getError?.exception?.message;
        } else if (error instanceof Error) {
          message = error.message;
        }

        setSnackbar((prev) => ({
          ...prev,
          message,
          color: "red",
          show: true,
        }));
      },
      [setSnackbar]
  );

  const handleSaveMatch = async () => {
    setIsLoading(true);

    try {
        // We are calling the PHP endpoint here, which performs the necessary validations.
        // A small delay (setTimeout) is introduced to ensure that the React state (isLoading)
        // is properly updated before the browser redirects to the new URL.
        // This prevents React from losing control of the component's lifecycle during the redirect.
        setTimeout(() => {
            window.location.assign(showTerms ? DataUrls.CREATE_ACCOUNT : DataUrls.SAVE_MATCH);
        }, 100);
    } catch (error) {
      showSnackbarErrors(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
          <div className='loading'>
            <Loading id="loading" ariaLabel="Carregando" show={isLoading}/>
          </div>
    );
  }

  return (
      <>{!isLoading && <Card paddingSize={24} className="card-compareData">
          <CardContent>
            <Title component="h3" className="mb-4">
              {i18n.gettext(titleMessage)}
            </Title>

            <Flex gap={3} direction="column">
              <Flex justify="between" direction="column">
                {fullName && (
                    <TextField
                        label="Nome completo"
                        modifier="readonly"
                        value={fullName}
                    />
                )}
              </Flex>
              <Flex justify="between" direction="column">
                {email && (
                    <TextField
                        label="E-mail"
                        modifier="readonly"
                        value={email}
                    />
                )}
              </Flex>

              <Flex justify="between" wrap="wrap">
                {document && (
                    <TextField ma label="CPF / CNPJ" modifier="readonly" value={applyMask({value: document})}/>
                )}

                {phoneNumber && (
                    <TextField
                        label="Telefone"
                        modifier="readonly"
                        value={phoneNumber}
                    />
                )}
              </Flex>

              {address && (
                  <TextField label="Endereço" modifier="readonly" value={address}/>
              )}
            </Flex>

            <Flex gap={3} direction="column" className="mt-3">
              {showTerms && (
                  <Text size="s">
                    {i18n.gettext('Ao clicar em "Entendido", aceito os ')}
                    <Typography
                        component="a"
                        color="link"
                        href="https://www.kangu.com.br/termos-de-uso/"
                        target="_blank"
                        size="s"
                    >
                      {i18n.gettext("Termos e condições")}
                    </Typography>
                    {i18n.gettext(
                        " e autorizo o uso dos meus dados de acordo com a "
                    )}
                    <Typography
                        component="a"
                        color="link"
                        href="https://www.kangu.com.br/wp-content/themes/Kangu/assets/images/Pol%C3%ADtica%20de%20Privacidade.pdf"
                        target="_blank"
                        size="s"
                    >
                      {i18n.gettext("Política de privacidade")}
                    </Typography>
                  </Text>
              )}

              <ProgressButton
                  loading={isLoading}
                  fullWidth
                  onClick={() => handleSaveMatch()}
                  className="mt-2"
              >
                {i18n.gettext(buttonMessage)}
              </ProgressButton>
            </Flex>
          </CardContent>
        </Card>}

      </>
  );
};

export default InformativeCard;
