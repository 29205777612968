import { type FC, memo, useCallback, useRef, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { useSetAtom } from "jotai";

import { zodResolver } from "@hookform/resolvers/zod";

import { useI18n } from "nordic/i18n";

import { Card, CardContent } from "@andes/card";
import { ProgressButton, Button } from "@andes/button";
import { Text } from "@andes/typography";
import { Password } from "@andes/textfield";
import { Form } from "@andes/form";
import TextField from "@andes/textfield";

import { Col, Flex, Row } from "../../components/grid";

import { DataFetchService } from "../../services/types";
import { checkBondMeliAction } from "../../pages/login/controller";
import { snackbarStateAtom } from "../../states/snackbarState";
import {
  checkBondMeliSchema,
  type CheckBondMeliSchemaType,
} from "../../schemas/login/checkBondMeli";

import {
  loginSchema,
  type LoginSchemaType,
} from "../../schemas/loginMeli/login/login";

import { get, remove } from "../../utils/cookies";

import { loginAction } from "../../pages/loginMeli/login/controller";

import { stepsStateAtom } from "../../states/steps";

import Recaptcha from "../../components/recaptcha";

import type { LoginResponseData } from "../../services/loginMeli/login/types";

import type { FirstStepProps } from "./firstStep";

import type ReCAPTCHA from "react-google-recaptcha";

interface CardLoginSellerProps extends FirstStepProps {
  readonly siteKey?: string;
  readonly setRedirectMeli: (redirectMeli: boolean) => void;
}

const enableExperienceMeli = !!get("enable_experience_meli")!;

const CardLoginSeller: FC<CardLoginSellerProps> = ({
  siteKey,
  currentUser,
  setCurrentUser,
  setResponseLogin,
  setRedirectMeli,
}) => {
  const { i18n } = useI18n();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeoutButton, setTimeoutButton] = useState<number | undefined>(undefined);

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const setStep = useSetAtom(stepsStateAtom);

  const setSnackbar = useSetAtom(snackbarStateAtom);

  const {
    formState: formStateCheckbond,
    handleSubmit: handleSubmitCheckbond,
    register: registerCheckbond,
  } = useForm<CheckBondMeliSchemaType>({
    resolver: zodResolver(checkBondMeliSchema),
  });

  const { formState, handleSubmit, clearErrors, setValue } =
    useForm<LoginSchemaType>({
      resolver: zodResolver(loginSchema),
    });

  const setValuePassword = (e: { target: { value: string } }) => {
    const { value } = e.target;

    if (value) {
      clearErrors("password");
    }

    setValue("password", value);
  };

  const showSnackbarErrors = useCallback(
    (error: unknown) => {
      const getError = error as DataFetchService;
      let message = "";

      setIsLoading(false);

      if (getError?.exception) {
        message = getError?.exception?.message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setSnackbar((prev) => ({
        ...prev,
        message,
        color: "red",
        show: true,
      }));
    },
    [setSnackbar]
  );

  const onSubmit: SubmitHandler<CheckBondMeliSchemaType> = async (data) => {
    setIsLoading(true);

    const response = await checkBondMeliAction({ data, showSnackbarErrors });

    if (response !== null && response === false) {
      setCurrentUser(data.email);
      setValue("user", data.email);
    } else if (response === true) {
      setRedirectMeli(true);
    }

    setIsLoading(false);
  };

  const handleLogged = (): void => {
    const params = new URLSearchParams(document.location.search);
    let url = params.get("success") || "/";

    setTimeoutButton(3000);

    if (get("successRedirectUrl")) {
      url = get("successRedirectUrl", true)!;
    }

    window.location.href = url;
    remove("utm_resource");
    remove("utm_cs");
  };

  const handleMfaEnable = (user: string, response: LoginResponseData): void => {
    setIsLoading(false);
    setCurrentUser(user);
    setResponseLogin(response);
    setStep({ current: 2 });
  };

  const handleRedirectError = (isRedirectMeli?: boolean) => {
    setIsLoading(false);
    if (isRedirectMeli && setRedirectMeli) {
      setRedirectMeli(true);
    }
  };

  const onSubmitLogin: SubmitHandler<LoginSchemaType> = async (data) => {
    setIsLoading(true);

    const tokenRecaptcha = (await recaptchaRef.current?.executeAsync()) || "";

    await loginAction({
      data,
      handleLogged,
      handleMfaEnable,
      handleRedirectError,
      showSnackbarErrors,
      tokenRecaptcha,
    });
  };

  return (
    <Form
      onSubmit={
        !currentUser
          ? handleSubmitCheckbond(onSubmit)
          : handleSubmit(onSubmitLogin)
      }
    >
      <Recaptcha siteKey={siteKey} recaptchaRef={recaptchaRef} />
      <Card className="mb-4 p-2 shadow-sm">
        <CardContent>
          <Row align="center">
            <Col className="mt-4" size={12}>
              <Flex direction="column">
                {enableExperienceMeli && (
                  <Text>
                    {i18n.gettext(
                      "Se você ainda não vinculou suas contas Kangu e Mercado Livre, informe seu usuário."
                    )}
                  </Text>
                )}
                {!currentUser ? (
                  <TextField
                    label="E-mail"
                    placeholder="Insira seu e-mail"
                    modifier={
                      formStateCheckbond.errors.email ? "error" : undefined
                    }
                    helper={
                      formStateCheckbond.errors.email?.message as
                        | string
                        | undefined
                    }
                    {...registerCheckbond("email")}
                  />
                ) : (
                  <Password
                    label="Senha"
                    placeholder="Insira sua senha"
                    showPasswordLabel="Mostrar"
                    hidePasswordLabel="Ocultar"
                    modifier={formState.errors.password ? "error" : undefined}
                    helper={formState.errors.password?.message}
                    showHide
                    onChange={setValuePassword}
                  />
                )}
                <Row className="pt-1">
                  <Col size={12}>
                    <ProgressButton
                      hierarchy="loud"
                      className="rounded w-100"
                      progressLabel="Acessando..."
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      timeout={timeoutButton}
                    >
                      {i18n.gettext("Acessar")}
                    </ProgressButton>
                  </Col>
                  <Col size={12} className="mt-1">
                    {!currentUser ? (
                      <Button
                        hierarchy="transparent"
                        className="rounded w-100 px-0"
                        onClick={() => {
                          window.location.href =
                            "https://www.kangu.com.br/cadastro-seller/";
                        }}
                      >
                        {i18n.gettext("Criar conta")}
                      </Button>
                    ) : (
                      <Button
                        hierarchy="transparent"
                        className="rounded w-100 px-0"
                        onClick={() => {
                          window.location.href = "/user/forget-pass";
                        }}
                      >
                        {i18n.gettext("Esqueci a senha")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Flex>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </Form>
  );
};

export default memo(CardLoginSeller);
