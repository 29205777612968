const disableMenu = (document: Document | null) => {
  if (!document) {
    return;
  }

  const asideMenu = document.querySelector("aside#menu");

  asideMenu
    ?.querySelectorAll("a, button, [href], [t-href], .group-item")
    .forEach((link) => {
      link.setAttribute("disabled", "true");
      link.setAttribute("t-href", "");
      link.removeAttribute("href");
      link.removeAttribute("onclick");
    });

  asideMenu
    ?.querySelector(".aside-menu")
    ?.setAttribute(
      "style",
      "opacity: 0.7 !important; pointer-events: none !important;"
    );
};

const disableNavbar = (document: Document | null) => {
  if (!document) {
    return;
  }

  const navbar = document.querySelector("nav.navbar");

  navbar
    ?.querySelectorAll(
      ":scope > *:not(#nav-header-menu-open, #chat-button, #navbar-header-screen-name)"
    )
    .forEach((item) => {
      item?.setAttribute(
        "style",
        "opacity: 0.7 !important; pointer-events: none !important;"
      );
    });
};

export { disableMenu, disableNavbar };
