import { useEffect, useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import Card, { CardContent } from "@andes/card";
import { Text, Title } from "@andes/typography";
import { Button } from "@andes/button";

import { Container, Flex } from "../../components/grid";
import SendDocSvg from "../../assets/images/sendDoc.svg";

import "./styles.scss";
import { GetInformationData } from "../../services/upgradeNivel/types";
import { getInformation } from "../upgradeNivel/controller";

const Index: FC = () => {
  const { i18n } = useI18n();

  const [days, setDays] = useState<number>(1);

  const getInformationSeller = async (): Promise<GetInformationData> => {
    const response = await getInformation();
    const respondeData = response?.data;

    const daysSolicitation =
      respondeData.daysSolicitation &&
      !isNaN(Number(respondeData.daysSolicitation))
        ? Number(respondeData.daysSolicitation)
        : 0;

    setDays(daysSolicitation);

    return respondeData;
  };

  useEffect(() => {
    getInformationSeller();
  }, []);

  return (
    <Flex align="center" justify="center" fullWidth className="mt-4">
      <Container className="p-4 px-md-5 custom-size">
        <Card>
          <CardContent className="py-4">
            <Flex direction="column" justify="center" align="center">
              <img src={SendDocSvg} alt="Documento enviado" />
              <Title className="mt-4 fw-bolder">
                {i18n.gettext("Seus documentos foram enviados!")}
              </Title>
              <Text className="mt-2 ps-4 pe-3 text-center lh-base" size="m">
                {i18n.gettext(
                  `Sua solicitação vai ser analisada em até ${days} ${
                    days > 1 ? "dias úteis" : "dia útil"
                  } e se aprovado você já pode conferir seu novo limite de envio simultâneo.`
                )}
              </Text>
              <Button
                hierarchy="quiet"
                className="mt-2 mb-4 borderRadius"
                size="medium"
                href="/seller/dashboard/"
              >
                {i18n.gettext("Voltar para home")}
              </Button>
            </Flex>
          </CardContent>
        </Card>
      </Container>
    </Flex>
  );
};

export default Index;
