import { useEffect, useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import { Container } from "../../components/grid";

import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";
import { Button } from "@andes/button";
import { Wizard } from "react-use-wizard";
import { disableMenu, disableNavbar } from "../../utils/handlePortalNavigation";

import ShippingType from "../../containers/onboarding/shippingType";
import PersonType from "../../containers/onboarding/personType";
import PersonData from "../../containers/onboarding/personData";
import Summary from "../../containers/onboarding/summary";

import {
  getOnboardingAvailability,
  getOnboardingProgress,
  saveOnboardingProgress,
} from "./controller";
import type {
  OnboardingProgressProps,
  OnboardingResponseProps,
} from "../../services/onboarding/types";

import {
  onboardingStateAtom,
  OnboardingStateProps,
} from "../../states/onboarding";
import { useAtom } from "jotai";

import { ShippingTypeEnum } from "../../containers/onboarding/shippingType/enum";
import { PersonTypeEnum } from "../../containers/onboarding/personType/enum";
import fixPortalReactCSS from "../../utils/fixPortalReactCSS";

import "./styles.scss";

const Onboarding: FC = () => {
  const { i18n } = useI18n();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [onboardingState, setOnboardingState] =
    useAtom<OnboardingStateProps>(onboardingStateAtom);

  const retrieveOnboardingAvailability = async () => {
    try {
      const { data }: OnboardingResponseProps =
        await getOnboardingAvailability();

      return data?.success;
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveOnboardingProgress = async () => {
    try {
      const { data }: OnboardingProgressProps = await getOnboardingProgress();

      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnboardingProgress = async () => {
    try {
      const isOnboardingAvailable =
        (await retrieveOnboardingAvailability()) || false;

      if (!isOnboardingAvailable) {
        window.location.href = "/seller/dashboard";
        return;
      }

      const onboardingProgress = await retrieveOnboardingProgress();

      setOnboardingState((prevState) => ({
        ...prevState,
        stepIndex: onboardingProgress?.step || 0,
        displayFooter: onboardingProgress?.step !== 3,
        shippingType:
          onboardingProgress?.st_declaracao &&
          ShippingTypeEnum[
            onboardingProgress?.st_declaracao === "S" ? "DC" : "NF"
          ],
        personType:
          onboardingProgress?.tp_perfil &&
          PersonTypeEnum[onboardingProgress?.tp_perfil],
        personData: {
          ...prevState.personData,
          cnpj: onboardingProgress?.doc,
          razaoSocial: onboardingProgress?.razao_social,
          cpf:
            onboardingProgress?.tp_perfil === PersonTypeEnum.CNPJ ||
            onboardingProgress?.tp_perfil === PersonTypeEnum.MEI
              ? onboardingProgress?.doc_resp
              : onboardingProgress?.doc,
          dataNascimento:
            onboardingProgress?.tp_perfil === PersonTypeEnum.CNPJ ||
            onboardingProgress?.tp_perfil === PersonTypeEnum.MEI
              ? onboardingProgress?.dt_nasc_resp
              : onboardingProgress?.dt_nasc,
        },
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveProgressAndExit = async () => {
    try {
      setButtonLoading(true);

      const { cnpj, razaoSocial, cpf, dataNascimento } =
        onboardingState.personData || {};

      const onboardingProgress: OnboardingProgressProps = {
        data: {
          step: onboardingState.stepIndex,
          st_declaracao:
            onboardingState.personType === PersonTypeEnum.CNPJ ? "N" : "S",
          tp_perfil: onboardingState.personType,
          doc:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? cnpj
              : cpf,
          razao_social: razaoSocial,
          dt_nasc: dataNascimento,
          doc_resp:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? cpf
              : undefined,
          dt_nasc_resp:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? dataNascimento
              : undefined,
        },
      };

      await saveOnboardingProgress(onboardingProgress);

      window.location.href = "/seller/dashboard";
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSuccessParam = () => {
    const params = new URLSearchParams(document.location.search);
    const redirectUrl = params.get("success")?.replace("http://", "https://");

    localStorage.setItem("success", redirectUrl || "");
  };

  useEffect(() => {
    handleSuccessParam();
    disableMenu(document);
    disableNavbar(document);

    fixPortalReactCSS(true, "onboarding");

    handleOnboardingProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (onboardingState.stepIndex === undefined) {
    return (
      <ProgressIndicatorCircular
        className="onboarding-progress-indicator"
        srLabel={i18n.gettext(
          "Buscando informações de progresso do onboarding"
        )}
        srAnnouncement={i18n.gettext("Carregando...")}
      />
    );
  }

  return (
    <div className="onboarding p-3">
      <Container className="onboarding-container">
        <Wizard startIndex={onboardingState.stepIndex}>
          <ShippingType />
          <PersonType />
          <PersonData />
          <Summary />
        </Wizard>
      </Container>

      <div className="onboarding-footer">
        {onboardingState.displayFooter && (
          <Button
            loading={buttonLoading}
            size="medium"
            hierarchy="transparent"
            onClick={() => handleSaveProgressAndExit()}
            srAnnouncement={i18n.gettext("Carregando...")}
          >
            {i18n.gettext("Preencher depois")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
