import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import RedirectMessage from "../../components/redirectMessage";
import { getErrorMessage } from "./erroMessages";

import erroIcon from "../../assets/images/error_authorized_connection.svg";

export const ErroMatchContent: FC = () => {
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get("code");

  const { title, text, link, button } = errorCode
    ? getErrorMessage(errorCode)
    : getErrorMessage("");

  return (
    <RedirectMessage
      titleMessage={title}
      middleMessage={text}
      buttonMessage={button}
      action={() => (window.location.href = link)}
      iconImage={erroIcon}
      codeError={errorCode as string}
    />
  );
};
