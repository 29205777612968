import { FieldsForAddressType } from "../../schemas/onboarding/address";
import OnboardingService from "../../services/onboarding";

import type {
  OnboardingProgressProps,
  OnboardingUserMeliAddressProps,
  OnboardingValidatePersonZipcodeProps,
  OnboardingSavePersonAddressProps,
} from "../../services/onboarding/types";

export const getOnboardingProgress =
  async (): Promise<OnboardingProgressProps> => {
    const response = await OnboardingService.getProgress();

    return response;
  };

export const getUserMeliAddress =
  async (): Promise<OnboardingUserMeliAddressProps> => {
    const response = await OnboardingService.getUserMeliAddress();

    return response;
  };

export const validatePersonZipcode = async (
  zipcode: string
): Promise<OnboardingValidatePersonZipcodeProps> => {
  const response = await OnboardingService.validatePersonZipcode(zipcode);

  return response;
};

export const savePersonAddress = async (
  data: FieldsForAddressType
): Promise<OnboardingSavePersonAddressProps> => {
  const response = await OnboardingService.savePersonAddress(data);

  return response;
};
