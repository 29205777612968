import React from 'react'

import { Pill } from '@andes/badge';
import FeedbackPositive12 from '@andes/icons/FeedbackPositive12';

const PillMfa = () => {
  return (
    <Pill
        color='green'
        size='small'
        hierarchy='quiet'
        contentType='icon'
    >
        <FeedbackPositive12 />
    </Pill>
  )
}

export default PillMfa