import { type FC, memo } from "react";

import Card, { CardContent } from "@andes/card";
import { Text } from "@andes/typography";
import { useI18n } from "nordic/i18n";

import { Col, Row } from "../../components/grid";

import type { CardInformativeProps } from "./types";

type KEYS_WORDS = "dia" | "útil";

const DICTONARY_WORD_PLURAL = {
  dia: "dias",
  útil: "úteis",
};

function getPlural(word: string, daysSolicitation?: string): string {
  if (!daysSolicitation) {
    return word;
  }

  return Number(daysSolicitation) > 1
    ? DICTONARY_WORD_PLURAL[word as KEYS_WORDS]
    : word;
}

const CardInformative: FC<CardInformativeProps> = ({ data }) => {
  const { i18n } = useI18n();

  return (
    <Card className="mb-4 py-3 px-md-5 px-3">
      <CardContent>
        <Row align="center">
          <Col>
            <Row>
              <Text className="custom-text">
                {i18n.gettext(
                  "Existe um limite na quantidade de envios simultâneos, ou seja, aqueles pedidos que foram pagos mas ainda não postados."
                )}
                <b>
                  {i18n.gettext(
                    " Esse limite existe para reforçar a sua segurança e a do seu negócio"
                  )}
                </b>
                {i18n.gettext(", mas ")}
                <b>
                  {i18n.gettext("você pode solicitar o aumento do seu limite")}
                </b>
                {i18n.gettext(" seguindo o passo a passo:")}
              </Text>
              <Text className="custom-text">
                {i18n.gettext(
                  "1. Anexe os documentos requeridos e uma foto com o seu documento"
                )}
              </Text>
              <Text className="m-0 custom-text">
                {i18n.gettext(
                  " 2. Nos conte o motivo pelo qual você está solicitando seu aumento de limites de envios simultâneos, clique em solicitar e pronto!"
                )}
              </Text>
              <Text className="custom-text">
                {i18n.gettext("Em ")}
                <b>
                  {i18n.gettext("até ")}{" "}
                  {data?.daysSolicitation || i18n.gettext("XX")}{" "}
                  {getPlural("dia", data?.daysSolicitation)}{" "}
                  {getPlural("útil", data?.daysSolicitation)}{" "}
                  {i18n.gettext("sua solicitação é verificada")}
                </b>
                {i18n.gettext(" e caso seja aprovada você")}
                <b>
                  {i18n.gettext(
                    " pode aproveitar seu novo limite de envios simultâneos no Portal Kangu"
                  )}
                </b>
                {i18n.gettext(
                  ". Qualquer dúvida pode entrar em contato com nosso suporte"
                )}
              </Text>
            </Row>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardInformative);
