import { useState, useCallback } from "react";
import { useForm, FieldValues, SubmitHandler } from "react-hook-form";
import { useQuery } from "react-query";

import Card, { CardContent } from "@andes/card";
import Typography from "@andes/typography";
import Textfield from "@andes/textfield";
import Button from "@andes/button";
import Form from "@andes/form";

import { getDataProfile, saveProfile } from "../../pages/myAccess/controller";

import { Col, Flex, Row } from "../../components/grid";
import Avatar from "../../components/avatar";

import { isValidEmail } from "../../constants/validation";

import type {
  DataProfileChange,
  ResponseSaveProfile,
  ProfileChange,
} from "./types";

import type { DataFetchService } from "../../services/types";
import { useAtom } from "jotai";
import {
  type SnackbarStateProps,
  snackbarStateAtom,
} from "../../states/snackbarState";
import { sanitizeData } from "../../utils/sanitizeData";

export const Profile: React.FC = () => {
  const [selectedAvatar, setSelectedAvatar] = useState<
    string | null | ArrayBuffer
  >(null);
  const [avatarChanged, setAvatarChanged] = useState<
    string | null | ArrayBuffer
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modifierName, setModifierName] = useState<string>("");
  const [helperName, setHelperName] = useState<string>("");
  const [modifierEmail, setModifierEmail] = useState<string>("");
  const [helperEmail, setHelperEmail] = useState<string>("");
  const [modifierCargo, setModifierCargo] = useState<string>("");
  const [helperCargo, setHelperCargo] = useState<string>("");
  const { register, handleSubmit, setValue } = useForm();

  const [snackbar, setSnackbar] =
    useAtom<SnackbarStateProps>(snackbarStateAtom);

  const ShowMe = useCallback(async (): Promise<void> => {
    const { data }: DataProfileChange = await getDataProfile();

    if (data) {
      setValue("descricao", data?.descricao);
      setValue("avatar", data?.avatar);
      setValue("email", data?.email);
      setValue("funcao", data?.funcao);
      setSelectedAvatar(data?.avatar);
    }
  }, [setValue]);

  const validateName = (data: ProfileChange): boolean => {
    if (!data.descricao) {
      setModifierName("error");
      setHelperName("Necessário informar nome");
      setValue("descricao", "");

      return false;
    }

    setModifierName("");
    setHelperName("");

    return true;
  };

  const validateEmail = (data: ProfileChange): boolean => {
    if (!data.email) {
      setModifierEmail("error");
      setHelperEmail("Necessário informar e-mail");
      setValue("email", "");

      return false;
    }

    if (!isValidEmail(data.email)) {
      setModifierEmail("error");
      setHelperEmail("Necessário informar um e-mail válido");
      setValue("email", "");

      return false;
    }

    setModifierEmail("");
    setHelperEmail("");

    return true;
  };

  const valiteCargo = (data: ProfileChange): boolean => {
    if (!data.funcao) {
      setModifierCargo("error");
      setHelperCargo("Necessário informar Cargo");
      setValue("funcao", "");

      return false;
    }

    setModifierCargo("");
    setHelperCargo("");

    return true;
  };

  const tratamentData = (data: ProfileChange): void => {
    const { descricao, email, funcao } = data;

    data.descricao = descricao ? descricao.trim() : "";
    data.email = email ? email.trim() : "";
    data.funcao = funcao ? funcao.trim() : "";
  };

  const handleProfileChange: SubmitHandler<FieldValues> = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);

        data = sanitizeData(data);

        tratamentData(data as ProfileChange);

        if (!validateName(data as ProfileChange)) {
          return;
        }

        if (!validateEmail(data as ProfileChange)) {
          return;
        }

        if (!valiteCargo(data as ProfileChange)) {
          return;
        }

        if (avatarChanged) {
          data.avatar = avatarChanged;
        } else {
          delete data.avatar;
        }

        const response: ResponseSaveProfile = await saveProfile(
          data as ProfileChange
        );

        if (response?.error) {
          const message: string | undefined = response?.exception?.message;

          if (message) {
            setSnackbar({
              ...snackbar,
              message,
              color: "red",
              show: true,
            });
          }
        }

        if (response?.success) {
          const message: string | undefined = response?.data?.message;

          if (message) {
            setSnackbar({
              ...snackbar,
              message,
              color: "green",
              show: true,
            });
          }
        }
      } catch (error: unknown) {
        const getError = error as DataFetchService;

        if (getError?.exception) {
          setSnackbar({
            ...snackbar,
            message: getError?.exception?.message,
            color: "red",
            show: true,
          });
        } else if (error instanceof Error) {
          setSnackbar({
            ...snackbar,
            message: error?.message,
            color: "red",
            show: true,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [avatarChanged, setSnackbar, snackbar]
  );

  useQuery<void>("showMeFetch", ShowMe, {
    staleTime: 100000, //10 minutes
  });

  return (
    <>
      <Card className="mb-4">
        <CardContent className="pb-2">
          <Row gap={3} justify="center">
            <Col size={12}>
              <Typography weight="semibold" size="l">
                Perfil
              </Typography>
            </Col>
          </Row>
          <Flex justify="center">
            <Avatar
              selectedAvatar={selectedAvatar}
              setSelectedAvatar={setSelectedAvatar}
              setAvatarChanged={setAvatarChanged}
            />
          </Flex>
          <Form onSubmit={handleSubmit(handleProfileChange)}>
            <Row align="center" gap={3}>
              <Col size={12} md>
                {modifierName ? (
                  <Textfield
                    className="flex-fill"
                    label="Nome"
                    placeholder="Nome"
                    modifier={modifierName}
                    helper={helperName}
                    {...register("descricao")}
                  />
                ) : (
                  <Textfield
                    className="flex-fill pb-md-4"
                    label="Nome"
                    placeholder="Nome"
                    helper={helperName}
                    {...register("descricao")}
                  />
                )}
              </Col>
              <Col size={12} md>
                {modifierEmail ? (
                  <Textfield
                    className="flex-fill"
                    label="E-mail"
                    placeholder="E-mail"
                    modifier={modifierEmail}
                    helper={helperEmail}
                    {...register("email")}
                  />
                ) : (
                  <Textfield
                    className="flex-fill pb-md-4"
                    label="E-mail"
                    placeholder="E-mail"
                    helper={helperEmail}
                    {...register("email")}
                  />
                )}
              </Col>
              <Col size={12} md>
                {modifierCargo ? (
                  <Textfield
                    className="flex-fill"
                    label="Cargo"
                    placeholder="Cargo"
                    modifier={modifierCargo}
                    helper={helperCargo}
                    {...register("funcao")}
                  />
                ) : (
                  <Textfield
                    className="flex-fill pb-md-4"
                    label="Cargo"
                    placeholder="Cargo"
                    helper={helperCargo}
                    {...register("funcao")}
                  />
                )}
              </Col>
              <Col size={12} md="auto" className="py-md-4 pt-2 mt-md-4">
                <Button
                  type="submit"
                  fullWidth
                  className="button-quiet rounded"
                  hierarchy="quiet"
                  srAnnouncement="Salvar"
                  loading={loading}
                  disabled={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};
