import { type FC, memo, useState, useEffect } from "react";

import { useI18n } from "nordic/i18n";

import { Title } from "@andes/typography";

import { Col, Flex } from "../../components/grid";
import TypeAccount from "./typeAccount";

import CardLogin from "../../components/login/cardLogin";

import { get, remove, set } from "../../utils/cookies";

import type { ResponseLogin, TypeAccountEnum } from "./types";

import { TYPE_ACCOUNT } from "./typeAccount";

import CardLoginMeli from "./cardLoginMeli";
import CardLoginSeller from "./cardLoginSeller";

import type { IStepsAuthenticate } from "./stepsAuthenticate";

import LoginSvg from "../../assets/images/login.svg";

export interface FirstStepProps extends IStepsAuthenticate {
  readonly setResponseLogin: (value: ResponseLogin) => void;
}

interface FirstStepPropsAdditional extends FirstStepProps {
  readonly setRedirectMeli: (redirect: boolean) => void;
}

const enableExperienceMeli = !!get("enable_experience_meli")!;

const FirstStep: FC<FirstStepPropsAdditional> = ({
  currentUser,
  setCurrentUser,
  setResponseLogin,
  setRedirectMeli,
}) => {
  const [siteKey, setSiteKey] = useState<string>("");
  const [typeAccountSelected, setTypeAccountSelected] = useState<
    TypeAccountEnum | undefined
  >(undefined);

  const { i18n } = useI18n();

  const handleSetTypeAccount = (typeAccount: TypeAccountEnum) => {
    setTypeAccountSelected(typeAccount);
    remove("typeAccountLogin");
    set("typeAccountLogin", typeAccount, 10);
    setCurrentUser("");
  };

  useEffect(() => {
    if (!siteKey) {
      setSiteKey(get("utm_resource")!);
    }

    const typeAccount = get("typeAccountLogin");
    if (typeAccount) {
      setTypeAccountSelected(typeAccount as TypeAccountEnum);
    }
  }, [siteKey]);

  return (
    <>
      <Col size={12} md={6} lg={5} className="px-lg-5 mx-xl-5 mt-md-5">
        <Flex direction="column" gap={4}>
          <Title className="custom-text mb-2" component="h2">
            {i18n.gettext("Escolha como deseja acessar o portal Kangu")}
          </Title>
          <TypeAccount
            typeAccountSelected={typeAccountSelected}
            setTypeAccountSelected={handleSetTypeAccount}
          />
        </Flex>
      </Col>
      <Col auto className="vertical-line mx-lg-4 d-lg-block d-none">
        <div className="vr"></div>
      </Col>

      {typeAccountSelected && typeAccountSelected === TYPE_ACCOUNT.others && (
        <Col size={12} md={6} lg className="px-lg-4 mx-xl-5 mt-md-4 mt-md-0">
          <CardLogin
            siteKey={siteKey}
            setCurrentUser={setCurrentUser}
            setResponseLogin={setResponseLogin}
            setRedirectMeli={setRedirectMeli}
            isCommonLogin
            forgetPassword
          />
        </Col>
      )}
      {typeAccountSelected && typeAccountSelected === TYPE_ACCOUNT.seller && (
        <Col size={12} md={6} lg className="px-lg-4 mx-xl-5 mt-md-0">
          {enableExperienceMeli && (
            <CardLoginMeli setRedirectMeli={setRedirectMeli} />
          )}
          <CardLoginSeller
            siteKey={siteKey}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            setResponseLogin={setResponseLogin}
            setRedirectMeli={setRedirectMeli}
          />
        </Col>
      )}
      {!typeAccountSelected && (
        <Col size={12} lg className="text-center">
          <img src={LoginSvg} alt="Login" height={300} width={350} />
        </Col>
      )}
    </>
  );
};

export default memo(FirstStep);
