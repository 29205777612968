import React, { useRef, memo } from "react";

import AvatarImg from "../../assets/images/avatar.svg";
import AddAvatarImg from "../../assets/images/addAvatar.svg";

import "./style.scss";

import type { PropsAvatar } from "./types";

const Avatar: React.FC<PropsAvatar> = (
{ 
    selectedAvatar,
    setSelectedAvatar,
    setAvatarChanged
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file: FileList | null = event.target.files;

        if (!file) {
            return;
        }

        if (!file.length) {
            return;
        }

        const firstFile: File = file[0];
        const reader: FileReader = new FileReader();

        reader.onload = (): void => {
            setSelectedAvatar(reader.result);
            setAvatarChanged(reader.result);
        };

        reader.readAsDataURL(firstFile);      
    };

    const handleImageClick = (): void => {
        if (!fileInputRef?.current) {
            return;
        }
        
        fileInputRef.current.click();
    };    

    return (
        <>
            <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
                ref={fileInputRef}
                data-testid="file-input"
            />
            {selectedAvatar ? (
                <div
                    className="avatar-preview"
                    aria-label="Preview Avatar"
                    onClick={handleImageClick}
                    style={{
                        backgroundImage: `url('${selectedAvatar}')`,
                    }}
                />
            ) : (
                <div onClick={handleImageClick} className="select-avatar">
                    <img src={AvatarImg} className="image" alt="Default Avatar" loading="lazy"/>
                    <img src={AddAvatarImg} className="image" alt="Add Avatar" loading="lazy"/>
                </div>
            ) }
        </>
    );
};

export default memo(Avatar);
