import * as zod from "zod";

export const commonSchema = zod.object({
  user: zod
    .string({ required_error: "Informe seu usuário." })
    .min(1, "Informe seu usuário."),
  password: zod.string({ required_error: "Informe sua senha." }),
});

export type CommonLoginSchemaType = zod.infer<typeof commonSchema>;
