import { atom } from "jotai";

export type UseDcStateProps = {
  cpfCadastrado: boolean;
  tipo: "juridica" | "fisica";
  exibe: boolean;
  nome: string;
  documento: string;
  data_nasc?: string;
};

export const useDcStateAtom = atom<UseDcStateProps>({
  cpfCadastrado: false,
  tipo: "fisica",
  exibe: false,
  nome: "",
  documento: "",
  data_nasc: "",
});
