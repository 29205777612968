import { type FC, useCallback, useEffect } from "react";

import { useI18n } from "nordic/i18n";

import { Text } from "@andes/typography";
import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";
import { Flex } from "../../components/grid";
import { remove } from "../../utils/cookies";

interface RedirectLoginMeliProps {
  readonly setRedirectMeli: (redirectMeli: boolean) => void;
}

const RedirectLoginMeli: FC<RedirectLoginMeliProps> = ({ setRedirectMeli }) => {
  const { i18n } = useI18n();

  const handleRedirectMeli = useCallback(() => {
    const params = new URLSearchParams(document.location.search);
    const url = params.get("success");

    setTimeout(() => {
      remove("utm_resource");
      remove("utm_cs");
      window.location.href = `/auth/mercadolivre/login-mercadolivre-redirect${
        url ? `?success=${url}` : ""
      }`;
    }, 5000);
  }, []);

  useEffect(() => {
    handleRedirectMeli();

    setTimeout(() => {
      setRedirectMeli(false);
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="loading-redirect-meli"
      align="center"
      justify="center"
      direction="column"
      gap={5}
    >
      <ProgressIndicatorCircular
        id="my-progress-indicator"
        srLabel="Processamento em andamento"
        srAnnouncement="Procesando..."
      />
      <Text>
        {i18n.gettext(
          "Estamos redirecionando você a uma nova experiência de login na Kangu"
        )}
      </Text>
    </Flex>
  );
};

export default RedirectLoginMeli;
