/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useI18n } from "nordic/i18n";

import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useWizard } from "react-use-wizard";
import { Button } from "@andes/button";
import { Title } from "@andes/typography";
import Card, { CardContent } from "@andes/card";
import { ZIPCODE } from "@andes/textfield/masks/br";
import TextField from "@andes/textfield";
import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";

import { Flex } from "../../../components/grid";
import TextFieldWithMask from "../../../components/textFieldWithMask";
import {
  fieldsForAddressSchema,
  type FieldsForAddressType,
} from "../../../schemas/onboarding/address";
import {
  OnboardingUserMeliAddressProps,
  OnboardingValidatePersonZipcodeProps,
  OnboardingSavePersonAddressProps,
} from "../../../services/onboarding/types";
import {
  getUserMeliAddress,
  savePersonAddress,
  validatePersonZipcode,
} from "../../../pages/onboardingAddress/controller";

export const Address = () => {
  const { i18n } = useI18n();
  const { nextStep } = useWizard();
  const [loading, setLoading] = useState<boolean>(false);
  const [cepValid, setCepValid] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    setError,
    resetField,
    trigger,
  } = useForm<FieldsForAddressType>({
    resolver: zodResolver(fieldsForAddressSchema),
  });

  const formatCep = (cep: string) => {
    return cep.replace(/\D/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
  };

  const unformatCep = (cep: string) => {
    return cep.replace("-", "");
  };

  const getUserAddress = async () => {
    setLoading(true);
    try {
      const response = await getUserMeliAddress();
      const { data }: OnboardingUserMeliAddressProps = response;

      if (data?.address) {
        setValue("cep", formatCep(data.address.zip_code.toString()));
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleValidadeZipcode = async (zipcode: string) => {
    setLoading(true);
    resetField("logradouro");
    resetField("bairro");
    resetField("cidade");
    resetField("estado");
    resetField("numero");
    resetField("complemento");
    try {
      const response = await validatePersonZipcode(zipcode);
      const { data }: OnboardingValidatePersonZipcodeProps = response;

      if (data?.address) {
        setCepValid(false);
        trigger("cep");
        setValue("logradouro", data.address.street);
        setValue("bairro", data.address.neighborhood);
        setValue("cidade", data.address.city);
        setValue("estado", data.address.state);
      }

      if (!data?.success) {
        setCepValid(true);
        setError("cep", {
          type: "manual",
          message: data?.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const onSubmit = async (fields: FieldsForAddressType) => {
    console.log("Dados: ", fields);
    setLoading(true);

    if (cepValid) {
      setError("cep", {
        type: "manual",
        message: "CEP inválido",
      });
      return;
    }

    try {
      const response = await savePersonAddress(fields);
      const { data }: OnboardingSavePersonAddressProps = response;

      if (data?.success && data.success === true) {

        const redirectUrl = localStorage.getItem("success");
        if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
        }

        nextStep();
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserAddress();
  }, []);

  useEffect(() => {
    if (watch("cep").length === 9) {
      handleValidadeZipcode(unformatCep(watch("cep")));
    }
  }, [watch("cep")]);

  return (
    <Flex direction="column" gap={3} className="custom-container-address">
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext("Por último, preencha o seu endereço")}
      </Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={3} direction="column">
          <Card shadow="elevated">
            <CardContent>
              <Flex gap={4} direction="column">
                <Flex gap={1} direction="column">
                  <Flex gap={4} fullWidth direction="row" className="pt-2">
                    <Controller
                      name="cep"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextFieldWithMask
                          {...field}
                          maskOptions={ZIPCODE}
                          label="CEP"
                          placeholder="00000-000"
                          modifier={formState.errors.cep ? "error" : undefined}
                          helper={formState.errors.cep?.message}
                        />
                      )}
                    />
                    <TextField
                      label="Logradouro"
                      placeholder="Razão social da empresa"
                      className="mb-2 w-100"
                      modifier={
                        formState.errors.logradouro ? "error" : undefined
                      }
                      helper={formState.errors.logradouro?.message}
                      {...register("logradouro")}
                    />
                  </Flex>
                  <Flex gap={4} fullWidth direction="row" className="pt-2">
                    <TextField
                      label="Número"
                      placeholder="000"
                      className="mb-2"
                      inputProps={{ type: "number", min: 0 }}
                      modifier={formState.errors.numero ? "error" : undefined}
                      helper={formState.errors.numero?.message}
                      {...register("numero", {
                        valueAsNumber: true,
                      })}
                    />
                    <TextField
                      label="Complemento (opcional)"
                      placeholder="Apartamento / bloco"
                      className="mb-2 w-100"
                      modifier={
                        formState.errors.complemento ? "error" : undefined
                      }
                      helper={formState.errors.complemento?.message}
                      {...register("complemento")}
                    />
                    <TextField
                      label="Bairro"
                      placeholder="Jardim Flor"
                      className="mb-2 w-100"
                      modifier={formState.errors.bairro ? "error" : undefined}
                      helper={formState.errors.bairro?.message}
                      {...register("bairro")}
                    />
                  </Flex>
                  <Flex gap={4} fullWidth direction="row" className="pt-2">
                    <TextField
                      disabled
                      label="Cidade"
                      className="mb-2 w-100"
                      modifier={formState.errors.cidade ? "error" : undefined}
                      helper={formState.errors.cidade?.message}
                      {...register("cidade")}
                    />
                    <TextField
                      disabled
                      label="UF"
                      className="mb-2 w-100"
                      modifier={formState.errors.estado ? "error" : undefined}
                      helper={formState.errors.estado?.message}
                      {...register("estado")}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </CardContent>
          </Card>
          <Flex gap={3} align="end" justify="end" fullWidth>
            <Button size="large" type="submit">
              {i18n.gettext("Salvar")}
            </Button>
          </Flex>
        </Flex>
      </form>

      {loading && (
        <ProgressIndicatorCircular
          className="onboarding-progress-indicator"
          srLabel={i18n.gettext(
            "Buscando informações de progresso do onboarding"
          )}
          srAnnouncement={i18n.gettext("Carregando...")}
          modifier="fullscreen"
        />
      )}
    </Flex>
  );
};
