export enum ErrorCodes {
  MATCH_FAILED_DEFAULT = "",
  MATCH_FAILED_USER_MATCHED = "MU-01",
  MATCH_FAILED_EMAIL_MATCHED = "MM-01",
  MATCH_FAILED_NIVEL = "ME-02",
  MATCH_FAILED_DATA = "EM-01",
}

export enum ErrorTitles {
  MATCH_FAILED_DEFAULT = "Não foi possível vincular sua conta",
  MATCH_FAILED_USER_MATCHED = "Não foi possível vincular suas contas",
  MATCH_FAILED_EMAIL_MATCHED = "Não foi possível vincular suas contas",
  MATCH_FAILED_NIVEL = "Não foi possível vincular suas contas",
  MATCH_FAILED_DATA = "Não foi possível vincular suas contas"
}

export enum ErrorTexts {
  MATCH_FAILED_DEFAULT = "Por favor, tente novamente.",
  MATCH_FAILED_USER_MATCHED = "Por favor, tente novamente com informações compatíveis.",
  MATCH_FAILED_EMAIL_MATCHED = "Por favor, tente novamente com informações compatíveis.",
  MATCH_FAILED_NIVEL = "Por favor, tente novamente com informações compatíveis.",
  MATCH_FAILED_DATA = "Por favor, tente novamente com informações compatíveis.",
}

export enum ErrorButtons {
  MATCH_FAILED_DEFAULT = "Tentar Novamente",
  MATCH_FAILED_USER_MATCHED = "Tentar novamente",
  MATCH_FAILED_EMAIL_MATCHED = "Tentar novamente",
  MATCH_FAILED_NIVEL = "Tentar novamente",
  MATCH_FAILED_DATA = "Tentar novamente",
}

export enum ErrorLinks {
  MATCH_FAILED_DEFAULT = "/user/auth",
  MATCH_FAILED_USER_MATCHED = "/seller/dashboard",
  MATCH_FAILED_EMAIL_MATCHED = "seller/dashboard",
  MATCH_FAILED_NIVEL = "/seller/dashboard",
  MATCH_FAILED_DATA = "/seller/dashboard",
}
