import { FC, memo } from 'react'

import Typography from "@andes/typography";
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import type { PropsSendButtonCode } from './types';

const SendButtonCode: FC<PropsSendButtonCode> = ({
    isSendingCode,
    isLoading,
    typeSend,
    handleSendMfa,
    timer,
    textSend,
    isModal
}) => {

    if (isSendingCode) {
        return <ProgressIndicatorCircular 
            className="progress-spinner-custom d-flex justify-content-center" 
            srLabel="Carregando"
        />;
    }

    if (timer > 0) {
        return (
            <>
                {isModal && 
                    <>
                        <Typography
                            size="s"
                        >
                            Ainda não recebeu? Verifique seu spam ou 
                        </Typography>
                        <br></br>
                        <Typography
                            size="s"
                            className="me-1"
                        >
                            aguarde
                        </Typography>
                    </>
                }
                <Typography>
                        {timer}
                </Typography>

                {isModal && 
                    <>
                        <Typography
                            size="s"
                            className="ms-1"
                        >
                            segundos
                        </Typography>
                    </>
                }
            </>
        );
    }

    if (isLoading) {
        return (
            <>
                {isModal && 
                    <>
                        <Typography
                            size="s"
                        >
                            Ainda não recebeu? Verifique seu spam ou
                        </Typography>
                        <br></br>
                    </>
                }                
                <Typography 
                    className="text-guru-orange-300"
                >
                    {textSend}
                </Typography>
            </>
            );
    }

    return (
        <>
            {isModal && 
                <>
                    <Typography
                        size="s"
                    >
                        Ainda não recebeu? Verifique seu spam ou
                    </Typography>
                    <br></br>
                </>
            }    
            <Typography
                className="custom-typography-link text-guru-orange-500"
                color="link"
                onClick={() => handleSendMfa(typeSend)}
            >
                {textSend}
            </Typography>
        </>
    )
}

export default memo(SendButtonCode);