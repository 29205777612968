import { type FC, memo } from "react";

import Card, { CardContent } from "@andes/card";
import { Text } from "@andes/typography";

import { useI18n } from "nordic/i18n";

import { Col, Row } from "../../components/grid";
import FileUpload from "./fileUpload";
import DropdownDoc from "./dropdownDoc";

import type { CardDocumentationProps } from "./types";

const CardDocumentation: FC<CardDocumentationProps> = ({
  control,
  formState,
  setValue,
  dropdownValue,
  setDropdownValue,
  setError,
}) => {
  const { i18n } = useI18n();

  return (
    <Card className="mb-4 py-3 px-md-5 px-3">
      <CardContent>
        <Row align="center">
          <Col>
            <Row>
              <Text className="fw-bolder">
                {i18n.gettext("Documentação do responsável")}
              </Text>
              <Col className="mt-4" size={12}>
                <Row>
                  <Col size={12}>
                    <Row>
                      <Col lg={4}>
                        <DropdownDoc
                          dropdownValue={dropdownValue}
                          setDropdownValue={setDropdownValue}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col size={12} lg={6}>
                    <FileUpload
                      label="Frente do documento de identificação"
                      control={control}
                      formState={formState}
                      setError={setError}
                      setValue={setValue}
                      fileId={"frontDoc"}
                    />
                  </Col>
                  <Col size={12} lg={6}>
                    <FileUpload
                      label="Verso do documento de identificação"
                      control={control}
                      formState={formState}
                      setError={setError}
                      setValue={setValue}
                      fileId={"verseDoc"}
                    />
                  </Col>
                  <Col size={12} lg={12}>
                    <FileUpload
                      label="Sua foto com o documento"
                      control={control}
                      formState={formState}
                      setError={setError}
                      setValue={setValue}
                      fileId={"userWithDoc"}
                      tooltip={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardDocumentation);
