import { memo } from "react";

import { Pill, type PillSpecificProps } from "@andes/badge";

import { Col, Row } from "../../components/grid";

const mapPillColors: Map<string, PillSpecificProps["color"]> = new Map();

mapPillColors.set("Ativo", "green");
mapPillColors.set("Inativo", "gray");
mapPillColors.set("Erro", "red");

export const PillStatus: React.FC<{ status: string }> = memo(({ status }) => {
  return (
    <Col className="px-5 px-md-0">
      <Row align="center" justify="center" gap={0} className="pt-4 pt-md-0">
        <Pill
          className="pill-p"
          color={mapPillColors.get(status)}
          hierarchy="quiet"
        >
          {status}
        </Pill>
      </Row>
    </Col>
  );
});
