import { HttpMethod } from "nordic/restclient"

import type { DataFetchService, FetchService, CodeRequisition } from "./types";

export default class ApiResponse {
    private static SUCCESS_CODE: CodeRequisition = 200;
    private static CLIENT_ERROR_CODE: CodeRequisition = 400;
    private static SERVER_ERROR_CODE: CodeRequisition = 500;

    private static getSuccessMessage(message?: string): string {
        return message || "Sucesso ao realizar requisição";
    }

    private static getClientErrorMessage(message?: string): string {
        return message || "Erro ao realizar requisição do cliente";
    }

    private static getServerErrorMessage(message?: string): string {
        return message || "Erro ao realizar requisição do servidor";
    }

    static handleSideError(data: FetchService | undefined) {
        if (data?.error) {
            return this.clientError(data as Error)
        }

        return this.success(data as HttpMethod | undefined);
    }

    static success(data: HttpMethod | undefined): DataFetchService {
        return {
            error: false,
            success: true,
            exception: {
                code: this.SUCCESS_CODE,
                message: this.getSuccessMessage(),
                notification: "success"
            },
            data
        };
    }

    static clientError(data?: FetchService): DataFetchService {
        return {
            error: true,
            success: false,
            exception: {
                code: this.CLIENT_ERROR_CODE,
                message: this.getClientErrorMessage(data?.exception?.message),
                notification: "error"
            },
            data
        };
    }
  
    static serverError(data?: FetchService): DataFetchService {
        return {
            error: true,
            success: false,
            exception: {
                code: this.SERVER_ERROR_CODE,
                message: this.getServerErrorMessage(data?.exception?.message),
                notification: "error"
            },
            data
        };
    }
}