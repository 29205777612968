import OrderService from "../../services/orderList";

import {
  IResponseOrderList,
  IResponseOdersVerify,
} from "../../services/orderList/types";

export const getOrderList = async (
  ids: string
): Promise<IResponseOrderList> => {
  const response = await OrderService.getOrders(ids);

  return response;
};

export const verifyCartPromise = async (
  orders_id: number[]
): Promise<IResponseOdersVerify> => {
  const response = await OrderService.verifyCartPromise(orders_id);

  return response;
};
