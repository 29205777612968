import { TypeSizes, SizesClass } from "./types";

class Sizes {
    public getSize = (type: TypeSizes): SizesClass => {
        switch (type) {
            case "medium":
                return "md";
            case "large":
                return "lg";
            default:
                return "sm";
        };
    };
}

export default Sizes;