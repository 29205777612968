import { type FC } from "react";

import Image from "../assets/images/something-went-wrong.svg";

interface SomethingWentWrongProps {
  heigth?: number;
  width?: number;
}

export const SomethingWentWrong: FC<SomethingWentWrongProps> = ({
  heigth,
  width,
}) => {
  return (
    <img src={Image} width={width} height={heigth} alt="Something went wrong" />
  );
};
