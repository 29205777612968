import { FC } from "react";

import Layout from "../../../components/layout";
import { ErroMatchContent } from "../../../containers/erroMatch";

const ErroMatch: FC = () => {
  return (
    <Layout pageTitle="Erro Match">
      <ErroMatchContent />
    </Layout>
  );
};

export default ErroMatch;
