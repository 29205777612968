import { FC } from "react";

import RedirectMessage from "../../components/redirectMessage";
import { urlRedirect } from "./enum";

import AlertNewAccountIcon from "../../assets/images/alert-new-account.svg";

export const NewAccountContent: FC = () => {
  return (
    <RedirectMessage
      titleMessage="Não encontramos uma conta Kangu vinculada à conta Mercado Livre informada"
      middleMessage="Recomendamos que você realize um novo cadastro com informações compatíveis."
      buttonMessage="Realizar novo cadastro"
      iconImage={AlertNewAccountIcon}
      action={() => (window.location.href = urlRedirect.URL_REDIRECT)}
    />
  );
};
