import type { PropsData, PropsDataSettingReturn } from "./types";

export default class PropsSettings {
    constructor (public repository: PropsData) {}

    private getWrapClass(): string {
        return this.repository?.wrap ? "wrap" : "nowrap";
    }

    private getClassName(): string {
        return this.repository?.className || "";
    }

    private getDirection(): string {
        return this.repository?.direction || "";
    }

    private getGap(): string | number {
        if (this.repository?.gap === 0) {
            return 0;
        }
        
        return this.repository?.gap || 3;
    }
  
    private getJustifyClass(): string {
        return this.repository?.justify ? `justify-content-${this.repository?.justify}` : "";
    }
  
    private getAlignClass(): string {
        return this.repository?.align ? `align-items-${this.repository?.align}` : "";
    }
  
    private getAlignSelfClass(): string {
        return this.repository?.alignSelf ? `align-self-${this.repository?.alignSelf}` : "";
    }
  
    private getFullWidthClass(): string {
        return this.repository?.fullWidth ? "w-100" : "";
    }

    private getWidth(): string {
        return this.repository?.width ? `-${this.repository?.width}` : "";
    }

    private getAlign(): string {
        return this.repository?.align || "start";
    }

    private getJustify(): string {
        return this.repository?.justify || "start";
    }

    private getColClass(): string {
        return this.repository?.auto ? "col-auto" : `col${this.repository?.size ? `-${this.repository?.size}` : ""}`;
    }

    private getSmClass(): string {
        return this.repository?.sm ? `${this.repository?.sm !== true ? `col-sm-${this.repository?.sm}` : `col-sm`}` : "";
    }

    private getMdClass(): string {
        return this.repository?.md ? `${this.repository?.md !== true ? `col-md-${this.repository?.md}` : `col-md`}` : "";
    }

    private getLgClass(): string {
        return this.repository?.lg ? `${this.repository?.lg !== true ? `col-lg-${this.repository?.lg}` : `col-lg`}` : "";
    }

    private getXlClass(): string {
        return this.repository?.xl ? `${this.repository?.xl !== true ? `col-xl-${this.repository?.xl}` : `col-xl`}` : "";
    }

    private getXxlClass(): string {
        return this.repository?.xxl ? `${this.repository?.xxl !== true ? `col-xxl-${this.repository?.xxl}` : `col-xxl`}` : "";
    }

    public getProps(): PropsDataSettingReturn {
        return {
            className: this.getClassName(),
            direction: this.getDirection(),
            gap: this.getGap(),
            wrapClass: this.getWrapClass(),
            justifyClass: this.getJustifyClass(),
            alignClass: this.getAlignClass(),
            alignSelfClass: this.getAlignSelfClass(),
            fullWidthClass: this.getFullWidthClass(),
            widthClass: this.getWidth(),
            align: this.getAlign(),
            justify: this.getJustify(),
            colClass: this.getColClass(),  
            smClass: this.getSmClass(),
            mdClass: this.getMdClass(),
            lgClass: this.getLgClass(),
            xlClass: this.getXlClass(),
            xxlClass: this.getXxlClass()
        };
    }    
};