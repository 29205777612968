interface IMask {
    value: number
}
const maskCPF = (cpf: string) => {
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}
const maskCNPJ = (cnpj: string)=> {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
export const checkType = (number:number): string => {
    const numStr = number.toString();

    const cleanedValue = numStr?.replace(/[^\d]+/g, '');

    if (cleanedValue.length === 11) {
        return 'CPF';
    } else if (cleanedValue.length === 14) {
        return 'CNPJ';
    }
    return 'CPF';
}
export default function applyMask({ value }: IMask) {
    const type = checkType(value)
    const fn = {
        CPF: maskCPF(value.toString()),
        CNPJ: maskCNPJ(value.toString()),

    }
    // @ts-ignore
    return fn[type?.toUpperCase()] || value.toString()
}