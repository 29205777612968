import { type FC, memo, useState, useEffect } from "react";

import { useI18n } from "nordic/i18n";

import { Title } from "@andes/typography";

import { Col, Flex } from "../../components/grid";
import TypeAccount from "./typeAccount";

import CardLogin from "../../components/login/cardLogin";

import { get, remove, set } from "../../utils/cookies";

import type { ResponseLogin, TypeAccountEnum } from "./types";

import { TYPE_ACCOUNT } from "./typeAccount";

import CardLoginMeli from "./cardLoginMeli";
import CardLoginSeller from "./cardLoginSeller";

import type { IStepsAuthenticate } from "./stepsAuthenticate";

import LoginSvg from "../../assets/images/login.svg";

export interface FirstStepProps extends IStepsAuthenticate {
  readonly setResponseLogin: (value: ResponseLogin) => void;
}

interface FirstStepPropsAdditional extends FirstStepProps {
  readonly setRedirectMeli: (redirect: boolean) => void;
}

const enableExperienceMeli = !!get("enable_experience_meli")!;

const FirstStep: FC<FirstStepPropsAdditional> = ({
  currentUser,
  setCurrentUser,
  setResponseLogin,
  setRedirectMeli,
}) => {
  const [siteKey, setSiteKey] = useState<string>("");
  const [typeAccountSelected, setTypeAccountSelected] = useState<
    TypeAccountEnum | undefined
  >(undefined);

  const { i18n } = useI18n();

  const handleSetTypeAccount = (typeAccount: TypeAccountEnum) => {
    setTypeAccountSelected(typeAccount);
    remove("typeAccountLogin");
    set("typeAccountLogin", typeAccount, 10);
    setCurrentUser("");
  };

  useEffect(() => {
    if (!siteKey) {
      setSiteKey(get("utm_resource")!);
    }

    const typeAccount = get("typeAccountLogin");
    if (typeAccount) {
      setTypeAccountSelected(typeAccount as TypeAccountEnum);
    }
  }, [siteKey]);

  return (
    <>    
    <Col size={12} md={6} lg={5} className="px-lg-5 mx-xl-5 mt-md-5">
    <Flex direction="column" gap={4}>
        <TypeAccount
        typeAccountSelected={typeAccountSelected}
        setTypeAccountSelected={handleSetTypeAccount}
        />
    </Flex>
    </Col>
    <Col auto className="vertical-line mx-lg-4 d-lg-block d-none">
    <div className="vr"></div>
    </Col>

    <Col size={12} md={6} lg className="px-lg-4 mx-xl-5 mt-md-4 mt-md-0">
        <CardLogin
        siteKey={siteKey}
        setCurrentUser={setCurrentUser}
        setResponseLogin={setResponseLogin}
        setRedirectMeli={setRedirectMeli}
        isCommonLogin
        forgetPassword
        />
    </Col>
    </>
  );
};

export default memo(FirstStep);
