import { atomWithStorage } from "jotai/utils";

import { AvatarState } from "../components/avatar/types";

export type NavbarStateProps = {
  avatar: AvatarState;
  descricao: string;
  email: string;
  funcao: string;
};

export const navbarStateAtom = atomWithStorage<NavbarStateProps>(
  "navbarState",
  {
    avatar: null,
    descricao: "",
    email: "",
    funcao: "",
  }
);
