import Service from "../Service";
import { IResponseOdersVerify, IResponseOrderList } from "./types";

export default class OrderListService {
  static async getOrders(ids: string): Promise<IResponseOrderList> {
    const service = new Service();
    const response = (await service.doPost(`/vendas/pedido/pre-cart`, {
      id: ids,
    })) as unknown;

    return response as Promise<IResponseOrderList>;
  }

  static async verifyCartPromise(
    orders_id: number[]
  ): Promise<IResponseOdersVerify> {
    const service = new Service();
    const response = (await service.doPost(
      "/vendas/pedido/verify-pre-cart-promise",
      {
        orders_id,
      }
    )) as unknown;

    return response as Promise<IResponseOdersVerify>;
  }
}
