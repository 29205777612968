import * as zod from "zod";
import { sanitizeData } from "../../../utils/sanitizeData";

export const fieldsForCpfSchema = zod.object({
  cpf: zod
    .string({ required_error: "Informe seu CPF." })
    .min(14, "CPF inválido."),
  dataNascimento: zod
    .string({ required_error: "Informe sua data de nascimento." })
    .min(10, "Data de nascimento inválida."),
});

export type FieldsForCpfType = zod.infer<typeof fieldsForCpfSchema>;

export const fieldsForCnpjSchema = zod.object({
  cnpj: zod
    .string({ required_error: "Informe seu CNPJ." })
    .min(18, "CNPJ inválido."),
  razaoSocial: zod
    .string({ required_error: "Informe a razão social." })
    .transform((value) => sanitizeData(value).trim())
    .refine((value: string) => value.split(" ").length >= 2, {
      message: "Digite a razão social completa.",
    }),
  cpf: zod
    .string({ required_error: "Informe o CPF do responsável." })
    .min(14, "CPF inválido."),
  dataNascimento: zod
    .string({ required_error: "Informe a data de nascimento do responsável." })
    .min(10, "Data de nascimento inválida."),
});

export type FieldsForCnpjType = zod.infer<typeof fieldsForCnpjSchema>;

export const fieldsForMeiSchema = zod.object({
  cnpj: zod
    .string({ required_error: "Informe seu CNPJ." })
    .min(18, "CNPJ inválido."),
  razaoSocial: zod
    .string({ required_error: "Informe a razão social." })
    .transform((value) => sanitizeData(value).trim())
    .refine((value: string) => value.split(" ").length >= 2, {
      message: "Digite a razão social completa.",
    }),
  cpf: zod
    .string({ required_error: "Informe o CPF do responsável." })
    .min(14, "CPF inválido."),
  dataNascimento: zod
    .string({ required_error: "Informe a data de nascimento do responsável." })
    .min(10, "Data de nascimento inválida."),
});

export type FieldsForMeiType = zod.infer<typeof fieldsForMeiSchema>;
