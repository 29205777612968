/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";

import { useAtomValue } from "jotai";

import { Container, Flex } from "../../components/grid";
import { Orders } from "../../containers/orderList/orders";
import { Footer } from "../../containers/orderList/footer";
import { Banner } from "../../containers/orderList/banner";
import { orderListStateAtom } from "../../states/orderList";

import "./styles.scss";

const ListOrder: FC = () => {
  const orderListState = useAtomValue(orderListStateAtom);

  useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("style", "overflow: hidden !important;");

    return () => {
      document.getElementsByTagName("html")[0]?.removeAttribute("style");
    };
  }, []);

  useEffect(() => {
    if (orderListState.isHighLight) {
      document
        .getElementById("menu")
        ?.setAttribute("style", "z-index: 0 !important;");
    }

    return () => {
      document.getElementById("menu")?.removeAttribute("style");
    };
  }, [orderListState.isHighLight]);
  return (
    <Flex
      direction="column"
      justify="between"
      fullWidth
      className={`custom-container ${
        orderListState.isHighLight ? "container-highlight" : ""
      }`}
    >
      <Container className="p-4 px-md-1">
        <Flex direction="column" justify="around" gap={4} fullWidth>
          {orderListState.isHighLight && <Banner />}
          <Orders />
        </Flex>
      </Container>
      <Footer />
    </Flex>
  );
};

export default ListOrder;
