import {
  RestClient,
  RestClientInstance,
  RestClientConfig,
} from "nordic/restclient";

import { ENVS, DEFAULT_BASE_URL } from "../constants/process";

export default class ApiConfigurator {
  private baseURL: string = DEFAULT_BASE_URL;
  private timeout: number = 50000;

  constructor() {
    this.setBaseURL();
  }

  private getHostname(): string {
    return window.location.hostname;
  }

  private getProtocol(): string {
    return window.location.protocol;
  }

  private getBaseURL(): string {
    return this.baseURL;
  }

  private setBaseURL(): void {
    if (process.env.NODE_ENV === ENVS.test) {
      this.baseURL = DEFAULT_BASE_URL;
      return;
    }
    
    this.baseURL = `${this.getProtocol()}//${this.getHostname()}`;
  }

  private getTimeout(): number {
    return this.timeout;
  }

  public getConfig(): RestClientConfig {
    return {
      baseURL: this.getBaseURL(),
      timeout: this.getTimeout(),
      validateStatus: () => true,
    } as RestClientConfig;
  }

  public setConfig(apiConfig: RestClientConfig): RestClientInstance {
    return RestClient(apiConfig);
  }
}
