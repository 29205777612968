import { useEffect, useState, memo, useCallback, useMemo } from "react";

import {
    TableBody,
    TableData,
    TableHead,
    TableRow,
    TableHeader,
    Table,
} from "@andes/table";

import Pagination from "@andes/pagination";

import Placeholder from "../placeholder";
import { Col, Row } from "../grid";

import type { PropsTableWithPagination, DataTable} from "./types";

const TableWithPagination = <T extends DataTable>(props: PropsTableWithPagination<T>): JSX.Element => {    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showRows, setShowRows] = useState<boolean>(false);

    const { 
        columns, 
        data, 
        itemsPerPage, 
        dataKeys 
    }: PropsTableWithPagination<T> = props;

    const isMobileDevice: boolean = window.innerWidth < 768;

    // Calculate initial and final index of items in actual page
    const indexOfLastItem: number = useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);
    const indexOfFirstItem: number = useMemo(() => indexOfLastItem - itemsPerPage, [indexOfLastItem, itemsPerPage]);
    const currentItems = useMemo(() => data.slice(indexOfFirstItem, indexOfLastItem), [data, indexOfFirstItem, indexOfLastItem]);
    const currentItemsLength: number = useMemo(() => currentItems.length, [currentItems]);
    const pageQuantity: number = useMemo(() => data.length / itemsPerPage, [data, itemsPerPage]);

    // Function to change page
    const paginate = useMemo(() => (pageNumber: number): void => {
        setCurrentPage(pageNumber);
    }, []);

    const handleShowRows = useCallback((): void => {
        if (!currentItemsLength) {
            return;
        }

        setShowRows(true);
    }, [currentItemsLength]);

    useEffect(() => {
        handleShowRows();
    }, [currentItems, handleShowRows]);

    return (
        <Row
            align="end"
            gap={3}
        >
            <Col
                size={12}
            >
                <Table 
                    compact={true}
                >
                    <TableHead>
                        {columns &&
                            columns.map((column, index: number) => {
                                return column.visible && (
                                    <TableHeader
                                        key={column.title + index}
                                        className="text-center"
                                        >
                                            {column.title}
                                    </TableHeader>
                                )
                            })
                        }
                    </TableHead>
                    <TableBody>
                        {(showRows && currentItems) || isMobileDevice
                        ? 
                            currentItems.map((item) => 
                                <TableRow
                                    key={item.id}
                                    className="mt-md-0 mt-3"
                                >
                                    {dataKeys &&
                                        dataKeys.map((key: string, index: number) => {
                                            return (
                                                <TableData
                                                    key={item[key as keyof typeof item] + key}
                                                    data-title={columns[index].title}
                                                    className="py-md-3"
                                                >
                                                    {item[key as keyof typeof item]}
                                                </TableData>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        :
                            dataKeys &&
                                <TableRow>
                                {
                                    dataKeys.map((_, index: number) => 
                                        <TableData
                                            data-title={columns[index].title}
                                            className="p-4"
                                            key={`${columns[index].title}-tabledata`}
                                        >
                                            <Placeholder />
                                        </TableData>           
                                    )
                                }
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </Col>
            {pageQuantity && pageQuantity > 1 
                ? (
                    <Col
                        size={12}
                    >
                        <Pagination
                            onChange={(_: Event, page: number) => paginate(page)}
                            href="#"
                            pageQuantity={Math.ceil(pageQuantity)}
                            pageSelected={currentPage}
                            prevLabel="Anterior"
                            nextLabel="Próximo"
                        />
                    </Col>
                )
                : ""
            }
        </Row>
    );
};

export default memo(TableWithPagination);
