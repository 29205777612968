import { memo, useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import Button from "@andes/button";
import Typography from '@andes/typography';

import { Modal } from "../../components/modal";
import { Col, Row } from "../../components/grid";

import { disableMfa } from "../../pages/myAccess/controller";

import type { 
    DataCheckMfa,
    DataDisableMfa,
    PropsDisableMfa,
    PropsModalDisableMfa,
} from "./types";

import { DataFetchService } from "../../services/types";
import { useAtom } from "jotai";
import { SnackbarStateProps, snackbarStateAtom } from "../../states/snackbarState";

const MfaTypes = {
    "W": "WhatsApp",
    "S": "SMS",
    "E": "E-mail",
};

export const ModalDisableMfa: React.FC<PropsModalDisableMfa> = memo(
({ 
    openModalDisable, 
    setOpenModalDisable,
    setMfaHasChange,
    typeMfa
}) => {
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const [snackbar, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);

    const handleCloseModal = useCallback((): void => {
        setOpenModalDisable(false);
    }, [setOpenModalDisable]);

    const handleDisableMfa = (): void => {
        if (!typeMfa) {
            return;
        };

        const data: PropsDisableMfa = {
            mfatipo: typeMfa
        };

        fetchDisableMfa(data);
    };

    const fetchDisableMfa = async (data: PropsDisableMfa): Promise<void> => {
        try {
            setLoadingButton(true);

            const response: DataDisableMfa = await disableMfa(data);

            if (response?.error) {
                const message: string | undefined = response?.exception?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.data?.ok === false) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.success && response?.data?.ok === true) {
                setSnackbar({
                    ...snackbar,
                    message: `Autenticação de dois fatores desativada com sucesso para ${MfaTypes[typeMfa]}`,
                    color: "green",
                    show: true,
                });
            
                updateCacheQuery();
                setMfaHasChange(true);

                setOpenModalDisable(false);
            }
        } catch (error: unknown) {
            const getError = error as DataFetchService;

            if (getError?.exception) {
                setSnackbar({
                    ...snackbar,
                    message: getError?.exception?.message,
                    color: "red",
                    show: true,
                });
            } else if (error instanceof Error) {
                setSnackbar({
                    ...snackbar,
                    message: error?.message,
                    color: "red",
                    show: true,
                });
            }
        } finally {
            setLoadingButton(false);
        }
    };

    const updateCacheQuery = useCallback((): void => {
        const checkedMfa = queryClient.getQueryData<DataCheckMfa | void>(["getMfaActiveFetch"]);

        if (checkedMfa?.data) {
            if (typeMfa === "S") {
                checkedMfa.data.SMS = "I";
            } else if (typeMfa === "W") {
                checkedMfa.data.WhatsApp = "I";
            } else {
                checkedMfa.data.Email = "I";
            }

            queryClient.setQueryData(["getMfaActiveFetch"], checkedMfa);
        }
    }, [queryClient, typeMfa]);

    return (
        <Modal
            open={openModalDisable}
            onClose={handleCloseModal}
            title="Deseja continuar?"
            type="medium"
            className="py-2 px-4"
            headerClassName="text-center"
        >
            <Col
                size={12}
            >
                <Row
                    align="end"
                    gap="3"
                >
                    <Col
                        size={12}
                        className="text-center"
                    >
                        <Typography>
                            Ao clicar nesse botão você desativa a autenticação de dois fatores 
                            via {MfaTypes[typeMfa].toLowerCase()} e só poderá receber o código de segurança pelos 
                            outros métodos ativados por você.
                        </Typography>
                    </Col>
                    <Col
                        size={12}
                        className="pt-3 pb-2"
                    >
                        <Row
                            justify="center"
                        >
                            <Col
                                auto
                            >
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="text-guru-orange-500 custom-button-transparent rounded progress-indicator-kangu"
                                    hierarchy="quiet"
                                    srAnnouncement="Desativar 2FA"
                                    loading={loadingButton}
                                    disabled={loadingButton}
                                    onClick={handleDisableMfa}
                                >
                                    Desativar
                                </Button>
                            </Col>
                            <Col
                                auto
                            >                           
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="button-quiet-orange rounded"
                                    hierarchy="quiet"
                                    srAnnouncement="Cancelar"
                                    onClick={() => setOpenModalDisable(false)}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>                        
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
});