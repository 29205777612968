import * as zod from "zod";

export const loginSchema = zod.object({
  user: zod
    .string({ required_error: "Informe seu e-mail." })
    .email("Informe um e-mail válido."),
  password: zod.string({ required_error: "Informe sua senha." }),
});

export type LoginSchemaType = zod.infer<typeof loginSchema>;
