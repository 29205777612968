import { atom } from "jotai";

import type { ColorSnackbar } from "../containers/myAccess/types";

export interface SnackbarStateProps {
  message: string;
  color: ColorSnackbar;
  show: boolean;
  delay: number;
}

export const snackbarStateAtom = atom<SnackbarStateProps>({
  message: "",
  color: "green",
  show: false,
  delay: 21000,
});
