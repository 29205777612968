import { type FC, memo } from "react";

import { Dropdown, DropdownItem } from "@andes/dropdown";
import { AndesProvider } from "@andes/context";

import { DropdownDocProps } from "./types";

const DropdownDoc: FC<DropdownDocProps> = ({
  dropdownValue,
  setDropdownValue,
}) => {
  return (
    <AndesProvider locale="pt-BR">
      <Dropdown
        type="form"
        label="Selecione o tipo de documento"
        placeholder="Selecione o tipo de documento"
        className="custom-dropdown"
        defaultValue="rg"
        value={dropdownValue}
        onChange={( _, value ) =>
          setDropdownValue(value as string)
        }
      >
        <DropdownItem key="rg" value="rg" title="RG (Registro Geral)" />
        <DropdownItem
          key="cnh"
          value="cnh"
          title="CNH (Carteira Nacional de Habilitação)"
        />
      </Dropdown>
    </AndesProvider>
  );
};

export default memo(DropdownDoc);
