import UpgradeNivelService from "../../services/upgradeNivel/UpgradeNivelService";

import type {
  GetInformationSeller,
  UpgradeNivel,
  ResponseUpgradeNivel,
} from "../../services/upgradeNivel/types";

export const getInformation = async (): Promise<GetInformationSeller> => {
  const response = await UpgradeNivelService.getInformationSeller();
  return response;
};

export const upgradeNivel = async (
  data: UpgradeNivel
): Promise<ResponseUpgradeNivel> => {
  const response = await UpgradeNivelService.upgradeNivel(data);
  return response;
};
