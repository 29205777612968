import UseDcService from "../../services/useDc";

import type {
  ProfileInfo,
  RequestRating,
  ResponseProfileChange,
  ResponseRating,
} from "../../services/useDc/types";

export const getDataProfile = async (): Promise<ProfileInfo> => {
  const response = await UseDcService.getProfile();

  return response;
};

export const postDataProfile = async (): Promise<ResponseProfileChange> => {
  const response = await UseDcService.postProfile();

  return response;
};

export const postDataRating = async (
  data: RequestRating
): Promise<ResponseRating> => {
  const response = await UseDcService.postRating(data);

  return response;
};
