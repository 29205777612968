import { type FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";

import SnackbarCustom from "./components/snackbar";
import { AndesProvider } from "@andes/context";

import { I18n, I18nProvider } from "nordic/i18n";

import { Provider as JotaiProvider } from "jotai";

import translations from "./translations/pt-BR/messages.json";

import { Router } from "./routes";

import { get } from "./utils/cookies";

const client = new QueryClient();

const csrfToken = get("utm_cs")!;

const i18n = new I18n({
  translations,
});

const App: FC = () => {
  return (
    <AndesProvider locale="pt-BR" csrfToken={csrfToken}>
      <I18nProvider i18n={i18n}>
        <JotaiProvider>
          <BrowserRouter>
            <QueryClientProvider client={client}>
              <Router />
              <SnackbarCustom />
            </QueryClientProvider>
          </BrowserRouter>
        </JotaiProvider>
      </I18nProvider>
    </AndesProvider>
  );
};

export default App;
