import { type FC, memo } from "react";

import Card, { CardContent } from "@andes/card";
import { Text } from "@andes/typography";
import TextField from "@andes/textfield";

import { useI18n } from "nordic/i18n";

import { Col, Row } from "../../components/grid";

import type { CardJusticationProps } from "./types";

const CardJustification: FC<CardJusticationProps> = ({
  register,
  formState,
}) => {
  const { i18n } = useI18n();

  return (
    <Card className="mb-4 py-3 px-md-5 px-3">
      <CardContent>
        <Row align="center">
          <Col>
            <Row>
              <Text className="fw-bolder">{i18n.gettext("Justificativa")}</Text>
              <Col>
                <TextField
                  placeholder="Motivo pelo qual estou solicitando meu aumento de limites de envios simultâneo."
                  className="custom-textarea"
                  maxLength={320}
                  srLabel="Justificativa"
                  countdown
                  multiline
                  rows={2}
                  resize="none"
                  modifier={
                    formState.errors.justification ? "error" : undefined
                  }
                  helper={formState.errors.justification?.message}
                  {...register("justification", {
                    minLength: {
                      value: 5,
                      message:
                        "A justificativa deve ter pelo menos 5 caracteres",
                    },
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardJustification);
