import { type FC, memo } from "react";

import { useI18n } from "nordic/i18n";

import { Card, CardContent } from "@andes/card";
import { Text } from "@andes/typography";
import { Thumbnail } from "@andes/thumbnail";

import { Col, Flex } from "../grid";

interface CardAvatarProps {
  readonly currentUser: string;
  readonly onClick?: () => void;
}

const CardAvatar: FC<CardAvatarProps> = ({ currentUser, onClick }) => {
  const { i18n } = useI18n();

  return (
    <Col md="auto" size={12} className="p-md-0">
      <Card className="shadow-none card-avatar">
        <CardContent>
          <Flex align="center" className="pe-3 ps-2">
            <Thumbnail className="custom-thumbnail">
              <i className="fa-regular fa-user"></i>
            </Thumbnail>
            <Col auto>
              <Flex direction="column" gap={1}>
                <Text>{currentUser}</Text>
                <Text
                  className="text-change-account text-muted"
                  onClick={onClick}
                >
                  {i18n.gettext("Alterar conta")}
                </Text>
              </Flex>
            </Col>
          </Flex>
          <Col></Col>
        </CardContent>
      </Card>
    </Col>
  );
};

export default memo(CardAvatar);
