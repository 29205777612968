import { useState, type FC, useCallback, memo, useEffect } from "react";

import { useForm, SubmitHandler } from "react-hook-form";

import { useAtom } from "jotai";

import { Form } from "@andes/form";

import CardAdvantages from "./cardAdvantages";
import CardInformative from "./cardInformative";
import CardRegistrationData from "./cardRegistrationData";
import CardDocumentation from "./cardDocumentation";
import CardJustification from "./cardJustification";
import ButtonSendDoc from "./buttonSendDoc";

import {
  getInformation,
  upgradeNivel,
} from "../../pages/upgradeNivel/controller";

import type { DataForm, Inputs } from "./types";
import type {
  GetInformationData,
  ResponseUpgradeNivel,
  UpgradeNivel,
} from "../../services/upgradeNivel/types";
import type { DataFetchService } from "../../services/types";
import {
  SnackbarStateProps,
  snackbarStateAtom,
} from "../../states/snackbarState";
import { sanitizeData } from "../../utils/sanitizeData";

function removeSpecialCharacters(value?: string): string {
  if (!value) {
    return "";
  }

  return String(value)
    .replace(/[^0-9]/g, "")
    .trim();
}

const FormUpgradeNivel: FC = () => {
  const [dropdownValue, setDropdownValue] = useState<string>("rg");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<GetInformationData>({});

  const [snackbar, setSnackbar] =
    useAtom<SnackbarStateProps>(snackbarStateAtom);

  const { control, handleSubmit, formState, register, setError, setValue } =
    useForm<Inputs>();

  const getInformationSeller = async (): Promise<GetInformationData> => {
    const response = await getInformation();
    const respondeData = response?.data;

    setDataIsLoading(false);
    setData(respondeData);

    return respondeData;
  };

  useEffect(() => {
    getInformationSeller();
  }, []);

  const validDocuments = (dataForm: DataForm) => {
    let hasError = false;

    if (!dataForm.rg) {
      setValue("rg", "");
      setError("rg", {
        type: "manual",
        message: "Este campo é obrigatório",
      });
    }

    if (!dataForm.frontDoc) {
      setValue("frontDoc", "");
      setError("frontDoc", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    if (!dataForm.verseDoc) {
      setValue("verseDoc", "");
      setError("verseDoc", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    if (!dataForm.userWithDoc) {
      setValue("userWithDoc", "");
      setError("userWithDoc", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    if (!dataForm.justification) {
      setValue("justification", "");
      setError("justification", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    if (!data?.name && !dataForm.name) {
      setValue("name", "");
      setError("name", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    if (!data?.code && !dataForm.cpf) {
      setValue("cpf", "");
      setError("cpf", {
        type: "manual",
        message: "Este campo é obrigatório",
      });

      hasError = true;
    }

    return hasError;
  };

  const showSnackbarOnSubmit = useCallback(
    (response: ResponseUpgradeNivel): void => {
      if (response?.error) {
        const message: string | undefined = response?.exception?.message;

        if (message) {
          setSnackbar({ ...snackbar, message, color: "red", show: true });
        }
      }

      if (response?.success) {
        const message: string | undefined = response?.data?.message;

        window.location.href = "/sellers/documento-enviado/";

        if (message) {
          setSnackbar({ ...snackbar, message, color: "green", show: true });
        }
      }
    },
    [setSnackbar, snackbar]
  );

  const dtoInput = (data: Inputs): UpgradeNivel => {
    return {
      pessoa_doc: {
        frente: data.frontDoc,
        verso: data.verseDoc,
        pessoa_com_doc: data.userWithDoc,
      },
      tipo_documento: dropdownValue,
      nome: data?.name ? data?.name.trim() : "",
      rg: removeSpecialCharacters(data.rg),
      cpf: removeSpecialCharacters(data?.cpf),
      nivel_solicitacao_justificativa: data.justification
        ? data.justification.trim()
        : "",
    };
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);

      data = await sanitizeData(data);

      if (validDocuments(data)) {
        setIsLoading(false);
        return;
      }

      const dto = dtoInput(data);
      const response: ResponseUpgradeNivel = await upgradeNivel(dto);

      showSnackbarOnSubmit(response);
    } catch (error) {
      const getError = error as DataFetchService;

      if (getError?.exception) {
        setSnackbar({
          ...snackbar,
          message: getError?.exception?.message,
          color: "red",
          show: true,
        });
      } else if (error instanceof Error) {
        setSnackbar({
          ...snackbar,
          message: error?.message,
          color: "red",
          show: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <CardAdvantages dataIsLoading={dataIsLoading} />
      <CardInformative data={data} />
      <CardRegistrationData
        data={data}
        dataIsLoading={dataIsLoading}
        control={control}
        formState={formState}
        setValue={setValue}
      />
      <CardDocumentation
        control={control}
        formState={formState}
        setValue={setValue}
        dropdownValue={dropdownValue}
        setDropdownValue={setDropdownValue}
        setError={setError}
      />
      <CardJustification register={register} formState={formState} />
      <ButtonSendDoc isLoading={isLoading} />
    </Form>
  );
};

export default memo(FormUpgradeNivel);
