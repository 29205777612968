import type { FC } from "react";

import { HeadProps } from "./types";
import usePageTracking from "../../hooks/usePageTracking";

const Head: FC<HeadProps> = ({ pageTitle = "" }) => {
  usePageTracking();

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=0"
      />
      {pageTitle && <title>{pageTitle}</title>}
    </>
  );
};

export default Head;
