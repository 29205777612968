import { FC, memo } from "react";

import Switch from "@andes/switch";
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import type { PropsSwitchMfa } from "./types";

const SwitchMfa: FC<PropsSwitchMfa> = ({
    isSendingCode,
    callback,
    isChecked,
    isDisabled
}) => {

    if (isSendingCode) {
        return (
            <ProgressIndicatorCircular
                className="progress-indicator-kangu me-2"
                srLabel="Enviando código..."
            />
        )
    }

    return (
        <Switch 
            srLabel="Ativar 2FA"
            className="switch-custom"
            checked={isChecked}
            onChange={callback}
            disabled={isDisabled}
        />
    );
};

export default memo(SwitchMfa);
