import { FC } from "react";

import Layout from "../../../components/layout";
import { NewAccountContent } from "../../../containers/newAccount";

const NewAccount: FC = () => {
  return (
    <Layout pageTitle="Criar conta">
      <NewAccountContent />
    </Layout>
  );
};

export default NewAccount;
