import { Flex } from "../../components/grid";

import Card from "@andes/card";
import Typography from '@andes/typography';
import { Title, Text} from "@andes/typography";
import { Button } from "@andes/button";
import { useAtom } from "jotai";
import { type FC, useEffect, useState } from "react";
import { HeaderStateProps, headerStateAtom } from "../../states/header";
import { RequestCardProps } from "./types";
import { useDcStateAtom, UseDcStateProps } from "../../states/useDc";
import { ResponseProfileChange } from "../../services/useDc/types";
import { postDataProfile } from "../../pages/useDc/controller";
import { navbarStateAtom, NavbarStateProps } from "../../states/navbar";
import IconError from "../../assets/images/error.svg";

const COUNTDOWN_TIME = 30;

const RequestCard: FC<RequestCardProps> = ({
  setUseSameCPF,
  setIsFormSubmitted,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerState, setHeaderState] =
    useAtom<HeaderStateProps>(headerStateAtom);

  const [useDcState, setUseDcState] = useAtom<UseDcStateProps>(useDcStateAtom);
  const [navbarState, setNavbarState] =
    useAtom<NavbarStateProps>(navbarStateAtom);

  const [countdown, setCountdown] = useState<number>(COUNTDOWN_TIME);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleSaveChangeProfile = async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const response: ResponseProfileChange = await postDataProfile();

      const { data } = response;

      if (data.status !== "success" || data.erros) {
        handleCountDown();
      }

      if (data.status === "success") {
        setHasError(false);
        setUseDcState({
          ...useDcState,
          documento: data.cpf,
          nome: data.nome,
          data_nasc: data.dt_nasc,
        });

        setNavbarState({
          ...navbarState,
          descricao: data.nome,
        });

        setTimeout(() => {
          setIsFormSubmitted(true);
          setUseSameCPF(false);
        }, 2000);

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleCountDown = () => {
    setIsLoading(false);
    setHeaderState({
      ...headerState,
      state: "error",
    });
    setHasError(true);
    setCountdown(COUNTDOWN_TIME);

    let intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(intervalId);
          return prevCountdown;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    setHeaderState({
      ...headerState,
      state: "default",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="mt-4" shadow="outline">
      <Title component="h6" color="inverted">
          Atualização disponível na sua conta!
        </Title>
      </Card>

      <Card className="mt-4">
        <Flex
          gap={3}
          direction="column"
          justify="stretch"
          fullWidth
          className="p-4"
        >
        <Title component="h6" style={{ fontSize: '20px', fontWeight: 600 }}>
        Por que alterar seu cadastro para CPF?
        </Title>
          <Flex gap={4} direction="column">
            <Text size="m">
              <span>
              Para continuar utilizando a Kangu em envios com Declaração de Conteúdo, altere seu
              cadastro e informe um CPF para esta conta.
              </span>
            </Text>
            <Text size="m">
            Com essa alteração, você terá:
              <ul>
                <li>Envios com Declaração de Conteúdo.</li>
                <li>Envios com Nota Fiscal.</li>
                <li>Etiquetas geradas com os dados da Pessoa Física.</li>
              </ul>
            </Text>
            <Text size="m">
            A partir de janeiro, não será mais possível enviar com Declaração de Conteúdo sem esta
            atualização. Saiba mais{' '}
            <Typography
            component="a"
            href="https://ajuda.kangu.com.br/hc/pt-br/articles/26279288789783-D%C3%BAvidas-sobre-a-mudan%C3%A7a-de-Cadastro-CNPJ-para-CPF"
            target="_blank"
            style={{ color: '#FF7733', textDecoration: 'none' }}
            >
            aqui
            </Typography>
            </Text>
            <Text size="m" color="secondary" style={{ fontSize: '14px' }}>
            É importante anexar o documento fiscal correto nos envios e seguir os termos de uso da plataforma e
            a legislação vigente.
            </Text>
            <Text size="m" color="secondary" style={{ fontSize: '14px' }}>
            Ao optar pelo transporte com Declaração de Conteúdo, o usuário declara que não se enquadra no
            conceito de contribuinte previsto no art. 4° da Lei Complementar n° 87/1996, ou está dispensado, por
            lei, da emissão de nota fiscal.
            </Text>
          </Flex>

          <hr className="my-1" />

          {useDcState.documento && (
               <Text size="m" color="secondary">
               O documento utilizado será{' '}
               <span style={{ color: 'initial' }}>CPF {useDcState.documento}</span>.
             </Text>
          )}

          <Text size="m">Esta ação não poderá ser desfeita</Text>

          {hasError && (
            <Flex gap={2} align="center">
              <img src={IconError} alt="Icone de erro" />
              <Text component="span" size="s" color="negative">
                <b>
                  Erro ao tentar alterar seu cadastro, tente novamente
                  {countdown > 0 ? ` em ${countdown} seg.` : "."}
                </b>
              </Text>
            </Flex>
          )}

          <Button
            fullWidth
            onClick={handleSaveChangeProfile}
            disabled={isLoading || (hasError && countdown > 0)}
          >
            Continuar
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default RequestCard;