import { type FC, memo, useCallback } from "react";

import { Controller } from "react-hook-form";

import { Col, Row } from "../../components/grid";

import FileUploader from "@andes/file-uploader";
import { AndesProvider } from "@andes/context";
import Tooltip from "@andes/tooltip";
import { Text } from "@andes/typography";
import Info16 from "@andes/icons/Info16";

import type { FileUploadProps } from "./types";

function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
  });
}

const FileUpload: FC<FileUploadProps> = ({
  label,
  control,
  setValue,
  setError,
  formState,
  fileId,
  tooltip,
}) => {
  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      const base64: string = await convertToBase64(e.target.files[0]);
      setValue(fileId, base64);
      setError(fileId, {
        type: "manual",
        message: "",
      });
    },
    [fileId, setError, setValue]
  );

  return (
    <AndesProvider locale="pt-BR">
      {tooltip ? (
        <Row gap={1}>
          <Col auto className="custom-col-tooltip">
            <Text
              className="custom-text"
              color={formState.errors[fileId]?.message ? "negative" : "primary"}
            >
              {label}
            </Text>
          </Col>
          <Col auto>
            <Tooltip
              id="tooltip-id"
              content="Aproxime a frente do documento do seu rosto e tire uma selfie em um local claro."
              closable
            >
              <Info16 />
            </Tooltip>
          </Col>
          <Controller
            name={fileId}
            control={control}
            render={({ field }) => (
              <FileUploader
                {...field}
                srLabel={label}
                className="custom-file-uploader"
                id={fileId}
                extensions={[".jpg", ".png", ".jpeg"]}
                maxSize="2MB"
                type="thumbnail"
                requirements="Permitido: JPG, JPEG, PNG. Tamanho máximo: 2MB."
                errorHelper={formState.errors[fileId]?.message}
                onChange={handleChange}
                onRemove={() => setValue(fileId, "")}
              />
            )}
          />
        </Row>
      ) : (
        <Controller
          name={fileId}
          control={control}
          render={({ field }) => (
            <FileUploader
              {...field}
              label={label}
              srLabel={label}
              className="custom-file-uploader"
              id={fileId}
              extensions={[".jpg", ".png", ".jpeg"]}
              maxSize="2MB"
              type="thumbnail"
              requirements="Permitido: JPG, JPEG, PNG. Tamanho máximo: 2MB."
              errorHelper={formState.errors[fileId]?.message}
              onChange={handleChange}
              onRemove={() => setValue(fileId, "")}
            />
          )}
        />
      )}
    </AndesProvider>
  );
};

export default memo(FileUpload);
