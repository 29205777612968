import { useRef, forwardRef, type FC } from "react";

import TextField from "@andes/textfield";
import useMask from "@andes/textfield/hooks/useMask";

import type { TextfieldWithMaskProps } from "./types";
import type { TextFieldProps } from "./typesTextField";

const TextFieldWithMask: FC<TextFieldProps & TextfieldWithMaskProps> =
  forwardRef(({ maskOptions, children, ...props }, _) => {
    const textfieldRef = useRef<HTMLInputElement>(null);

    useMask(textfieldRef, { maskOptions, ...props });

    return (
      <TextField ref={textfieldRef} {...props}>
        {children}
      </TextField>
    );
  });

export default TextFieldWithMask;
