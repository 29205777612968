import { type FC, ReactNode } from "react";

import DefaultHeader from "../header/defaultHeader";
import Head from "../head";

import { Container, Flex } from "../grid";

interface LayoutProps {
  children: ReactNode;
  pageTitle: string;
  className?: string;
}

const Index: FC<LayoutProps> = ({ children, pageTitle, className }) => {
  return (
    <>
      <Head pageTitle={pageTitle} />
      <Flex direction="column" gap={0}>
        <DefaultHeader />
        <Container className={`p-4 pt-md-5 mt-md-5 ${className || ""}`}>{children}</Container>
      </Flex>
    </>
  );
};

export default Index;
