import { type FC, memo } from "react";

import { useAtomValue, useSetAtom } from "jotai";

import { useI18n } from "nordic/i18n";

import { Title, Text } from "@andes/typography";

import { Col, Row } from "../../../components/grid";

import CardAvatar from "../../../components/login/cardAvatar";
import CardCode from "../../../components/login/cardCode";

import { type DataMfaProps, dataMfaStateAtom } from "../../../states/dataMfa";
import { stepsStateAtom } from "../../../states/steps";

interface ThirdStepProps {
  readonly currentUser: string;
}

const ThirdStep: FC<ThirdStepProps> = ({ currentUser }) => {
  const { i18n } = useI18n();
  const setStep = useSetAtom(stepsStateAtom);

  const dataMfa = useAtomValue<DataMfaProps>(dataMfaStateAtom);

  return (
    <>
      <Col size={12} md className="mx-xl-5 mt-md-4 px-md-4">
        <Row gap={0} className="px-md-2">
          <Title className="custom-text" component="h2">
            {i18n.gettext(
              "Digite o código que enviamos por {0}",
              dataMfa.title
            )}
          </Title>
          <Text className="fw-normal mb-4 pe-md-4 mt-2 mt-md-0">
            {i18n.gettext(
              "O código possui 4 dígitos e foi enviado para o {0} {1}",
              dataMfa.text,
              dataMfa.value
            )}
          </Text>
          <CardAvatar
            currentUser={currentUser}
            onClick={() => setStep({ current: 1 })}
          />
        </Row>
      </Col>
      <Col size={12} md>
        <CardCode />
      </Col>
    </>
  );
};

export default memo(ThirdStep);
