import { useI18n } from "nordic/i18n";

import Card, { CardContent } from "@andes/card";
import { Title } from "@andes/typography";
import { Flex } from "../../../../components/grid";

import TextFieldWithMask from "../../../../components/textFieldWithMask";
import { Controller, useFormContext } from "react-hook-form";
import { FieldsForCpfType } from "../../../../schemas/onboarding/personData";
import { cpfMask, dateMask } from "../../../../constants/masks";

const FieldsForCPF = () => {
  const { i18n } = useI18n();

  const { control, formState } = useFormContext<FieldsForCpfType>();

  return (
    <>
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext("Preencha seus dados de Pessoa Física")}
      </Title>

      <Card shadow="elevated" className="w-100">
        <CardContent>
          <Flex gap={4} direction="column">
            <Controller
              name="cpf"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextFieldWithMask
                  {...field}
                  maskOptions={cpfMask}
                  label="CPF do responsável"
                  placeholder="000.000.000-00"
                  modifier={formState.errors.cpf ? "error" : undefined}
                  helper={formState.errors.cpf?.message}
                  className="w-100"
                />
              )}
            />

            <Controller
              name="dataNascimento"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextFieldWithMask
                  {...field}
                  maskOptions={dateMask}
                  label="Data de nascimento"
                  placeholder="00/00/0000"
                  modifier={
                    formState.errors.dataNascimento ? "error" : undefined
                  }
                  helper={formState.errors.dataNascimento?.message}
                  className="w-100"
                />
              )}
            />
          </Flex>
        </CardContent>
      </Card>
    </>
  );
};

export default FieldsForCPF;
