import { type FC } from "react";

import { Snackbar } from "@andes/snackbar";

import { useAtom } from "jotai";
import {
  snackbarStateAtom,
  type SnackbarStateProps,
} from "../../states/snackbarState";

const SnackbarCustom: FC = () => {
  const [snackbar, setSnackbar] =
    useAtom<SnackbarStateProps>(snackbarStateAtom);

  if (!snackbar) {
    return;
  }

  const delay = snackbar.delay;

  const timer = setTimeout((): void => {
    setSnackbar({ ...snackbar, show: false });

    if (!snackbar.show) {
      clearTimeout(timer);
    }
  }, delay + 100);

  return (
    <Snackbar
      message={snackbar.message || ""}
      color={snackbar.color}
      show={snackbar.show}
      delay={snackbar.delay}
    />
  );
};

export default SnackbarCustom;
