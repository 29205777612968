import { useEffect, type FC } from "react";

import { useI18n } from "nordic/i18n";
import { useAtom } from "jotai";
import { Wizard } from "react-use-wizard";
import { Button } from "@andes/button";
import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";

import { Container } from "../../components/grid";
import {
  onboardingStateAtom,
  OnboardingStateProps,
} from "../../states/onboarding";
import { Address } from "../../containers/onboarding/address";
import { AddressCompleted } from "../../containers/onboarding/addressCompleted";
import fixPortalReactCSS from "../../utils/fixPortalReactCSS";

import "./styles.scss";
import { disableMenu, disableNavbar } from "../../utils/handlePortalNavigation";

const OnboardingAddress: FC = () => {
  const { i18n } = useI18n();

  const [onboardingState, setOnboardingState] =
    useAtom<OnboardingStateProps>(onboardingStateAtom);

  useEffect(() => {
    disableMenu(document);
    disableNavbar(document);
    fixPortalReactCSS(true, "onboarding-address");

    setOnboardingState((prevState) => ({
      ...prevState,
      stepIndex: 0,
      displayFooter: false,
    }));
  }, []);

  if (onboardingState.stepIndex === undefined) {
    return (
      <ProgressIndicatorCircular
        className="onboarding-progress-indicator"
        srLabel={i18n.gettext(
          "Buscando informações de progresso do onboarding"
        )}
        srAnnouncement={i18n.gettext("Carregando...")}
      />
    );
  }

  return (
    <div className="onboarding">
      <Container className="onboarding-container">
        <Wizard startIndex={onboardingState.stepIndex}>
          <Address />
          <AddressCompleted />
        </Wizard>
      </Container>

      <div className="onboarding-footer">
        {onboardingState.displayFooter && (
          <Button size="medium" hierarchy="transparent">
            {i18n.gettext("Preencher depois")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingAddress;
