import { type FC, ReactNode, useEffect, useState } from "react";

import { useI18n } from "nordic/i18n";
import { Flex } from "../../../components/grid";

import { Button } from "@andes/button";
import { useWizard } from "react-use-wizard";

import { PersonTypeEnum } from "../personType/enum";
import FieldsForCPF from "./fieldsByPersonType/fieldsForCPF";
import FieldsForCNPJ from "./fieldsByPersonType/fieldsForCNPJ";
import FieldsForMEI from "./fieldsByPersonType/fieldsForMEI";
import { useAtom } from "jotai";
import {
  onboardingStateAtom,
  OnboardingStateProps,
  PersonDataProps,
} from "../../../states/onboarding";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  fieldsForCpfSchema,
  fieldsForCnpjSchema,
  fieldsForMeiSchema,
  FieldsForCpfType,
  FieldsForCnpjType,
  FieldsForMeiType,
} from "../../../schemas/onboarding/personData";
import { useForm, FormProvider } from "react-hook-form";
import { OnboardingProgressProps } from "../../../services/onboarding/types";
import { saveOnboardingProgress } from "../../../pages/onboarding/controller";

const PersonData: FC = () => {
  const { i18n } = useI18n();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { goToStep, nextStep } = useWizard();

  const [onboardingState, setOnboardingState] =
    useAtom<OnboardingStateProps>(onboardingStateAtom);

  const { personType } = onboardingState;

  const renderFieldsByPersonType: { [key in PersonTypeEnum]: ReactNode } = {
    [PersonTypeEnum.CPF]: <FieldsForCPF />,
    [PersonTypeEnum.CNPJ]: <FieldsForCNPJ />,
    [PersonTypeEnum.MEI]: <FieldsForMEI />,
  };

  const setSchemaByPersonType: { [key in PersonTypeEnum]: any } = {
    [PersonTypeEnum.CPF]: fieldsForCpfSchema,
    [PersonTypeEnum.CNPJ]: fieldsForCnpjSchema,
    [PersonTypeEnum.MEI]: fieldsForMeiSchema,
  };

  const handlePreviousStep = async () => {
    const stepToGo = personType === PersonTypeEnum.CNPJ ? 0 : 1;

    setOnboardingState((prevState) => ({
      ...prevState,
      stepIndex: stepToGo,
    }));

    await handleOnboardingProgress(stepToGo);

    goToStep(stepToGo);
  };

  const methods = useForm<
    FieldsForCpfType | FieldsForCnpjType | FieldsForMeiType
  >({
    resolver: zodResolver(personType && setSchemaByPersonType[personType]),
  });

  const onSubmit = async (
    data: FieldsForCpfType | FieldsForCnpjType | FieldsForMeiType
  ) => {
    try {
      setOnboardingState((prevState) => ({
        ...prevState,
        stepIndex: 3,
        personData: {
          ...prevState.personData,
          ...data,
        },
      }));

      await handleOnboardingProgress(3, data);

      nextStep();
    } catch (error) {
      console.error(error);
    }
  };

  const setPreviousValues = () => {
    if (!onboardingState.personData) {
      return;
    }

    const { cnpj, razaoSocial, cpf, dataNascimento } =
      onboardingState.personData;

    methods.setValue("cnpj", cnpj && cnpj.length > 14 ? cnpj : "");
    methods.setValue("razaoSocial", razaoSocial || "");
    methods.setValue("cpf", cpf || "");
    methods.setValue("dataNascimento", dataNascimento || "");
  };

  const handleOnboardingProgress = async (
    stepIndex: number,
    formData?: PersonDataProps
  ) => {
    try {
      setButtonLoading(true);

      const { cnpj, razaoSocial, cpf, dataNascimento } = formData || {};

      const onboardingProgress: OnboardingProgressProps = {
        data: {
          step: stepIndex,
          st_declaracao: personType === PersonTypeEnum.CNPJ ? "N" : "S",
          tp_perfil: personType,
          doc:
            personType === PersonTypeEnum.CNPJ ||
            personType === PersonTypeEnum.MEI
              ? cnpj
              : cpf,
          razao_social: razaoSocial,
          dt_nasc: dataNascimento,
          doc_resp:
            personType === PersonTypeEnum.CNPJ ||
            personType === PersonTypeEnum.MEI
              ? cpf
              : undefined,
          dt_nasc_resp:
            personType === PersonTypeEnum.CNPJ ||
            personType === PersonTypeEnum.MEI
              ? dataNascimento
              : undefined,
        },
      };

      await saveOnboardingProgress(onboardingProgress);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    setPreviousValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mx-auto"
        style={{ maxWidth: personType === PersonTypeEnum.CPF ? "357px" : "" }}
      >
        <Flex gap={4} direction="column" className="pt-4 personData">
          {personType !== undefined && renderFieldsByPersonType[personType]}

          <Flex gap={3} align="center">
            <Button
              size="large"
              hierarchy="transparent"
              className="ms-auto"
              onClick={() => handlePreviousStep()}
            >
              {i18n.gettext("Voltar")}
            </Button>

            <Button
              loading={buttonLoading}
              srAnnouncement={i18n.gettext("Carregando...")}
              size="large"
              type="submit"
            >
              {i18n.gettext("Salvar")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default PersonData;
