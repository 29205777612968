import { useState, memo, useCallback } from "react";
import { useQueryClient } from "react-query";

import Button from "@andes/button";

import { revokeToken } from "../../pages/myAccess/controller";

import { Col, Row } from "../../components/grid";

import type { 
    PropsActionButton, 
    ResponseRevokeCredential,
    DataRevokeToken,
    PropsDataRow
} from "./types";

import type { DataFetchService } from "../../services/types";
import { useAtom } from "jotai";
import { type SnackbarStateProps, snackbarStateAtom } from "../../states/snackbarState";

export const ActionButtons: React.FC<PropsActionButton> = memo(
({ 
    token, 
    setTableHasChange,
    revokeDisabled
}) => {
    const [loadingRevoke, setLoadingRevoke] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const [snackbar, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);

    const updateCacheQuery = useCallback((token: string) => {
        const listCredentials = queryClient.getQueryData<PropsDataRow[]>(["listCredentialsFetch"]);

        if (listCredentials) {
            const newCredentials = listCredentials.map((credential) => {
                if (credential.token === token) {
                    return {
                        ...credential,
                        status: "Inativo",
                        dt_exp: (new Date()).toLocaleDateString('pt-BR')
                    }
                } 

                return credential
            });

            queryClient.setQueryData(["listCredentialsFetch"], newCredentials);
        }
    }, [queryClient]);

    const handleRevokeToken = useCallback(async (token: string): Promise<void> => {
        try {
            if (!token) {
                return;
            }

            setLoadingRevoke(true);

            const data: DataRevokeToken = {
                t: token
            };

            const response: ResponseRevokeCredential = await revokeToken(data);

            if (response?.error) {
                const message: string | undefined = response?.exception?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }
            }

            if (response?.success) {
                setSnackbar({
                    ...snackbar,
                    message: "Credencial inativada com sucesso.",
                    color: "green",
                    show: true,
                });

                updateCacheQuery(token);

                setTableHasChange(true);
            }            
        } catch (error: unknown) {
            const getError = error as DataFetchService;

            if (getError?.exception) {
                setSnackbar({
                    ...snackbar,
                    message: getError?.exception?.message,
                    color: "red",
                    show: true,
                });
            }
        } finally {
           setLoadingRevoke(false);
        }
    }, [setSnackbar, setTableHasChange, snackbar, updateCacheQuery]);

    const copyCredencial = useCallback((token: string) => {
        if (!token) {
            return;
        }

        const el = document.createElement("textarea");
        el.value = token;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        setSnackbar({
            ...snackbar,
            message: "Credencial copiado com sucesso!",
            color: "green",
            show: true,
        });

    }, [setSnackbar, snackbar]);

    return (
        <Col
            md="auto"
        >
            <Row
                className="p-md-0 p-3"
                justify="center"
                align="center"
                gap={1}
            >  
                <Col
                    auto
                >
                    <Button
                        className="button-quiet rounded"
                        hierarchy="quiet"
                        size="small"
                        onClick={() => copyCredencial(token)}
                        disabled={revokeDisabled}
                        srAnnouncement="Copiar"
                    >
                        Copiar
                    </Button>
            
                </Col>
                <Col
                    auto
                    className="position-relative"
                >
                    <Button
                        className="button-quiet rounded"
                        hierarchy="quiet"
                        size="small"
                        onClick={() => handleRevokeToken(token)}
                        loading={loadingRevoke}
                        disabled={revokeDisabled ?? loadingRevoke}
                        srAnnouncement="Excluir"
                    >
                        Excluir
                    </Button>
                </Col>         
            </Row>
        </Col>
    );
});