import { Flex } from "../../components/grid";

import Card from "@andes/card";
import { Title, Text } from "@andes/typography";
import { Button } from "@andes/button";
import { useAtom } from "jotai";
import { type FC, useEffect } from "react";
import { type HeaderStateProps, headerStateAtom } from "../../states/header";

const ActionCard: FC = () => {
  const handleLogout = () => {
    window.location.href = "/auth/user/logout";
    return;
  };

  const [headerState, setHeaderState] =
    useAtom<HeaderStateProps>(headerStateAtom);

  useEffect(() => {
    setHeaderState({
      ...headerState,
      state: "default",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="mt-4" shadow="outline">
        <Title component="h6" color="inverted">
          Está conta já tem utilização de declaração de conteúdo
        </Title>
      </Card>

      <Card className="mt-4">
        <Flex
          gap={2}
          direction="column"
          justify="stretch"
          fullWidth
          className="p-4"
        >
          <Title component="h6" weight="semibold">
            Todas as ofertas de transportadoras já estão associadas a esta conta
          </Title>

          <Text size="m" color="secondary">
            Caso você possua uma conta CNPJ e queira ter acesso a envios com
            declaração de conteúdo, realize o login com sua outra conta para
            realizar este procedimento.
          </Text>

          <hr className="my-2" />

          <Button fullWidth onClick={handleLogout}>
            Voltar
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default ActionCard;
