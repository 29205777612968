import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ReactTagManager } from "react-gtm-ts";
import SendGaEvents from "../../utils/sendGaEvents";

const usePageTracking = () => {
  const gtmID = "GTM-PP3V7ZC";

  ReactTagManager.init({
    code: gtmID,
    debug: false,
    performance: false,
  });

  const location = useLocation();

  useEffect(() => {
    SendGaEvents({ event: "page_view" });
  }, [location]);
};

export default usePageTracking;
