import { type FC, memo, useEffect, useState } from "react";
import { useI18n } from "nordic/i18n";
import { get } from "../../utils/cookies";

import Typography, { Text } from "@andes/typography";
import { Message, MessageActions } from "@andes/message";
import { Flex } from "../../components/grid";

export interface ShutdownDataProps {
  shutdownEnabled: boolean;
  shutdownDate: string;
  cashLimitDate: string;
  deadlinesLink: string;
  detailsLink: string;
  withdrawLink: string;
}

const useOldBanner = get("use_old_banner")!;

const ShutdownBanner: FC = () => {
  const { i18n } = useI18n();

  const [shutdownData, setShutdownData] = useState<ShutdownDataProps>();

  const handleDetailsClick = () => {
    window.location.href = shutdownData?.detailsLink || "";
  };
  const handleWithdrawClick = () => {
    window.location.href = shutdownData?.withdrawLink || "";
  };

  useEffect(() => {
    if (!shutdownData) {
      setShutdownData(JSON.parse(get("shutdown_data")!));
    }
  }, [shutdownData]);

  if (!shutdownData?.shutdownEnabled) {
    return null;
  }

  if (useOldBanner) {
    return (
      <div className="px-lg-4 mx-xl-5">
        <Message
          color="red"
          closable={false}
          actions={
            <MessageActions
              primary={{
                text: "Consultar detalhes",
                onClick: handleDetailsClick,
              }}
              secondary={{
                text: "Retirar créditos",
                onClick: handleWithdrawClick,
              }}
            />
          }
          className="mt-3 mt-md-n-3 mt-xxl-n-7 ms-xl-5 me-xl-5"
        >
          <Flex gap={1} direction="column">
            <Text size="m" weight="semibold" color="inverted">
              {i18n.gettext(
                `A contratação de envios pela Kangu será encerrada no dia ${
                  shutdownData?.shutdownDate || ""
                }`
              )}
            </Text>

            <Text size="s" color="inverted">
              {i18n.gettext(
                "Continuaremos a oferecer suporte até que todos os envios feitos dentro da data limite sejam concluídos. Pedimos que, caso possua créditos em sua carteira digital, "
              )}

              <Text size="s" color="inverted" weight="semibold">
                {i18n.gettext("solicite o resgate o mais rápido possível ")}
              </Text>

              {i18n.gettext("e quite os eventuais débitos pendentes. As ")}

              <Text size="s" color="inverted" weight="semibold">
                {i18n.gettext(
                  "integrações com plataformas e ERPs também serão encerradas"
                )}
              </Text>

              {i18n.gettext(" no mesmo prazo.")}
            </Text>

            <Text size="s" color="inverted">
              {i18n.gettext(
                "Agradecemos por toda a parceria e pela confiança depositada em nossos serviços."
              )}
            </Text>
          </Flex>
        </Message>
      </div>
    );
  }

  return (
    <div className="px-lg-4 mx-xl-5 d-none" id="shutdownBanner">
      <Message
        color="red"
        closable={false}
        className="mt-3 mt-md-n-3 mt-xxl-n-7 ms-xl-5 me-xl-5"
      >
        <div id="banner"></div>
      </Message>
    </div>
  );
};

export default memo(ShutdownBanner);
