export const fetchAvatar = async (avatar: string): Promise<string | null> => {
  try {
    const response = await fetch(avatar, {
      mode: "no-cors",
    });

    if (response.status !== 200) {
      return null;
    }

    const blob = await response.blob();

    if (blob.size > 0 && blob.type.includes("image")) {
      return avatar;
    }

    return null;
  } catch {
    return null;
  }
};
