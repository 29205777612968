import * as zod from "zod";
import { sanitizeData } from "../../../utils/sanitizeData";

export const fieldsForAddressSchema = zod.object({
  cep: zod
    .string({ required_error: "Informe o CEP." })
    .min(1, "Informe o CEP.")
    .min(9, "CEP inválido.")
    .max(9, "CEP inválido."),
  logradouro: zod
    .string({
      required_error: "Informe o logradouro.",
      message: "Informe o logradouro.",
    })
    .min(1, "Informe o logradouro.")
    .transform((value) => sanitizeData(value).trim()),
  numero: zod
    .number({
      required_error: "Informe o número.",
      invalid_type_error: "Informe o número.",
    })
    .transform((value) => sanitizeData(value)),
  complemento: zod
    .string()
    .default("")
    .transform((value) => sanitizeData(value).trim()),
  bairro: zod
    .string({ required_error: "Informe o bairro." })
    .min(1, "Informe o bairro.")
    .transform((value) => sanitizeData(value).trim()),
  cidade: zod
    .string({ required_error: "Informe a cidade." })
    .transform((value) => sanitizeData(value).trim()),
  estado: zod
    .string({ required_error: "Informe o estado." })
    .transform((value) => sanitizeData(value).trim()),
});

export type FieldsForAddressType = zod.infer<typeof fieldsForAddressSchema>;
