import { type FC, memo, useCallback, useEffect, useState } from "react";

import { useAtomValue, useSetAtom } from "jotai";

import { useI18n } from "nordic/i18n";

import { Col, Flex, Row } from "../../components/grid";

import { Button, ProgressButton } from "@andes/button";
import { Text } from "@andes/typography";
import { Card, CardContent } from "@andes/card";
import CodeField from "../../components/codeField";

import { confirmMfa, sendMfa } from "../../pages/loginMeli/login/controller";

import { type DataMfaProps, dataMfaStateAtom } from "../../states/dataMfa";
import { snackbarStateAtom } from "../../states/snackbarState";
import { stepsStateAtom } from "../../states/steps";

import { get, remove } from "../../utils/cookies";

import type { DataFetchService } from "../../services/types";

const INITIAL_COUNTER = 30;
const MIN_CODE_LENGTH = 4;

interface CardCodeProps {
  readonly isCommonLogin?: boolean;
}

const CardCode: FC<CardCodeProps> = ({ isCommonLogin }) => {
  const [valueCode, setValueCode] = useState<string>("");
  const [messageError, setMessageError] = useState<string>("");
  const [counter, setCounter] = useState<number>(INITIAL_COUNTER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeoutButton, setTimeoutButton] = useState<number | undefined>(undefined);

  const setSnackbar = useSetAtom(snackbarStateAtom);

  const dataMfa = useAtomValue<DataMfaProps>(dataMfaStateAtom);

  const setStep = useSetAtom(stepsStateAtom);

  const { i18n } = useI18n();

  const handleCounter = useCallback(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(interval);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const showSnackbarErrors = useCallback(
    (error: unknown) => {
      const getError = error as DataFetchService;
      let message = "";

      setIsLoading(false);

      if (getError?.exception) {
        message = getError?.exception?.message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setSnackbar((prev) => ({
        ...prev,
        message,
        color: "red",
        show: true,
      }));
    },
    [setSnackbar]
  );

  const fetchSendMfa = async () => {
    if (counter > 0) {
      return;
    }

    try {
      const response = await sendMfa({
        temp_token: dataMfa.tempToken,
        opcao_mfa: dataMfa.type,
      });

      if (!response?.data?.success || response?.data?.error) {
        throw new Error(response?.data?.message);
      }

      setSnackbar((prev) => ({
        ...prev,
        message: response?.data?.message as string,
        color: "green",
        show: true,
      }));

      setCounter(INITIAL_COUNTER);

      handleCounter();
    } catch (error) {
      showSnackbarErrors(error);
    }
  };

  const handleLogged = (): void => {
    let url = "/";
    setTimeoutButton(3000);

    if (isCommonLogin) {
      const params = new URLSearchParams(document.location.search);
      url = params.get("success") ?? url;

      if (get("successRedirectUrl")) {
        url = get("successRedirectUrl", true)!;
      }
    } else if (get("utm")) {
      url = `/auth/mercadolivre/match-account?code=${get("utm")!}`;
    }

    window.location.href = url;
    remove("utm_resource");
    remove("utm_cs");
  };

  const validateCode = useCallback(() => {
    if (valueCode.length < MIN_CODE_LENGTH) {
      setMessageError("Digite um código válido");
      return false;
    }
    setMessageError("");
    return true;
  }, [valueCode]);

  const fetchConfirmMfa = useCallback(async () => {
    try {
      if (!validateCode()) {
        return;
      }

      setIsLoading(true);
      const response = await confirmMfa({
        temp_token: dataMfa.tempToken,
        codigo_mfa: valueCode,
        loginviaportal: true,
      });

      if (!response?.data?.success || response?.data?.error) {
        throw new Error(response?.data?.message);
      }

      if (response?.data?.logged) {
        handleLogged();
      }
    } catch (error) {
      showSnackbarErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMfa.tempToken, showSnackbarErrors, validateCode, valueCode]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const keycode = e.keyCode || e.which;
      if (keycode === 13) {
        fetchConfirmMfa();
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [fetchConfirmMfa]);

  useEffect(() => handleCounter(), [handleCounter]);

  return (
    <Card className="shadow-none p-3">
      <CardContent>
        <Flex align="center" className="mb-4">
          <CodeField
            label={"Insira o código"}
            setValueCode={setValueCode}
            setMessageError={setMessageError}
            messageError={messageError}
          />
        </Flex>
        {counter > 0 ? (
          <Text className="text-secondary">
            {i18n.gettext("Reenviar código em {0} segundos", counter)}
          </Text>
        ) : (
          <Text
            onClick={fetchSendMfa}
            className="text-resend-code text-primary"
          >
            {i18n.gettext("Reenviar código")}
          </Text>
        )}
        <Col className="mt-4">
          <Row>
            <Col md size={12}>
              <ProgressButton
                hierarchy="loud"
                className="rounded w-100 custom-button"
                progressLabel="Confirmando código..."
                onClick={fetchConfirmMfa}
                loading={isLoading}
                disabled={isLoading}
                timeout={timeoutButton}
              >
                {i18n.gettext("Confirmar código")}
              </ProgressButton>
            </Col>
            <Col md size={12} className="text-center">
              <Button
                hierarchy="transparent"
                className="w-100  px-md-2 px-xl-0"
                onClick={() => setStep({ current: 2 })}
              >
                Escolher outro método
              </Button>
            </Col>
          </Row>
        </Col>
      </CardContent>
    </Card>
  );
};

export default memo(CardCode);
