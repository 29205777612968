import { type FC, memo, useState } from "react";

import { useI18n } from "nordic/i18n";
import { useSetAtom } from "jotai";

import { Title } from "@andes/typography";
import { Card, CardContent } from "@andes/card";

import { Col, Flex, Row } from "../../../components/grid";

import CardOptionMfa from "../../../components/login/cardMfa";

import type { ResponseLogin } from "./types";
import CardAvatar from "../../../components/login/cardAvatar";
import { stepsStateAtom } from "../../../states/steps";

interface SecondStepProps {
  readonly currentUser: string;
  readonly responseLogin?: ResponseLogin;
}

const TYPE_EMAIL = "E";

const SecondStep: FC<SecondStepProps> = ({ currentUser, responseLogin }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setStep = useSetAtom(stepsStateAtom);

  const optionsMfa = responseLogin?.opcoes_mfa
    ? responseLogin.opcoes_mfa.split(",")
    : [];
  const tempToken = responseLogin?.temp_token || "";

  const getValueOption = (option: string): string =>
    option === TYPE_EMAIL
      ? (responseLogin?.email as string)
      : (responseLogin?.celular as string);

  const { i18n } = useI18n();

  return (
    <>
      <Col size={12} md className="mx-xl-5 mt-5 px-md-4">
        <Row gap={4} className="px-md-2">
          <Title className="custom-text" component="h2">
            {i18n.gettext(
              "Escolha um método de verificação para confirmar o seu acesso"
            )}
          </Title>
          <CardAvatar
            currentUser={currentUser}
            onClick={() => setStep({ current: 1 })}
          />
        </Row>
      </Col>

      <Col size={12} md>
        <Card className="mb-4 p-lg-2 shadow-sm">
          <CardContent>
            <Row align="center">
              <Col>
                <Row>
                  <Col className="mt-4" size={12}>
                    <Flex direction="column" justify="center" align="center">
                      {optionsMfa.map((option) => (
                        <CardOptionMfa
                          key={option}
                          option={option}
                          value={getValueOption(option)}
                          tempToken={tempToken}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      ))}
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>
    </>
  );
};

export default memo(SecondStep);
