import { useAtomValue } from "jotai";

import { HeaderStateProps, headerStateAtom } from "../../states/header";

import "./styles.scss";

const Header: React.FC = () => {
  const headerState = useAtomValue<HeaderStateProps>(headerStateAtom);

  return <header className={["animatedHeader", headerState.state].join(" ")} />;
};

export default Header;
