import { type FC, memo } from "react";

import { motion } from "framer-motion";

import Card, { CardContent } from "@andes/card";
import { Text, Title } from "@andes/typography";

import { useI18n } from "nordic/i18n";

import { Col, Flex, Row } from "../../components/grid";
import Placeholder from "../../components/placeholder";
import ImageAdvantage from "./imageAdvantage";

import type { CardAdvantagesProps } from "./types";

const CardAdvantages: FC<CardAdvantagesProps> = ({ dataIsLoading }) => {
  const { i18n } = useI18n();

  return (
    <Card className="mb-4 py-3 px-md-5 px-3">
      <CardContent>
        <Row align="center">
          <Col
            md
            size={12}
            className="mb-3 mb-lg-0 pe-lg-5 me-lg-5 text-center"
          >
            {dataIsLoading ? (
              <motion.div
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <Placeholder className="custom-placeholder" size={8} />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <Flex align="center" justify="center">
                  <ImageAdvantage />
                </Flex>
              </motion.div>
            )}
          </Col>
          <Col>
            <Row>
              <Title className="title-advantages" component="h2">
                {i18n.gettext("Por favor, verifique sua conta para continuar.")}
              </Title>
              <Text className="custom-text">
                {i18n.gettext("Esse é um procedimento ")}
                <b>
                  {i18n.gettext("padrão de segurança para você e seu negócio")}
                </b>
                {i18n.gettext(
                  ". Aproveite os benefícios de uma conta verificada."
                )}
              </Text>
              <Text className="custom-text">
                <i className="fa-regular fa-circle-check me-1"></i>
                {i18n.gettext("Aumente seu limite de envios simultâneos")}
              </Text>
              <Text className="m-0 custom-text">
                <i className="fa-regular fa-circle-check me-1"></i>
                {i18n.gettext("Procedimento online, seguro e sem custo")}
              </Text>
              <Text className="m-0 custom-text">
                <i className="fa-regular fa-circle-check me-1"></i>
                {i18n.gettext(
                  "Evita que pessoas mal intencionadas usem seus dados"
                )}
              </Text>
            </Row>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardAdvantages);
