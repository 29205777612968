/* eslint-disable jsx-a11y/alt-text */
import { FC, useState } from "react";

import { useAtomValue } from "jotai";
import { Button } from "@andes/button";
import Card, { CardContent, CardSecondary } from "@andes/card";
import { Text, Title } from "@andes/typography";

import { Flex } from "../../components/grid";
import { Modal } from "../../components/modal";
import {
  orderListStateAtom,
  type OrderListStateProps,
} from "../../states/orderList";
import { verifyCartPromise } from "../../pages/orderList/controller";
import { IDataItem } from "../../services/orderList/types";

export const Footer: FC = () => {
  const orderListState = useAtomValue<OrderListStateProps>(orderListStateAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleVerifyCartPromise = async (): Promise<void> => {
    setLoading(true);
    const response = await verifyCartPromise(orderListState.idOrdersSelected);

    const { data } = response;

    const ordersArray =
      data && data && typeof data === "object" ? Object.values(data) : [];

    const ordersChanged = ordersArray.filter(
      (order: IDataItem) => order.changed === true
    );

    if (ordersChanged.length > 0) {
      window.location.reload();
      setLoading(false);
      return;
    }

    if (orderListState.places.length > 1) {
      setOpenModal(true);
      setLoading(false);
      return;
    }

    // Redirect to pre-fatura
    linkPreFatura();
  };

  const linkPreFatura = (): void => {
    window.location.href = `/vendas/pedido/pre-fatura/pedidos/${orderListState.idOrdersSelected.join(
      ","
    )}`;
  };

  return (
    <>
      <Card className="custom-footer">
        <CardContent>
          <Flex
            justify="end"
            gap={2}
            align="center"
            wrap={orderListState.isMobile ? "wrap" : ""}
          >
            <Flex
              align="center"
              justify="center"
              className={orderListState.isMobile ? "w-100" : ""}
            >
              <Text size="m">
                Total:{" "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(orderListState.totalValue)}
              </Text>
            </Flex>
            <Flex
              direction="row"
              gap={1}
              align="center"
              justify="center"
              className={orderListState.isMobile ? "w-100" : ""}
            >
              <Button
                loading={loading}
                onClick={handleVerifyCartPromise}
                disabled={
                  orderListState.totalSelectedOrders === 0 ||
                  orderListState.isHighLight
                }
              >{`Pagar (${orderListState.totalSelectedOrders}/${orderListState.totalOrders})`}</Button>
              <Button
                hierarchy="transparent"
                type="button"
                href="/simulacao-frete-v2"
                disabled={orderListState.isHighLight}
              >
                Criar novo pedido
              </Button>
            </Flex>
          </Flex>
        </CardContent>
      </Card>

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Fique atento!"
          type="medium"
          className="py-2"
          headerClassName="text-center"
        >
          <Flex direction="column" justify="center" align="center" gap={2}>
            <p>
              Existem <b>diferentes</b> pontos de postagem para os pedidos
              selecionados.
            </p>
            {orderListState.places.map((place) => (
              <Card>
                <CardSecondary className="custom-address">
                  <Flex direction="column">
                    <Flex direction="row" align="center" justify="between">
                      <Title component="h4" size="xs">
                        {place.name}
                      </Title>
                      <img src={place.logo} width={24} height={24} />
                    </Flex>
                    <Text size="m" color="secondary">
                      {place.address}
                    </Text>
                  </Flex>
                </CardSecondary>
              </Card>
            ))}
            <Button
              fullWidth
              onClick={() => {
                setOpenModal(false);
                linkPreFatura();
              }}
            >
              Confirmar
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  );
};
