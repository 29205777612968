import type { FC } from "react";
import { ReactTagManager } from "react-gtm-ts";

import { SendGaEventsProps } from "./types";

const SendGaEvents: FC<SendGaEventsProps> = (props) => {
  const {
    event = "page_view",
    pageTitle = document.title,
    pagePath = window.location.pathname,
    pageLocation = window.location.href,
    pageReferrer = document.referrer,
  } = props || {};

  if (event === "page_view") {
    ReactTagManager.action({
      event: event,
      page_title: pageTitle,
      page_path: pagePath,
      page_location: pageLocation,
      page_referrer: pageReferrer,
    });

    return null;
  }

  ReactTagManager.action(props);

  return null;
};

export default SendGaEvents;
