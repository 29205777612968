import type { FC } from "react";

import type { CardAsRadioProps, CardAsRadioItemProps } from "./types";

import Card, { CardContent } from "@andes/card";
import { Flex } from "../grid";

import "./styles.scss";

const CardAsRadio: FC<CardAsRadioProps> = ({ children, ...props }) => {
  const {
    direction = "row",
    wrap = "wrap",
    gap = 4,
    align = "stretch",
    justify = "between",
    className,
  } = props;

  return (
    <Flex
      direction={direction}
      wrap={wrap}
      gap={gap}
      align={align}
      justify={justify}
      className={["cardAsRadio", className].join(" ")}
    >
      {children}
    </Flex>
  );
};

const CardAsRadioItem: FC<CardAsRadioItemProps> = (props) => {
  const { title, content, onClick, checked, shadowType, className } = props;

  return (
    <Card
      shadow={shadowType}
      className={[checked && "andes-card--checked", className].join(" ")}
      onClick={onClick}
    >
      <CardContent>
        {title && (
          <>
            {title}

            <hr />
          </>
        )}

        {content}
      </CardContent>
    </Card>
  );
};

export { CardAsRadio, CardAsRadioItem };
