import { useState, useEffect, useCallback, useMemo } from "react";

import { EmptyStateWithIllustration } from "@andes/empty-state";
import Card, { CardContent } from "@andes/card";
import Typography from "@andes/typography";
import Button from "@andes/button";
import { SomethingWentWrong } from "../../illustrations/somethingWentWrong";

import TableWithPagination from "../../components/table";
import { PillStatus } from "./pillStatuts";
import { ActionButtons } from "./actionButtons";

import { listCredentials } from "../../pages/myAccess/controller";

import { DataTable, IColumnData } from "../../components/table/types";
import { ModalCreateCredential } from "./modalCreateCredential";
import DataRow from "./dataRow";
import { Col, Row, Flex } from "../../components/grid";

import { useQuery } from "react-query";

import type {
  DataListCredentials,
  PropsDataRow,
  ReturnSetDataRows,
} from "./types";

export const Credentials: React.FC = () => {
  const [rows, setRows] = useState<ReturnSetDataRows[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tableHasChange, setTableHasChange] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);

  const isMobileDevice: boolean = window.innerWidth < 768;
  const itemsPerPage: number = useMemo(
    () => (!isMobileDevice ? 100 : 2),
    [isMobileDevice]
  );

  const columnsHead: IColumnData[] = useMemo(
    () => [
      { title: "Nome", visible: true },
      { title: "Criado em", visible: true },
      { title: "Expira em", visible: true },
      { title: "Credencial/Token", visible: true },
      { title: "Situação", visible: true },
      { title: "Ações", visible: true },
    ],
    []
  );

  const dataKeys: string[] = useMemo(
    () => [
      "descricao",
      "criadoEm",
      "expiraEm",
      "credencial",
      "situacao",
      "acao",
    ],
    []
  );

  const maskToken = useCallback((str: string, length: number): string => {
    if (str.length <= length) {
      return str;
    }

    const firstChars = str.slice(0, length);

    return `${firstChars}*****`;
  }, []);

  const setDataRowsInTable = useCallback(
    (rows: PropsDataRow[] | boolean | undefined): void => {
      const defaultRow = {
        id: "",
        descricao: "",
        criadoEm: "",
        expiraEm: "",
        credencial: "",
        situacao: "",
        acao: "",
      };

      const rowsData = !rows
        ? [defaultRow]
        : (rows as PropsDataRow[]).map((data: PropsDataRow) => ({
            id: data.id,
            descricao: <DataRow data={data.descricao} />,
            criadoEm: <DataRow data={data.dh_inc.split(" ")[0]} />,
            expiraEm: <DataRow data={data.dt_exp} />,
            credencial: <DataRow data={maskToken(data.token, 5)} />,
            situacao: <PillStatus status={data.status} />,
            acao: (
              <ActionButtons
                token={data.token}
                setTableHasChange={setTableHasChange}
                revokeDisabled={data.status !== "Ativo"}
              />
            ),
          }));

      setShowTable(!!rows);
      setRows(rowsData);
    },
    [maskToken]
  );

  const getCredentials = async (): Promise<PropsDataRow[] | boolean> => {
    const credentials: DataListCredentials = await listCredentials();

    setTableHasChange(true);

    if (!credentials) {
      return false;
    }

    const credentialRows: PropsDataRow[] | undefined = credentials?.data?.rows;

    if (!credentialRows) {
      return false;
    }

    return credentialRows;
  };

  const { data } = useQuery<PropsDataRow[] | boolean>(
    "listCredentialsFetch",
    getCredentials,
    {
      staleTime: 50000, //5 minutes
    }
  );

  useEffect(() => {
    if (tableHasChange) {
      setTableHasChange(false);
      setDataRowsInTable(data);
    }
  }, [data, setDataRowsInTable, tableHasChange, showTable]);

  return (
    <>
      <Card>
        <CardContent>
          <Row gap={3} justify="center">
            <Col size={12}>
              <Typography weight="semibold" size="l">
                API - Credenciais / Tokens
              </Typography>
            </Col>

            {showTable ? (
              <Col size={12}>
                <TableWithPagination
                  columns={columnsHead}
                  data={rows as unknown as DataTable[]}
                  itemsPerPage={itemsPerPage}
                  dataKeys={dataKeys}
                />
              </Col>
            ) : (
              <Col auto>
                <EmptyStateWithIllustration
                  className="empty-state-illustration"
                  title="Você ainda não tem nenhuma API integrada!"
                  description="Crie  uma nova credencial para autenticar suas requisições"
                  asset={<SomethingWentWrong width={160} />}
                />
              </Col>
            )}
          </Row>

          <Flex gap={3} justify="end">
            <Col size={12} md="auto" className="pt-4">
              <Button
                className="button-quiet-orange rounded"
                hierarchy="quiet"
                fullWidth
                onClick={() => setOpenModal(true)}
              >
                Nova credencial
              </Button>
            </Col>
          </Flex>
        </CardContent>
      </Card>
      <ModalCreateCredential
        openModal={openModal}
        setOpenModal={setOpenModal}
        setTableHasChange={setTableHasChange}
      />
    </>
  );
};
