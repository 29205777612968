import Service from "../../Service";

import type {
  LoginRequest,
  LoginResponse,
  SendMfaRequest,
  SendMfaResponse,
  ConfirmMfaRequest,
  ConfirmaMfaResponse,
} from "./types";

export default class LinkAuthorizationService {
  static async login(data: LoginRequest): Promise<LoginResponse> {
    return (await new Service().doPost(
      "/mfa/login",
      data
    )) as unknown as Promise<LoginResponse>;
  }

  static async sendMfa(data: SendMfaRequest): Promise<SendMfaResponse> {
    return (await new Service().doPost(
      "/mfa/envia-mfa",
      data
    )) as unknown as Promise<SendMfaResponse>;
  }

  static async confirmMfa(
    data: ConfirmMfaRequest
  ): Promise<ConfirmaMfaResponse> {
    return (await new Service().doPost(
      "/mfa/confirma-mfa",
      data
    )) as unknown as Promise<ConfirmaMfaResponse>;
  }
}
