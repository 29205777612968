import { 
    memo, 
    useCallback, 
    useState,
} from "react";

import { useQueryClient } from "react-query";

import Button from "@andes/button";
import TextField from '@andes/textfield';
import Typography from "@andes/typography";

import { Modal } from "../../components/modal";
import { Col, Row } from "../../components/grid";

import { validateMfa, sendCodeMfa } from "../../pages/myAccess/controller";

import type { 
    PropsModalMfa,
    PropsValidateMfa,
    DataValidateMfa,
    PropsSendDataCode,
    DataSendCodeMfa,
    DataCheckMfa
} from "./types";

import type { DataFetchService } from "../../services/types";
import SendButtonCode from "./sendButtonCode";
import { useAtom } from "jotai";
import { type SnackbarStateProps, snackbarStateAtom } from "../../states/snackbarState";

export const ModalMfa: React.FC<PropsModalMfa> = memo(
({ 
    openModal, 
    setOpenModal,
    typeMfa,
    field,
    setMfaHasChange,
    timer,
    setTimer,
    handleTimer
}) => {
    const mfa: string = typeMfa === "S" || typeMfa === "W" ? "número de celular" : "e-mail";

    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<boolean>(false);
    const [firstInput, setFirstInput] = useState<string>("");
    const [secondInput, setSecondInput] = useState<string>("");
    const [thirdInput, setThirdInput] = useState<string>("");
    const [fourthInput, setFourthInput] = useState<string>("");
    const [loadingResend, setLoadingResend] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const [snackbar, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);

    const resetModal = useCallback((): void => {
        setErrorCode(false);
        setFirstInput("");
        setSecondInput("");
        setThirdInput("");
        setFourthInput("");
    }, []);

    const handleCloseModal = useCallback((): void => {
        setOpenModal(false);
        resetModal();
    }, [resetModal, setOpenModal]);

    const validateCode = useCallback((code: string): boolean => {
        if (!code || code.length < 4 || !JSON.stringify(code)) {
            setErrorCode(true);
            return false;
        }

        setErrorCode(true);
        return true;
    }, []);

    const updateCacheQuery = useCallback(() => {
        const checkedMfa = queryClient.getQueryData<DataCheckMfa | void>(["getMfaActiveFetch"]);

        if (checkedMfa?.data) {
            if (typeMfa === "S") {
                checkedMfa.data.SMS = "A";
            } else if (typeMfa === "W") {
                checkedMfa.data.WhatsApp = "A";
            } else {
                checkedMfa.data.Email = "A";
            }

            queryClient.setQueryData(["getMfaActiveFetch"], checkedMfa);
        }
    }, [queryClient, typeMfa]);

    const handleValidateCode = async (lastInput: string = ""): Promise<void> => {
        try {
            setLoadingButton(true);

            const newCode: string = `${firstInput}${secondInput}${thirdInput}${lastInput || fourthInput}`;

            if(!validateCode(newCode)) return;

            setErrorCode(false);

            const data: PropsValidateMfa = {
                codigo: newCode,
                return_json: true,
                mfatipo: typeMfa
            };

            const response: DataValidateMfa = await validateMfa(data);

            if (response?.error) {
                const message: string | undefined = response?.exception?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.data?.ok === false) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.success && response?.data?.ok === true) {
                setSnackbar({
                    ...snackbar,
                    message: "Autenticação de 2 fatores cadastrado com sucesso",
                    color: "green",
                    show: true,
                });
                handleCloseModal();

                updateCacheQuery();

                setTimer(0);

                setMfaHasChange(true);
            }            
        } catch (error: unknown) {
            const getError = error as DataFetchService;

            if (getError?.exception) {
                setSnackbar({
                    ...snackbar,
                    message: getError?.exception?.message,
                    color: "red",
                    show: true,
                });
            }
        } finally {
            setLoadingButton(false);
        }
    };

    const sliceValueWithMaxLength = (value: string) => value.slice(0, 1);

    const allowOnlyPatternsChars = (value: string) => value.replace(/[^a-zA-Z0-9]/g, '');

    const handleChangeInput = (e: Event & { target: HTMLInputElement}, input: string): void => {
        let value: string = e.target.value;

        if (value) {
            value = sliceValueWithMaxLength(value);
            value = allowOnlyPatternsChars(value);
            value = value.toLocaleUpperCase();

            setErrorCode(false);
        }        

        if (input === "first") {
            setFirstInput(value);

            if (value?.length) {
                document.getElementById("second-input")?.focus();
            }
        } else if (input === "second") {
            setSecondInput(value);

            if (value?.length) {
                document.getElementById("third-input")?.focus();
            }
        } else if (input === "third") {
            setThirdInput(value);

            if (value?.length) {
                document.getElementById("fourth-input")?.focus();
            }
        } else {
            setFourthInput(value);

            if (firstInput && secondInput && thirdInput && value) {
                handleValidateCode(value)
            }
        }
    };

    const sendCode = async (): Promise<void> => {
        try {
            if (loadingResend) {
                return;
            }

            setLoadingResend(true);

            const data: PropsSendDataCode = {
                metodoTipo: typeMfa
            };

            const response: DataSendCodeMfa = await sendCodeMfa(data);

            if (response?.error) {
                const message: string | undefined = response?.exception?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.data?.ok === false) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.success && response?.data?.ok === true) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "green",
                        show: true,
                    });
                }

                handleTimer(typeMfa);
            }
        } catch (error: unknown) {
            const getError = error as DataFetchService;

            if (getError?.exception) {
                setSnackbar({
                    ...snackbar,
                    message: getError?.exception?.message,
                    color: "red",
                    show: true,
                });
            } else if (error instanceof Error) {
                setSnackbar({
                    ...snackbar,
                    message: error?.message,
                    color: "red",
                    show: true,
                });
            }
        } finally {
            setLoadingResend(false);
        }
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            type="medium"
            className="py-3"
            bodyClassName="py-2"
            headerClassName="text-center ms-5 pe-4"
            title="Informe o código de segurança"
        >
            <Col
                size={12}
            >
                <Row
                    align="center"
                    justify="center"
                    gap="3"
                >
                    <Col
                        size={12}
                        className="mt-0 text-center"
                    >
                        <Typography
                            size="s"
                        >
                            Insira abaixo o código enviado para o {mfa}
                        </Typography>
                    </Col>
                    <Col
                        size={12}
                        className="mt-0 text-center"
                    >
                        <Typography
                            size="s"
                            weight="semibold"
                        >
                            {field}
                        </Typography>
                    </Col>
                    <Col
                        md={8}
                        size={12}
                    >
                        <Row
                            align="center"
                            justify="center"
                            gap={2}
                        >
                            <Col
                                size={2}
                            >
                                <TextField 
                                    srLabel="Código 2FA"
                                    onChange={(e: Event & { target: HTMLInputElement}) => handleChangeInput(e, "first")}
                                    value={firstInput}
                                    className="custom-input-code"
                                    id="first-input"
                                />
                            </Col>
                            <Col
                                size={2}
                            >
                                <TextField 
                                    srLabel="Código 2FA"
                                    onChange={(e: Event & { target: HTMLInputElement}) => handleChangeInput(e, "second")}
                                    value={secondInput}
                                    className="custom-input-code"
                                    id="second-input"
                                />
                            </Col>
                            <Col
                                size={2}
                            >
                                <TextField 
                                    srLabel="Código 2FA"
                                    onChange={(e: Event & { target: HTMLInputElement}) => handleChangeInput(e, "third")}
                                    value={thirdInput}
                                    className="custom-input-code"
                                    id="third-input"
                                />
                            </Col>
                            <Col
                                size={2}
                            >
                                <TextField 
                                    srLabel="Código 2FA" 
                                    onChange={(e: Event & { target: HTMLInputElement}) => handleChangeInput(e, "fourth")}
                                    value={fourthInput}
                                    className="custom-input-code"
                                    id="fourth-input"
                                />
                            </Col>
                            {errorCode &&
                                <Col
                                    size={12}
                                    className="text-center"
                                >
                                <Typography
                                    size="s"
                                    weight="semibold"
                                    className="text-danger"
                                >
                                    <i className="fa-solid fa-circle-exclamation"></i> Necessário preencher o campo acima
                                </Typography>
                            </Col>  
                            }                                              
                        </Row>
                    </Col>
                    <Col
                        size={12}
                        className="text-center"
                    >
                        <SendButtonCode 
                            isSendingCode={loadingResend}
                            isLoading={loadingResend}
                            typeSend={typeMfa}
                            handleSendMfa={sendCode}
                            timer={timer}
                            textSend="solicite o reenvio"
                            isModal={true}                    
                        />
                    </Col>                    
                    <Col
                        md={9}
                        className="pt-2 pb-2"
                    >
                        <Button
                            type="submit"
                            fullWidth
                            className="button-quiet-orange rounded"
                            hierarchy="quiet"
                            srAnnouncement="Enviar código de validação"
                            loading={loadingButton}
                            disabled={loadingButton}
                            onClick={() => handleValidateCode()}
                        >
                            Validar
                        </Button>                     
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
});