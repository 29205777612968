import { atom } from "jotai";

type PlaceStateProps = {
  id: number;
  address: string;
  name: string;
  logo: string;
  agencia: boolean;
};

export type OrderListStateProps = {
  totalOrders: number;
  totalSelectedOrders: number;
  idOrdersSelected: number[];
  isHighLight: boolean;
  places: PlaceStateProps[];
  totalValue: number;
  isMobile: boolean;
};

export const orderListStateAtom = atom<OrderListStateProps>({
  totalOrders: 0,
  totalSelectedOrders: 0,
  idOrdersSelected: [],
  isHighLight: false,
  places: [],
  totalValue: 0,
  isMobile: false,
});
