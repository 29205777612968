import { atom } from "jotai";
import type { ShippingTypeEnum } from "../containers/onboarding/shippingType/enum";
import type { PersonTypeEnum } from "../containers/onboarding/personType/enum";

export interface PersonDataProps {
  cnpj?: string;
  razaoSocial?: string;
  cpf?: string;
  dataNascimento?: string;
}

export interface OnboardingStateProps {
  stepIndex?: number;
  displayFooter?: boolean;
  shippingType?: ShippingTypeEnum;
  personType?: PersonTypeEnum;
  personData?: PersonDataProps;
}

export const onboardingStateAtom = atom<OnboardingStateProps>({
  stepIndex: undefined,
  displayFooter: true,
  shippingType: undefined,
  personType: undefined,
  personData: {},
});
