import Service from "../Service";

import type {
  GetInformationSeller,
  UpgradeNivel,
  ResponseUpgradeNivel,
} from "./types";

export default class UpgradeNivelService {
  static async getInformationSeller(): Promise<GetInformationSeller> {
    const service = new Service();
    const response = (await service.doGet(
      "/ca/nivel-solicitacao/get-information-seller"
    )) as unknown;

    return response as Promise<GetInformationSeller>;
  }

  static async upgradeNivel(data: UpgradeNivel): Promise<ResponseUpgradeNivel> {
    const service = new Service();
    const response = (await service.doPost(
      "/ca/nivel-solicitacao/solicitar-mudanca-nivel",
      data
    )) as unknown;

    return response as Promise<ResponseUpgradeNivel>;
  }
}
