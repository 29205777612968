import { HeadersApi, OptionsApi } from "./types";

export default class Middlewares {
    private getToken(): string {
        return process.env.REACT_APP_JWT || "";
    }

    private getHeaders(): HeadersApi {
        return {
            "Authorization": `Bearer ${this.getToken()}`
        }
    }

    public getMiddlewares(data?: {}): OptionsApi {
        return {
            headers: this.getHeaders(),
            data
        };
    }
}