import type { FC } from "react";

import Layout from "../../../components/layout";

import StepsAuthenticate from "../../../containers/loginMeli/login/stepsAuthenticate";

import "./styles.scss";

const Index: FC = () => {
  return (
    <Layout pageTitle="Login" className="page-login-meli">
      <StepsAuthenticate />
    </Layout>
  );
};

export default Index;
