import { type FC, memo } from "react";

const ImageAdvantage: FC = () => {
  return (
    <svg
      width="292"
      height="203"
      viewBox="0 0 292 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_145_454)">
        <path
          d="M58.1791 203C90.3105 203 116.358 201.023 116.358 198.585C116.358 196.146 90.3105 194.17 58.1791 194.17C26.0477 194.17 0 196.146 0 198.585C0 201.023 26.0477 203 58.1791 203Z"
          fill="#E6E6E6"
        />
        <path
          d="M44.4709 45.042C54.5959 40.7389 67.2066 43.4653 79.5223 45.3576C79.6369 43.0661 81.0261 40.2772 79.5709 38.6785C77.803 36.7363 77.9658 34.6956 79.0296 32.6431C81.75 27.3945 77.8525 21.8097 73.9171 17.1661C73.0301 16.1218 71.9091 15.3013 70.6452 14.7712C69.3812 14.2411 68.01 14.0164 66.6428 14.1153L59.3529 14.6356C57.579 14.7622 55.8861 15.4271 54.5007 16.5413C53.1153 17.6556 52.1035 19.1661 51.6007 20.8706C49.8601 23.2361 48.9284 25.5951 49.515 27.9417C46.9077 29.7221 46.4697 31.8712 47.6413 34.2939C48.6347 35.8193 48.6242 37.3041 47.5961 38.7479C46.2459 40.5886 45.2118 42.641 44.5361 44.821L44.4709 45.042Z"
          fill="#2F2E41"
        />
        <path
          d="M285.081 175.49H78.7225C76.8874 175.49 75.1275 174.762 73.8298 173.465C72.5322 172.169 71.8032 170.41 71.8032 168.576C146.608 159.89 220.007 159.89 292 168.576C292 170.41 291.271 172.169 289.973 173.465C288.676 174.762 286.916 175.49 285.081 175.49Z"
          fill="#2F2E41"
        />
        <path
          d="M292 168.983L71.8032 168.576L97.3233 125.67L97.4454 125.466V30.905C97.4453 29.7572 97.6714 28.6207 98.111 27.5602C98.5505 26.4998 99.1948 25.5363 100.007 24.7247C100.819 23.9131 101.784 23.2693 102.845 22.8301C103.906 22.3909 105.043 22.1649 106.192 22.165H256.39C257.539 22.1649 258.676 22.3909 259.737 22.8301C260.799 23.2693 261.763 23.9131 262.575 24.7247C263.387 25.5363 264.032 26.4998 264.471 27.5602C264.911 28.6207 265.137 29.7572 265.137 30.905V126.117L292 168.983Z"
          fill="#3F3D56"
        />
        <path
          d="M105.993 28.2656C105.237 28.2665 104.513 28.5667 103.979 29.1004C103.445 29.6341 103.145 30.3577 103.144 31.1125V117.333C103.145 118.087 103.445 118.811 103.979 119.345C104.513 119.878 105.237 120.179 105.993 120.179H257.811C258.566 120.178 259.29 119.878 259.824 119.345C260.358 118.811 260.659 118.087 260.66 117.333V31.1125C260.659 30.3577 260.358 29.6341 259.824 29.1004C259.29 28.5667 258.566 28.2665 257.811 28.2656H105.993Z"
          fill="white"
        />
        <path
          d="M106.629 131.567C106.396 131.567 106.167 131.634 105.971 131.76C105.774 131.886 105.618 132.065 105.52 132.277L97.6561 149.358C97.5705 149.544 97.5331 149.749 97.5472 149.953C97.5614 150.157 97.6267 150.354 97.7372 150.527C97.8477 150.699 97.9997 150.841 98.1794 150.939C98.359 151.037 98.5605 151.088 98.7653 151.088H264.131C264.339 151.088 264.544 151.035 264.725 150.934C264.907 150.833 265.06 150.687 265.169 150.51C265.279 150.333 265.341 150.131 265.35 149.923C265.36 149.715 265.316 149.509 265.223 149.323L256.675 132.241C256.574 132.038 256.419 131.867 256.226 131.748C256.033 131.629 255.81 131.566 255.583 131.567H106.629Z"
          fill="#2F2E41"
        />
        <path
          d="M181.291 26.8422C182.303 26.8422 183.123 26.0228 183.123 25.012C183.123 24.0013 182.303 23.1819 181.291 23.1819C180.279 23.1819 179.459 24.0013 179.459 25.012C179.459 26.0228 180.279 26.8422 181.291 26.8422Z"
          fill="white"
        />
        <path
          d="M165.037 154.342C164.769 154.342 164.508 154.431 164.295 154.594C164.082 154.757 163.928 154.986 163.858 155.245L161.886 162.565C161.837 162.746 161.83 162.936 161.867 163.12C161.903 163.303 161.981 163.476 162.095 163.625C162.209 163.773 162.356 163.894 162.524 163.977C162.692 164.06 162.877 164.103 163.065 164.103H200.577C200.772 164.103 200.963 164.056 201.136 163.967C201.309 163.878 201.459 163.749 201.571 163.59C201.684 163.432 201.758 163.249 201.785 163.056C201.813 162.864 201.794 162.667 201.73 162.484L199.194 155.163C199.111 154.923 198.955 154.715 198.749 154.568C198.542 154.421 198.294 154.342 198.041 154.342H165.037Z"
          fill="#2F2E41"
        />
        <path
          d="M265.137 124.043V125.67H97.3232L97.4495 125.466V124.043H265.137Z"
          fill="#2F2E41"
        />
        <path
          d="M260.456 56.9377C276.191 56.9377 288.947 44.1918 288.947 28.4689C288.947 12.7459 276.191 0 260.456 0C244.721 0 231.965 12.7459 231.965 28.4689C231.965 44.1918 244.721 56.9377 260.456 56.9377Z"
          fill="#ED7234"
        />
        <path
          d="M272.26 40.8733H248.653C248.221 40.8729 247.807 40.7013 247.502 40.3963C247.197 40.0913 247.025 39.6778 247.025 39.2465V25.012C247.025 24.5807 247.197 24.1672 247.502 23.8622C247.807 23.5573 248.221 23.3857 248.653 23.3853H272.26C272.691 23.3857 273.105 23.5573 273.41 23.8622C273.716 24.1672 273.887 24.5807 273.888 25.012V39.2465C273.887 39.6778 273.716 40.0913 273.41 40.3963C273.105 40.7013 272.691 40.8729 272.26 40.8733ZM248.653 25.0121V39.2465H272.261L272.26 25.012L248.653 25.0121Z"
          fill="white"
        />
        <path
          d="M267.782 25.012H253.13V18.5048C253.13 13.7916 256.211 10.3708 260.456 10.3708C264.701 10.3708 267.782 13.7916 267.782 18.5048L267.782 25.012ZM254.758 23.3852H266.154V18.5048C266.154 14.6735 263.811 11.9976 260.456 11.9976C257.101 11.9976 254.758 14.6736 254.758 18.5048L254.758 23.3852Z"
          fill="white"
        />
        <path
          d="M260.456 33.3493C261.355 33.3493 262.084 32.6209 262.084 31.7225C262.084 30.824 261.355 30.0957 260.456 30.0957C259.557 30.0957 258.828 30.824 258.828 31.7225C258.828 32.6209 259.557 33.3493 260.456 33.3493Z"
          fill="white"
        />
        <path
          d="M240.567 90.9344H123.236C122.66 90.9337 122.108 90.7048 121.701 90.2978C121.294 89.8907 121.064 89.3389 121.064 88.7633V59.6816C121.064 59.106 121.294 58.5541 121.701 58.1471C122.108 57.7401 122.66 57.5112 123.236 57.5105H240.567C241.143 57.5112 241.695 57.7401 242.102 58.1471C242.51 58.5541 242.739 59.106 242.739 59.6816V88.7633C242.739 89.3389 242.51 89.8907 242.102 90.2978C241.695 90.7048 241.143 90.9337 240.567 90.9344ZM123.236 58.3789C122.891 58.3793 122.559 58.5167 122.315 58.7609C122.071 59.0051 121.933 59.3362 121.933 59.6816V88.7633C121.933 89.1087 122.071 89.4398 122.315 89.684C122.559 89.9282 122.891 90.0656 123.236 90.066H240.567C240.912 90.0656 241.244 89.9282 241.488 89.684C241.733 89.4398 241.87 89.1087 241.87 88.7633V59.6816C241.87 59.3362 241.733 59.0051 241.488 58.7609C241.244 58.5167 240.912 58.3793 240.567 58.3789L123.236 58.3789Z"
          fill="#E6E6E6"
        />
        <path
          d="M141.467 81.9503C146.355 81.9503 150.318 77.9908 150.318 73.1065C150.318 68.2222 146.355 64.2627 141.467 64.2627C136.579 64.2627 132.616 68.2222 132.616 73.1065C132.616 77.9908 136.579 81.9503 141.467 81.9503Z"
          fill="#E6E6E6"
        />
        <path
          d="M161.908 67.2107C161.714 67.2104 161.522 67.2483 161.343 67.3222C161.164 67.3962 161.001 67.5047 160.864 67.6416C160.727 67.7785 160.618 67.9411 160.543 68.1201C160.469 68.299 160.431 68.4909 160.431 68.6847C160.431 68.8784 160.469 69.0703 160.543 69.2493C160.618 69.4282 160.727 69.5908 160.864 69.7277C161.001 69.8646 161.164 69.9732 161.343 70.0471C161.522 70.121 161.714 70.1589 161.908 70.1586H231.45C231.841 70.1586 232.216 70.0033 232.493 69.7269C232.77 69.4505 232.925 69.0756 232.925 68.6847C232.925 68.2937 232.77 67.9188 232.493 67.6424C232.216 67.366 231.841 67.2107 231.45 67.2107H161.908Z"
          fill="#E6E6E6"
        />
        <path
          d="M161.908 76.0544C161.517 76.0544 161.142 76.2097 160.865 76.4862C160.589 76.7626 160.433 77.1375 160.433 77.5284C160.433 77.9193 160.589 78.2942 160.865 78.5707C161.142 78.8471 161.517 79.0024 161.908 79.0024H191.832C192.223 79.0024 192.599 78.8471 192.875 78.5707C193.152 78.2942 193.307 77.9193 193.307 77.5284C193.307 77.1375 193.152 76.7626 192.875 76.4862C192.599 76.2097 192.223 76.0544 191.832 76.0544H161.908Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.0345 76.6506L75.3451 88.1727L75.0706 78.571C82.1498 77.3901 88.885 75.3341 95.1127 71.987L97.3811 66.387C97.6283 65.7768 98.03 65.2413 98.5469 64.8331C99.0637 64.4249 99.6779 64.158 100.329 64.0586C100.98 63.9593 101.646 64.0308 102.262 64.2663C102.877 64.5018 103.42 64.893 103.838 65.4017C104.45 66.146 104.756 67.0948 104.694 68.0559C104.632 69.0171 104.206 69.9187 103.504 70.5781L97.0345 76.6506Z"
          fill="#FFB8B8"
        />
        <path
          d="M44.0386 143.175C43.8804 142.595 43.8468 141.988 43.9401 141.394C44.0333 140.8 44.2513 140.233 44.5797 139.73L49.3442 132.423C50.4711 130.695 52.2003 129.447 54.1962 128.921C56.1922 128.395 58.3126 128.628 60.146 129.576C58.1436 132.982 58.4227 135.969 60.8375 138.566C57.0889 140.394 53.6242 142.754 50.5509 145.573C49.9898 145.955 49.3455 146.198 48.6715 146.281C47.9976 146.364 47.3136 146.285 46.6765 146.05C46.0393 145.815 45.4675 145.432 45.0087 144.932C44.5499 144.432 44.2173 143.829 44.0386 143.175Z"
          fill="#2F2E41"
        />
        <path
          d="M98.0224 133.621C97.3015 134.822 96.2837 135.817 95.0669 136.512C93.8501 137.206 92.4751 137.577 91.0738 137.587L59.4213 137.827L58.0486 129.871L72.0506 125.756L60.245 116.703L73.1488 101.615L96.5861 123.442C97.9559 124.718 98.846 126.425 99.1074 128.278C99.3689 130.131 98.9859 132.017 98.0224 133.621Z"
          fill="#2F2E41"
        />
        <path
          d="M54.4794 188.03H47.0665C40.4109 142.444 33.6361 96.7308 53.6557 79.6685L77.267 83.5091L74.2469 103.535L61.0686 118.624L54.4794 188.03Z"
          fill="#2F2E41"
        />
        <path
          d="M60.9957 198.357C60.4154 198.515 59.8082 198.549 59.2139 198.455C58.6197 198.362 58.0519 198.145 57.548 197.816L49.4992 196.735C47.7696 195.609 46.5203 193.882 45.9936 191.887C45.4669 189.893 45.7004 187.774 46.6488 185.942C50.0573 187.943 53.0474 187.664 55.6457 185.251C57.4817 188.955 60.5066 188.729 63.3955 191.85C63.7781 192.41 64.021 193.054 64.1041 193.727C64.1872 194.401 64.108 195.084 63.8732 195.721C63.6384 196.358 63.2548 196.929 62.7543 197.387C62.2537 197.846 61.6508 198.178 60.9957 198.357Z"
          fill="#2F2E41"
        />
        <path
          d="M72.0506 49.2174L58.3231 45.9254C60.603 41.2622 60.7908 36.0735 59.6959 30.5627L69.0305 30.2883C69.323 37.1412 70.2079 43.5436 72.0506 49.2174Z"
          fill="#FFB8B8"
        />
        <path
          d="M75.8234 91.7692C65.8303 98.5734 58.7695 92.002 53.3813 81.8632C54.13 75.6591 52.9177 68.2347 50.6723 60.1675C49.6738 56.6093 50.0466 52.8063 51.7172 49.5094C53.3877 46.2124 56.2347 43.6611 59.6959 42.3591L71.5015 47.2972C81.5258 55.4617 83.4989 64.3056 79.738 73.6332L75.8234 91.7692Z"
          fill="#ED7234"
        />
        <path
          d="M47.0666 56.0758L36.0846 61.8368L55.5776 73.3588L58.2899 80.044C58.4709 80.4902 58.5591 80.9685 58.5491 81.4499C58.5391 81.9313 58.431 82.4055 58.2316 82.8438C58.0321 83.2821 57.7454 83.6752 57.3889 83.9992C57.0325 84.3231 56.6137 84.5711 56.1582 84.7281C55.6404 84.9065 55.0882 84.9627 54.5451 84.8923C54.0019 84.8219 53.4824 84.6269 53.0272 84.3224C52.5721 84.0179 52.1937 83.6122 51.9216 83.1372C51.6496 82.6622 51.4913 82.1307 51.4591 81.5843L51.1848 76.9251L26.4094 68.7557C25.5747 68.4805 24.8136 68.019 24.1837 67.4063C23.5539 66.7936 23.0718 66.0457 22.7741 65.2193C22.3401 64.0147 22.318 62.7003 22.7113 61.4818C23.1046 60.2633 23.891 59.2095 24.9476 58.4852L44.8702 44.8281L47.0666 56.0758Z"
          fill="#FFB8B8"
        />
        <path
          d="M58.0486 57.1731C53.4684 55.1675 49.3035 57.3462 44.0465 59.0935L43.2229 44.5538C48.4429 41.7738 53.4197 41.0243 58.0486 43.1821L58.0486 57.1731Z"
          fill="#ED7234"
        />
        <path
          d="M63.5275 37.4902C68.368 37.4902 72.292 33.5693 72.292 28.7327C72.292 23.896 68.368 19.9751 63.5275 19.9751C58.687 19.9751 54.7631 23.896 54.7631 28.7327C54.7631 33.5693 58.687 37.4902 63.5275 37.4902Z"
          fill="#FFB8B8"
        />
        <path
          d="M74.0126 27.6278C65.335 28.477 58.7228 27.0589 54.4968 23.0212V19.7695H73.2761L74.0126 27.6278Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_145_454">
          <rect width="292" height="203" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(ImageAdvantage);
