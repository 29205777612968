import OnboardingService from "../../services/onboarding";

import type {
  OnboardingProgressProps,
  OnboardingResponseProps,
} from "../../services/onboarding/types";

export const getOnboardingAvailability =
  async (): Promise<OnboardingResponseProps> => {
    const response = await OnboardingService.getOnboardingAvailability();

    return response;
  };

export const getOnboardingProgress =
  async (): Promise<OnboardingProgressProps> => {
    const response = await OnboardingService.getProgress();

    return response;
  };

export const saveOnboardingProgress = async (
  data: OnboardingProgressProps
): Promise<OnboardingResponseProps> => {
  const response = await OnboardingService.saveProgress(data);

  return response;
};

export const verifyOnboardingData = async (
  data: OnboardingProgressProps
): Promise<OnboardingResponseProps> => {
  const response = await OnboardingService.verifyData(data);

  return response;
};

export const saveOnboardingData = async (
  data: OnboardingProgressProps
): Promise<OnboardingResponseProps> => {
  const response = await OnboardingService.saveData(data);

  return response;
};
