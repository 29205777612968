import { useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import { Title, Text } from "@andes/typography";
import { Flex } from "../../../components/grid";

import { Button } from "@andes/button";
import { useWizard } from "react-use-wizard";

import { CardAsRadio, CardAsRadioItem } from "../../../components/cardAsRadio";
import { PersonTypeEnum } from "./enum";

import {
  onboardingStateAtom,
  type OnboardingStateProps,
} from "../../../states/onboarding";
import { useAtom } from "jotai";
import { OnboardingProgressProps } from "../../../services/onboarding/types";
import { saveOnboardingProgress } from "../../../pages/onboarding/controller";

const PersonType: FC = () => {
  const { i18n } = useI18n();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { previousStep, nextStep } = useWizard();

  const [onboardingState, setOnboardingState] =
    useAtom<OnboardingStateProps>(onboardingStateAtom);

  const handleOnboardingProgress = async (stepIndex: number) => {
    try {
      setButtonLoading(true);

      const onboardingProgress: OnboardingProgressProps = {
        data: {
          step: stepIndex,
          st_declaracao:
            onboardingState.personType === PersonTypeEnum.CNPJ ? "N" : "S",
          tp_perfil: onboardingState.personType,
        },
      };

      await saveOnboardingProgress(onboardingProgress);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSelectedOption = (option: PersonTypeEnum) => {
    setOnboardingState((prevState) => ({
      ...prevState,
      stepIndex: 2,
      personType: option,
    }));
  };

  const handlePreviousStep = async () => {
    await handleOnboardingProgress(0);

    previousStep();
  };

  const handleNextStep = async () => {
    await handleOnboardingProgress(2);

    nextStep();
  };

  return (
    <Flex gap={4} direction="column" className="pt-4 personType">
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext(
          "Agora, selecione com qual categoria você deseja continuar"
        )}
      </Title>

      <CardAsRadio>
      <CardAsRadioItem
          shadowType="elevated"
          title={
            <Flex
              gap={3}
              direction="column"
              align="start"
              className="personType-cardAsRadioItem-title"
            >
              <img
                src="/public/images/kangu/onboarding-person-type-pf.svg"
                alt="Pessoa Física"
                height={38}
              />
              <Text size="m" weight="semibold">
                {i18n.gettext("Pessoa Física")}
              </Text>
            </Flex>
          }
          content={
            <Text size="s">
              {i18n.gettext(
                "Ao usar seu CPF, você pode fazer envios pessoais com Declaração de Conteúdo e Nota Fiscal"
              )}
            </Text>
          }
          checked={onboardingState.personType === PersonTypeEnum.CPF}
          onClick={() => handleSelectedOption(PersonTypeEnum.CPF)}
        />

        <CardAsRadioItem
          shadowType="elevated"
          title={
            <Flex
              gap={3}
              direction="column"
              align="start"
              className="personType-cardAsRadioItem-title"
            >
              <img
                src="/public/images/kangu/onboarding-person-type-mei.svg"
                alt="Microempreendedor Individual (MEI)"
                height={38}
              />

              <Text size="m" weight="semibold">
                {i18n.gettext("Microempreendedor Individual (MEI)")}
              </Text>
            </Flex>
          }
          content={
            <Text size="s">
              {i18n.gettext(
                "Ao usar seu CNPJ do MEI, você pode fazer envios com Declaração de Conteúdo e Nota Fiscal"
              )}
            </Text>
          }
          checked={onboardingState.personType === PersonTypeEnum.MEI}
          onClick={() => handleSelectedOption(PersonTypeEnum.MEI)}
        />
      </CardAsRadio>

      <Flex gap={3} align="center">
        <Button
          size="large"
          hierarchy="transparent"
          className="ms-auto"
          onClick={() => handlePreviousStep()}
        >
          {i18n.gettext("Voltar")}
        </Button>

        <Button
          loading={buttonLoading}
          srAnnouncement={i18n.gettext("Carregando...")}
          disabled={!onboardingState.personType}
          size="large"
          onClick={() => handleNextStep()}
        >
          {i18n.gettext("Selecionar")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default PersonType;
