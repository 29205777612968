/* eslint-disable react-hooks/exhaustive-deps */
import { useI18n } from "nordic/i18n";

import { useSetAtom } from "jotai";
import { Button } from "@andes/button";
import { Title, Text } from "@andes/typography";
import Card, { CardContent } from "@andes/card";

import { onboardingStateAtom } from "../../../states/onboarding";
import { Flex } from "../../../components/grid";
import { useEffect } from "react";

import checkIcon from "../../../assets/images/authorized_connection.svg";

export const AddressCompleted = () => {
  const { i18n } = useI18n();
  const setOnboardingState = useSetAtom(onboardingStateAtom);

  useEffect(() => {
    setOnboardingState((prevState) => ({
      ...prevState,
      displayFooter: false,
    }));
  }, []);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      className="mt-6 address-completed-container"
    >
      <Card shadow="elevated" className="address-completed">
        <CardContent>
          <Flex gap={4} direction="column" align="center">
            <img
              alt="avatar"
              src={checkIcon}
              className="check-icon-onboarding"
            />
            <Title component="h3" size="l">
              {i18n.gettext("Dados inseridos com sucesso!")}
            </Title>
            <Text component="span" size="l" className="text-center">
              {i18n.gettext(
                "A partir de agora, você já pode usar a Kangu para fazer envios e contar com a eficiência logística do Mercado Livre."
              )}
            </Text>
            <Button
              href="/onboarding"
              size="large"
              type="button"
              className="custom-address-button"
            >
              {i18n.gettext("Começar a enviar")}
            </Button>
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  );
};
