export const DEFAULT_BASE_URL = "https://dev.kangu.com.br";

export const PATHS = {
    development: "dev.kangu.com.br",
    internal: "internal.kangu.com.br",
    localhost: "localhost",
    production: "portal.kangu.com.br",
    stage: "preportal.kangu.com.br",
    test: "dev.kangu.com.br",
};

export const ENVS = {
    development: "development",
    internal: "internal",
    localhost: "localhost",
    production: "production",
    stage: "stage",
    test: "test",
};

export const PATHS_ENVS = {
    [PATHS.development]: ENVS.development,
    [PATHS.internal]: ENVS.internal,
    [PATHS.localhost]: ENVS.localhost,
    [PATHS.production]: ENVS.production,
    [PATHS.stage]: ENVS.stage,
    [PATHS.test]: ENVS.test,
};

export const PROCESS_BASE_URL_ENVS = {
    [ENVS.development]: process.env.REACT_APP_PATH_DEVELOPMENT_SERVICES,
    [ENVS.internal]: process.env.REACT_APP_PATH_INTERNAL_SERVICES,
    [ENVS.localhost]: process.env.REACT_APP_PATH_LOCALHOST_SERVICES,
    [ENVS.production]: process.env.REACT_APP_PATH_PRODUCTION_SERVICES,
    [ENVS.stage]: process.env.REACT_APP_PATH_STAGE_SERVICES,
    [ENVS.test]: process.env.REACT_APP_PATH_DEVELOPMENT_SERVICES,
};