import Service from "../Service";

import type { CheckBondMeliRequest, CheckBondMeliResponse } from "./types";

export default class LoginService {
  static async checkBondMeli(
    data: CheckBondMeliRequest
  ): Promise<CheckBondMeliResponse> {
    return (await new Service().doPost(
      "/mfa/check-bond",
      data
    )) as unknown as Promise<CheckBondMeliResponse>;
  }
}
