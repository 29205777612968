import CompareDataService from "../../../services/loginMeli/compareData";

import type {
  GetDataProps,
  PostDataProps,
} from "../../../services/loginMeli/compareData/types";

export const retrieveUserMatchData = async (): Promise<GetDataProps> => {
  const response = await CompareDataService.retrieveMatch();

  return response;
};

export const saveUserMatchData = async (): Promise<PostDataProps> => {
  const response = await CompareDataService.saveMatch();

  return response;
};
