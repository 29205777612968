import React from 'react';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

type LoadingProps = {
  id: string;
  ariaLabel: string;
  show: boolean;
};

export const Loading = ({ id, ariaLabel, show }: LoadingProps) => {
  return show ? <ProgressIndicatorCircular id={id} srLabel={ariaLabel} /> : null;
};

export default Loading;
