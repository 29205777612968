import Service from "../Service";

import type {
  DataProfileChange,
  ProfileChange,
  DataListCredentials,
  ResponseSaveProfile,
  DataChangePassword,
  ResponseChangePassword,
  ResponseRevokeCredential,
  DataCreateCredential,
  ResponseCreateCredential,
  DataRevokeToken,
  DataInfoAccount,
  DataFlagMfa,
  DataCheckMfa,
  PropsSendDataCode,
  DataSendCodeMfa,
  PropsValidateMfa,
  DataValidateMfa,
  PropsDisableMfa,
  DataDisableMfa,
} from "../../containers/myAccess/types";

export default class MyAccessService {
  static async getProfile(): Promise<DataProfileChange> {
    const service = new Service();
    const response = (await service.doGet("/cliente/perfil/me")) as unknown;

    return response as Promise<DataProfileChange>;
  }

  static async saveProfile(data: ProfileChange): Promise<ResponseSaveProfile> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/perfil/save",
      data
    )) as unknown;

    return response as Promise<ResponseSaveProfile>;
  }

  static async listCredentials(): Promise<DataListCredentials> {
    const service = new Service();
    const response = (await service.doGet(
      "/cliente/perfil/list-credentials"
    )) as unknown;

    return response as Promise<DataListCredentials>;
  }

  static async changePassword(
    data: DataChangePassword
  ): Promise<ResponseChangePassword> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/perfil/change-password",
      data
    )) as unknown;

    return response as Promise<ResponseChangePassword>;
  }

  static async revokeToken(
    data: DataRevokeToken
  ): Promise<ResponseRevokeCredential> {
    const service = new Service();
    const response = (await service.doPost(
      `/cliente/auth/revoke-token`,
      data
    )) as unknown;

    return response as Promise<ResponseRevokeCredential>;
  }

  static async generateToken(
    data: DataCreateCredential
  ): Promise<ResponseCreateCredential> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/auth/generate-token",
      data
    )) as unknown;

    return response as Promise<ResponseCreateCredential>;
  }

  static async getInfoAccount(): Promise<DataInfoAccount> {
    const service = new Service();
    const response = (await service.doGet(
      "/cliente/perfil/get-info-account"
    )) as unknown;

    return response as Promise<DataInfoAccount>;
  }

  static async getFlagMfa(): Promise<DataFlagMfa> {
    const service = new Service();
    const response = (await service.doGet(
      "/cliente/auth/get-flag-mfa"
    )) as unknown;

    return response as Promise<DataFlagMfa>;
  }

  static async checkMfa(): Promise<DataCheckMfa> {
    const service = new Service();
    const response = (await service.doGet(
      "/cliente/auth/check-mfa"
    )) as unknown;

    return response as Promise<DataCheckMfa>;
  }

  static async sendCodeMfa(data: PropsSendDataCode): Promise<DataSendCodeMfa> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/auth/envia-codigo-mfa",
      data
    )) as unknown;

    return response as Promise<DataSendCodeMfa>;
  }

  static async validateMfa(data: PropsValidateMfa): Promise<DataValidateMfa> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/auth/validate-mfa",
      data
    )) as unknown;

    return response as Promise<DataValidateMfa>;
  }

  static async disableMfa(data: PropsDisableMfa): Promise<DataDisableMfa> {
    const service = new Service();
    const response = (await service.doPost(
      "/cliente/auth/desativar-mfa",
      data
    )) as unknown;

    return response as Promise<DataDisableMfa>;
  }
}
