import type { FC } from "react";

import { Card, CardContent } from "@andes/card";

import { useI18n } from "nordic/i18n";

import { Col, Flex, Row } from "../../components/grid";

import { Button } from "@andes/button";
import { Text } from "@andes/typography";
import Info16 from "@andes/icons/Info16";
import Tooltip from "@andes/tooltip";

interface CardLoginMeliProps {
  readonly setRedirectMeli: (redirect: boolean) => void;
}

const CardLoginMeli: FC<CardLoginMeliProps> = ({ setRedirectMeli }) => {
  const { i18n } = useI18n();

  return (
    <Card className="mb-4 p-2 shadow-sm">
      <CardContent>
        <Row align="center">
          <Col className="mt-4" size={12}>
            <Flex direction="column">
              <Col size={12}>
                <Row>
                  <Col>
                    <Text>
                      {i18n.gettext(
                        "Se você já vinculou suas contas Kangu e Mercado Livre, entre no portal com sua conta Mercado Livre."
                      )}
                    </Text>
                  </Col>
                  <Col auto className="custom-tooltip">
                    <Tooltip
                      id="tooltip-id"
                      title="Novo acesso para vendedores"
                      content={i18n.gettext(
                        "Vendedores que enviam pacotes agora acessam o portal Kangu pela sua conta Mercado Livre."
                      )}
                      closable
                    >
                      <Info16 />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              <Button
                hierarchy="quiet"
                className="rounded"
                onClick={() => setRedirectMeli(true)}
              >
                {i18n.gettext("Entrar com Mercado Livre")}
              </Button>
            </Flex>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default CardLoginMeli;
