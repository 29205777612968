import { type FC, memo, useState } from "react";

import { useAtomValue } from "jotai";

import { Row } from "../../../components/grid";

import type { ResponseLogin } from "./types";
import { type StepsProps, stepsStateAtom } from "../../../states/steps";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";

const StepsAuthenticate: FC = () => {
  const [currentUser, setCurrentUser] = useState<string>("");
  const [responseLogin, setResponseLogin] = useState<ResponseLogin | undefined>(
    undefined
  );

  const step = useAtomValue<StepsProps>(stepsStateAtom);

  return (
    <Row justify="center" className="mt-md-5">
      {step.current === 1 && (
        <FirstStep
          setCurrentUser={setCurrentUser}
          setResponseLogin={setResponseLogin}
        />
      )}
      {step.current === 2 && (
        <SecondStep currentUser={currentUser} responseLogin={responseLogin} />
      )}
      {step.current === 3 && <ThirdStep currentUser={currentUser} />}
    </Row>
  );
};

export default memo(StepsAuthenticate);
