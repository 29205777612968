import Service from "../../Service";

import { GetDataProps, PostDataProps } from "./types";

export default class CompareDataService {
  static async retrieveMatch(): Promise<GetDataProps> {
    const service = new Service();
    const { data } = (await service.doGet("/auth/mercadolivre/match-account-retrive-user")) as any;
    return data as Promise<GetDataProps>;
  }

  static async saveMatch(): Promise<PostDataProps> {
    const service = new Service();

    const response = (await service.doGet(
      "/auth/mercadolivre/save-match-conta"
    )) as unknown;

    return response as Promise<PostDataProps>;
  }
}
