import React, {
  type FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import TextField from "@andes/textfield";
import { Col } from "../grid";
import "./styles.scss";

const INPUTS = ["first", "second", "third", "fourth"] as const;
const SR_LABEL = "Código 2FA";
const CUSTOM_INPUT_CLASS = "custom-input-code";
const INPUT_ERROR_CLASS = "input-error";

type InputKey = (typeof INPUTS)[number];
interface CodeFields {
  firstInput: string;
  secondInput: string;
  thirdInput: string;
  fourthInput: string;
}
interface CodeFieldProps {
  readonly label: string;
  readonly setValueCode: (code: string) => void;
  readonly setMessageError?: (message: string) => void;
  readonly messageError?: string;
}

const allowOnlyPatternsChars = (value: string) =>
  value.replace(/[^a-zA-Z0-9]/g, "");

export const useCodeInput = (
  setValueCode: (code: string) => void,
  setMessageError?: (message: string) => void
) => {
  const [code, setCode] = useState<CodeFields>({
    firstInput: "",
    secondInput: "",
    thirdInput: "",
    fourthInput: "",
  });

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, input: InputKey) => {
      const value = allowOnlyPatternsChars(e.target.value.toUpperCase());

      setCode((prevCode) => ({ ...prevCode, [`${input}Input`]: value }));

      const currentIndex = INPUTS.indexOf(input);
      const nextInput = INPUTS[currentIndex + 1];
      const prevInput = INPUTS[currentIndex - 1];

      if (value && nextInput) {
        document.getElementById(`${nextInput}-input`)?.focus();
      } else if (!value && prevInput) {
        document.getElementById(`${prevInput}-input`)?.focus();
      }
    },
    []
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData("text");
      const values = pastedText
        .slice(0, 4)
        .split("")
        .map(allowOnlyPatternsChars);
      setCode({
        firstInput: values[0] || "",
        secondInput: values[1] || "",
        thirdInput: values[2] || "",
        fourthInput: values[3] || "",
      });
      document.getElementById("fourth-input")?.focus();
    },
    []
  );

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    input: InputKey
  ) => {
    const currentIndex = INPUTS.indexOf(input);
    const nextInput = INPUTS[currentIndex + 1];

    if (e.key === "Tab") {
      e.preventDefault();
      if (nextInput) {
        document.getElementById(`${nextInput}-input`)?.focus();
      }
    }
  };

  useEffect(() => {
    const fullCode = INPUTS.map((input) => code[`${input}Input`]).join("");

    setValueCode(fullCode);

    if (fullCode.length === 4) {
      setMessageError?.("");
    }
  }, [code, setValueCode, setMessageError]);
  return { code, handleChangeInput, handlePaste, handleKeyDown };
};
interface InputFieldProps {
  input: InputKey;
  index: number;
  code: CodeFields;
  label: string;
  messageError?: string;
  handleChangeInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    input: InputKey
  ) => void;
  handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    input: InputKey
  ) => void;
}
const InputField: React.FC<InputFieldProps> = ({
  input,
  index,
  code,
  label,
  messageError,
  handleChangeInput,
  handlePaste,
  inputRef,
  handleKeyDown,
}) => (
  <Col md="auto" size={2} className={index > 0 ? "pt-4" : ""}>
    <TextField
      srLabel={SR_LABEL}
      label={index === 0 ? label : ""}
      onChange={(e: any) => handleChangeInput(e, input)}
      onPaste={handlePaste}
      value={code[`${input}Input`]}
      className={`${CUSTOM_INPUT_CLASS} ${
        messageError && index > 0 ? INPUT_ERROR_CLASS : ""
      }`}
      id={`${input}-input`}
      modifier={messageError && index === 0 ? "error" : undefined}
      helper={messageError && index === 0 ? messageError : undefined}
      maxLength={1}
      ref={inputRef}
      autoFocus={index === 0}
      onInput={handleKeyDown}
    />
  </Col>
);

const MemoizedInputField = memo(InputField);

const CodeField: FC<CodeFieldProps> = ({
  label,
  setValueCode,
  setMessageError,
  messageError,
}) => {
  const { code, handleChangeInput, handlePaste, handleKeyDown } = useCodeInput(
    setValueCode,
    setMessageError
  );
  const inputRefs = useRef<Record<InputKey, React.RefObject<HTMLInputElement>>>(
    {
      first: React.createRef(),
      second: React.createRef(),
      third: React.createRef(),
      fourth: React.createRef(),
    }
  );
  return (
    <>
      {INPUTS.map((input, index) => (
        <MemoizedInputField
          key={input}
          input={input}
          index={index}
          code={code}
          label={label}
          messageError={messageError}
          handleChangeInput={handleChangeInput}
          handlePaste={handlePaste}
          inputRef={inputRefs.current[input]}
          handleKeyDown={handleKeyDown}
        />
      ))}
    </>
  );
};
export default CodeField;
