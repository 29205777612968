import DOMPurify from "dompurify";

export function sanitizeData<T>(data: T): T {
  if (typeof data === "string") {
    return DOMPurify.sanitize(data) as unknown as T;
  } else if (typeof data === "object" && data !== null) {
    const sanitizedObject: any = Array.isArray(data) ? [] : {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedObject[key] = sanitizeData((data as any)[key]);
      }
    }
    return sanitizedObject;
  }
  return data;
}
