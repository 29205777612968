import { useEffect } from "react";

import { useI18n } from "nordic/i18n";
import { Title, Text } from "@andes/typography";
import Card, { CardContent } from "@andes/card";

import { motion } from "framer-motion";

import { Col, Flex, Row } from "../../components/grid";

import "./styles.scss";

const PlannedShipment = () => {
  const { i18n } = useI18n();

  const fixPortalReactCSS = (toggle: boolean = false) => {
    const body = document.body;

    body?.classList.toggle("planned-shipment", toggle);
  };

  const togglePortalFooter = (toggle: boolean = false) => {
    const portalFooter = document.getElementById("layout-footer");

    portalFooter?.classList.toggle("d-none", toggle);
  };

  useEffect(() => {
    fixPortalReactCSS(true);
    togglePortalFooter(true);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="planned-shipment-container"
    >
      <div className="planned-shipment-top">
        <Row
          gap={3}
          className="planned-shipment-top-container justify-content-sm-between justify-content-center"
        >
          <Col size={12} sm="5">
            <Flex gap={3} direction="column" className="pt-4">
              <Title
                size="xl"
                weight="semibold"
                color="inverted"
                className="planned-shipment-top-title"
              >
                {i18n.gettext("Postagem planejada")}
              </Title>

              <Text
                size="s"
                color="inverted"
                className="planned-shipment-top-description"
              >
                {i18n.gettext(
                  "O dia ideal de postagem para seu pacote ser entregue no tempo estimado."
                )}
              </Text>
            </Flex>
          </Col>
          <Col auto>
            <img
              src="/public/layout/kangu/images/planned-shipment-bg-top-item1.svg"
              alt="Ilustração 1"
              className="planned-shipment-top-item1"
            />
          </Col>
        </Row>
      </div>

      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        transition={{ ease: "circInOut", duration: 0.8, delay: 0.5 }}
        className="planned-shipment-content"
      >
        <Flex gap={5} direction="column" align="center" justify="center">
          <Row gap={5} justify="center">
            <Col auto className="order-1 order-sm-0">
              <Flex gap={0} align="end">
                <div className="planned-shipment-content-boxes">
                  <div className="planned-shipment-content-boxes-wrapper" />
                </div>

                <img
                  src="/public/layout/kangu/images/planned-shipment-content-shop.svg"
                  alt="Ilustração de Loja"
                />
              </Flex>
            </Col>

            <Col size={12} sm className="order-0 order-sm-1">
              <Flex gap={3} direction="column">
                <Title size="s" weight="semibold">
                  {i18n.gettext("O que é a postagem planejada?")}
                </Title>

                <Text size="l">
                  {i18n.gettext("É uma medida para")}{" "}
                  <Text size="l" weight="semibold">
                    {i18n.gettext(
                      "evitar sobrecarga dos centros de distribuição"
                    )}
                  </Text>{" "}
                  {i18n.gettext("e problemas no processamento de pedidos. ")}
                  <br />
                  {i18n.gettext(
                    "Assim que você realizar uma tentativa de emissão de etiqueta, informaremos uma nova data para a postagem dos seus pacotes."
                  )}
                </Text>
              </Flex>
            </Col>
          </Row>

          <Row gap={4} align="stretch">
            <Col size={12} sm>
              <Card className="h-100">
                <CardContent>
                  <Flex gap={2} direction="column">
                    <Title size="xs" weight="semibold">
                      {i18n.gettext("Como funciona?")}
                    </Title>
                    <Text size="m">
                      {i18n.gettext(
                        "Ao solicitar a impressão da etiqueta do seu pedido, você será informado de uma "
                      )}
                      <Text size="m" weight="semibold">
                        {i18n.gettext("nova data para imprimi-la.")}
                      </Text>
                      <br />
                      {i18n.gettext(
                        "A partir desta data, será possível postar o seu pacote normalmente no ponto de coleta."
                      )}
                    </Text>
                  </Flex>
                </CardContent>
              </Card>
            </Col>

            <Col size={12} sm>
              <Card className="h-100">
                <CardContent>
                  <Flex gap={2} direction="column">
                    <Title size="xs" weight="semibold">
                      {i18n.gettext("Quais os benefícios?")}
                    </Title>
                    <Text size="m">
                      {i18n.gettext(
                        "Com a postagem planejada, não há sobrecarga dos centros de distribuição, o que otimiza o processamento dos seus pacotes e "
                      )}
                      <Text size="m" weight="semibold">
                        {i18n.gettext("agiliza a entrega.")}
                      </Text>
                    </Text>
                  </Flex>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Flex>
      </motion.div>

      <motion.div
        animate={{ y: ["-5svh", "5svh", 0] }}
        transition={{ ease: "circInOut", duration: 1.25, delay: 0.5 }}
        className="planned-shipment-bottom d-none"
      >
        <Row
          gap={4}
          justify="between"
          className="planned-shipment-bottom-container"
        >
          <Col size={12} sm>
            <Row
              gap={3}
              justify="center"
              className="planned-shipment-bottom-video1"
            >
              <Col size={12} lg="auto" className="order-lg-0 order-1">
                <Card className="planned-shipment-bottom-video-container mx-auto">
                  <CardContent>{''}</CardContent>
                </Card>
              </Col>

              <Col size={12} lg className="order-lg-1 order-0">
                <Flex
                  gap={3}
                  direction="column"
                  className="planned-shipment-bottom-video-description mx-auto"
                >
                  <Title size="s" weight="semibold" color="inverted">
                    {i18n.gettext("Portal Kangu")}
                  </Title>

                  <Text size="m" color="inverted">
                    {i18n.gettext(
                      "Veja passo a passo o que muda dentro do nosso portal."
                    )}
                  </Text>
                </Flex>
              </Col>
            </Row>
          </Col>

          <Col size={12} sm>
            <Row
              gap={3}
              justify="center"
              className="planned-shipment-bottom-video2"
            >
              <Col size={12} lg="auto" className="order-lg-0 order-1">
                <Card className="planned-shipment-bottom-video-container mx-auto">
                  <CardContent>{''}</CardContent>
                </Card>
              </Col>

              <Col size={12} lg className="order-lg-1 order-0">
                <Flex
                  gap={3}
                  direction="column"
                  className="planned-shipment-bottom-video-description mx-auto"
                >
                  <Title size="s" weight="semibold" color="inverted">
                    {i18n.gettext("Plataforma")}
                  </Title>

                  <Text size="m" color="inverted">
                    {i18n.gettext(
                      "Entenda como afeta sua plataforma e e-commerce."
                    )}
                  </Text>
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </motion.div>
    </motion.div>
  );
};

export default PlannedShipment;
