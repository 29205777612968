import { type FC, memo, useEffect, useState, useCallback } from "react";

import { Controller } from "react-hook-form";

import { Card, CardContent } from "@andes/card";
import { Text } from "@andes/typography";
import TextField from "@andes/textfield";
import { cpfMask } from "../../constants/masks";

import { useI18n } from "nordic/i18n";

import { Col, Row } from "../../components/grid";
import TextFieldWithMask from "../../components/textFieldWithMask";

import type { CardRegistrationDataProps, DataCardRegistration } from "./types";
import Placeholder from "../../components/placeholder";

function setMaskInCode(code?: string): string {
  if (!code) {
    return "";
  }

  if (code.length <= 11) {
    return code.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return code.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

function validateName(value?: string): string | undefined {
  if (!value) {
    return undefined;
  }

  const trimmedValue = value.trim();
  if (!trimmedValue.includes(" ")) {
    return "Por favor, insira o nome completo";
  }

  return undefined;
}

const CardRegistrationData: FC<CardRegistrationDataProps> = ({
  data,
  control,
  formState,
  setValue,
  dataIsLoading,
}) => {
  const { i18n } = useI18n();

  const [code, setCode] = useState<string>("");

  const setValuesData = useCallback(
    (data: DataCardRegistration): void => {
      if (data?.name) {
        setValue("name", data.name);
      }

      if (data?.code) {
        setValue("cpf", data.code);
      }
    },
    [setValue]
  );

  useEffect(() => {
    setCode(setMaskInCode(data?.code ? String(data?.code) : undefined));
    setValuesData(data);
  }, [data, setValuesData, code]);

  return (
    <Card className="mb-4 py-3 px-md-5 px-3">
      <CardContent>
        <Row align="center">
          <Col>
            <Row>
              <Text className="fw-bolder">
                {i18n.gettext("Dados de cadastro")}
              </Text>
              <Col className="mt-4" size={12}>
                <Row>
                  <Col size={12} md>
                    {dataIsLoading ? (
                      <Row>
                        <Col size={12} md={4}>
                          <Placeholder className="mt-md-5" />
                        </Col>
                      </Row>
                    ) : data?.name ? (
                      <TextField
                        className="custom-textfield"
                        label="Nome completo"
                        placeholder="Nome completo"
                        value={data?.name}
                        modifier="readonly"
                      />
                    ) : (
                      <Controller
                        name="name"
                        defaultValue=""
                        control={control}
                        rules={{
                          minLength: {
                            value: 3,
                            message: "O nome deve ter pelo menos 3 caracteres",
                          },
                          validate: (value) => validateName(value),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Nome Completo"
                            placeholder="Marcos da Silva Prestes"
                            modifier={
                              formState.errors.name ? "error" : undefined
                            }
                            helper={formState.errors.name?.message}
                            data-testid="name"
                          />
                        )}
                      />
                    )}
                  </Col>
                  <Col size={12} md>
                    {dataIsLoading ? (
                      <Row>
                        <Col size={12} md={4}>
                          <Placeholder className="mt-md-5" />
                        </Col>
                      </Row>
                    ) : code ? (
                      <TextField
                        className="custom-textfield"
                        label="CPF"
                        placeholder="CPF"
                        value={code}
                        modifier="readonly"
                      />
                    ) : (
                      <Controller
                        name="cpf"
                        defaultValue=""
                        control={control}
                        rules={{
                          minLength: {
                            value: 14,
                            message: "O CPF deve ter pelo menos 14 caracteres",
                          },
                        }}
                        render={({ field }) => (
                          <TextFieldWithMask
                            {...field}
                            maskOptions={cpfMask}
                            label="CPF"
                            placeholder="123.456.789-00"
                            modifier={
                              formState.errors.cpf ? "error" : undefined
                            }
                            helper={formState.errors.cpf?.message}
                            data-testid="cpf"
                          />
                        )}
                      />
                    )}
                  </Col>
                  <Col>
                    <Controller
                      name="rg"
                      defaultValue=""
                      control={control}
                      rules={{
                        minLength: {
                          value: 1,
                          message: "O RG deve ter pelo menos 1 caracter",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="RG"
                          placeholder="12.345.768-9"
                          modifier={formState.errors.rg ? "error" : undefined}
                          helper={formState.errors.rg?.message}
                          data-testid="rg"
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardRegistrationData);
