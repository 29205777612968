import React, { useEffect } from "react";
import { Button } from "@andes/button";
import { Text, Title } from "@andes/typography";
import Card, { CardContent } from "@andes/card";
import { RedirectMessageProps } from "./types";
import DefaultImage from "../../assets/images/default-image.svg";
import "./styles.scss";
const RedirectMessage: React.FC<RedirectMessageProps> = ({
  titleMessage,
  middleMessage,
  buttonMessage,
  iconImage,
  action,
  codeError = null,
}) => {
  const [icon, setIcon] = React.useState(DefaultImage);

  useEffect(() => {
    if (iconImage) {
      setIcon(iconImage);
    }
  }, [iconImage]);

  return (
    <>
      <div className="container containerMessage">
        <Card paddingSize={40}>
          <CardContent>
            <div className="icon">
              <img src={icon} alt="Icon" loading="lazy" />
            </div>
            <Title component="h1" size="m">
              {titleMessage}
            </Title>
            <Text component="p" size="m" color="secondary">
              {middleMessage}
            </Text>
            {buttonMessage != null && (
              <div className="buttonStyled">
                <Button size="large" onClick={() => action && action()}>
                  {buttonMessage}
                </Button>
              </div>
            )}
          </CardContent>
          {codeError && <div className="code-error">{codeError}</div>}
        </Card>
      </div>
    </>
  );
};

export default RedirectMessage;
