import { type FC } from "react";

import { Flex } from "../grid";

import KanguMeliLogo from "../../assets/images/kangu-meli-logo.png";

import "./styles.scss";

const DefaultHeader: FC = () => {
  return (
    <Flex gap={3} align="center" className="bg-white header-default">
      <img
        className="ms-md-5 ps-md-5 ms-4"
        src={KanguMeliLogo}
        alt="Kangu Logo"
        height={35}
      />
    </Flex>
  );
};

export default DefaultHeader;
