import type { PropsData, PropsDataSettingReturn } from "./types";

export default class PropsSettings {
    constructor (public repository: PropsData) {}

    private getClassName(): string {
        return this.repository?.className || "";
    }

    private getClassNamePlaceholderGlow(): string {
        return this.repository?.classNamePlaceholderGlow || "";
    }

    private getColClass(): string {
        return this.repository?.auto ? "col-auto" : `col${this.repository?.size ? `-${this.repository?.size}` : "-12"}`;
    }

    private getSmClass(): string {
        return this.repository?.sm ? `${this.repository?.sm !== true ? `col-sm-${this.repository?.sm}` : `col-sm`}` : "";
    }

    private getMdClass(): string {
        return this.repository?.md ? `${this.repository?.md !== true ? `col-md-${this.repository?.md}` : `col-md`}` : "";
    }

    private getLgClass(): string {
        return this.repository?.lg ? `${this.repository?.lg !== true ? `col-lg-${this.repository?.lg}` : `col-lg`}` : "";
    }

    private getXlClass(): string {
        return this.repository?.xl ? `${this.repository?.xl !== true ? `col-xl-${this.repository?.xl}` : `col-xl`}` : "";
    }

    private getXxlClass(): string {
        return this.repository?.xxl ? `${this.repository?.xxl !== true ? `col-xxl-${this.repository?.xxl}` : `col-xxl`}` : "";
    }

    public getProps(): PropsDataSettingReturn {
        return {
            className: this.getClassName(),
            classNamePlaceholderGlow: this.getClassNamePlaceholderGlow(),
            colClass: this.getColClass(),  
            smClass: this.getSmClass(),
            mdClass: this.getMdClass(),
            lgClass: this.getLgClass(),
            xlClass: this.getXlClass(),
            xxlClass: this.getXxlClass()
        };
    }    
};