import React, { useState, useCallback, useEffect } from "react";

import { useQuery } from "react-query";

import Card, { CardContent } from "@andes/card";
import Typography from "@andes/typography";
import { Thumbnail } from '@andes/thumbnail';
import Smartphone24 from '@andes/icons/Smartphone24';

import { 
    getInfoAccount, 
    checkMfa, 
    sendCodeMfa
} from "../../pages/myAccess/controller";

import { BreakCol, Col, Row } from "../../components/grid";

import type { 
    DataInfoAccount,
    DataCheckMfa, 
    PropsSendDataCode,
    DataSendCodeMfa,
    MfaMethod
} from "./types";

import type { DataFetchService } from "../../services/types";

import { ModalMfa } from "./modalMfa";
import { ModalDisableMfa } from "./modalDisableMfa";
import PillMfa from "./pillMfa";
import Placeholder from "../../components/placeholder";
import SwitchMfa from "./switchMfa";

import ProgressIndicatorTimer from "./progressIndicatorTimer";
import { useAtom } from "jotai";
import { type SnackbarStateProps, snackbarStateAtom } from "../../states/snackbarState";

export const MfaCard: React.FC = () => {
    const [email, setEmail] = useState<string | undefined>("");
    const [phone, setPhone] = useState<string | undefined>("");
    const [typeMfa, setTypeMfa] = useState<MfaMethod>("E");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalDisable, setOpenModalDisable] = useState<boolean>(false);
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
    const [field, setField] = useState<string | undefined>("");
    const [smsActive, setSmsActive] = useState<boolean>(false);
    const [whatsappActive, setWhatsappActive] = useState<boolean>(false);
    const [emailActive, setEmailActive] = useState<boolean>(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState<boolean>(false);
    const [loadingSms, setLoadingSms] = useState<boolean>(false);
    const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
    const [mfaHasChange, setMfaHasChange] = useState<boolean>(false);
    const [timerWhatsapp, setTimerWhatsapp] = useState<number>(0);
    const [timerSMS, setTimerSMS] = useState<number>(0);
    const [timerEmail, setTimerEmail] = useState<number>(0);
    const [timerCounter, setTimerCounter] = useState<number>(30);

    const [snackbar, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);

    const timeoutCode = useCallback((setTimer: React.Dispatch<React.SetStateAction<number>>): void => {
        const myTimeout = setInterval(() => {
            setTimer(prev => {
                if (prev === 0) {
                    clearInterval(myTimeout);
                    return prev;
                    
                }

                return prev - 1;
            });
        }, 1000);
    }, []);

    const handleTimer = useCallback((type: string): void => {
        type === "W" && setTimerWhatsapp(timerCounter);
        type === "S" && setTimerSMS(timerCounter);
        type === "E" && setTimerEmail(timerCounter);

        type === "W" && timeoutCode(setTimerWhatsapp);
        type === "S" && timeoutCode(setTimerSMS);
        type === "E" && timeoutCode(setTimerEmail);
        
        setTimerCounter(prev => {
            if (prev === 90) {
                return prev;
            } 

            return prev + 30;
        });
    }, [timeoutCode, timerCounter]);

    const handleSendMfa = (type: MfaMethod | null): void => {
        if (!type) return;

        setTypeMfa(type);

        const isPhone: boolean = type === "W" || type === "S";

        if (isPhone) {
            setField(phone);
        } else {
            setField(email);
        }

        const data: PropsSendDataCode = {
            metodoTipo: type
        };

        sendCode(data, type);
    };

    const getTratamentEmail = (email?: string): string => {
        if (!email) {
            return "";
        }

        const emailSplited: string[] = email.split("@");

        if (!emailSplited.length) {
            return "";
        }

        const firstsLetters: string = emailSplited[0].slice(0, 2);

        return `${firstsLetters}****@${emailSplited[1]}`;
    };

    const getTratamentPhone = (phone?: string): string => {
        if (!phone) {
            return "";
        }

        const lastLetters: string = phone.slice(-2);
        const firstsLetters: string = phone.slice(0, 2);

        return `+55 ${firstsLetters} *****-**${lastLetters}`;
    };

    const getInfoData = async (): Promise<void> => {
        const { data }: DataInfoAccount = await getInfoAccount();

        if (!data) {
            return;
        }

        setEmail(getTratamentEmail(data?.email));
        setPhone(getTratamentPhone(data?.celular));

        setShowPlaceholder(false);
    };

    const controlLoadingButton = (isLoading: boolean = false, typeLoading: string): void => {
        if (typeLoading === "W") {
            setLoadingWhatsapp(isLoading);
        } else if (typeLoading === "S") {
            setLoadingSms(isLoading);
        } else {
            setLoadingEmail(isLoading);
        }
    };

    const sendCode = async (data: PropsSendDataCode, type: string): Promise<void> => {
        try {
            controlLoadingButton(true, type);

            const response: DataSendCodeMfa = await sendCodeMfa(data);

            if (response?.error) {
                const message: string | undefined = response?.exception?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.data?.ok === false) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "red",
                        show: true,
                    });
                }                
            }

            if (response?.success && response?.data?.ok === true) {
                const message: string | undefined = response?.data?.message;

                if (message) {
                    setSnackbar({
                        ...snackbar,
                        message,
                        color: "green",
                        show: true,
                    });
                }

                setOpenModal(true);
                handleTimer(type);
            }
        } catch (error: unknown) {
            const getError = error as DataFetchService;

            if (getError?.exception) {
                setSnackbar({
                    ...snackbar,
                    message: getError?.exception?.message,
                    color: "red",
                    show: true,
                });
            } else if (error instanceof Error) {
                setSnackbar({
                    ...snackbar,
                    message: error?.message,
                    color: "red",
                    show: true,
                });
            }
        } finally {
            controlLoadingButton(false, type);
        }
    };

    const updateMfaChecked = (response: DataCheckMfa) => {
        setSmsActive(response?.data?.SMS === "A");
        setWhatsappActive(response?.data?.WhatsApp === "A");
        setEmailActive(response?.data?.Email === "A");
    };

    const getMfaActive = async (): Promise<DataCheckMfa | void> => {
        const response: DataCheckMfa | void = await checkMfa();

        if (!response?.data) {
            return;
        }

        updateMfaChecked(response);

        return response;
    };

    const handleClickSwitch = (type: MfaMethod) => {
        setTypeMfa(type);

        if (type === "E") {
            return !emailActive ? handleSendMfa("E") : setOpenModalDisable(true);
        } else if (type === "S") {
            return !smsActive ? handleSendMfa("S") : setOpenModalDisable(true);
        } 

        return !whatsappActive ? handleSendMfa("W") : setOpenModalDisable(true);
    };

    useQuery<void>(
        "getInfoAccountFetch",
        getInfoData,
        { 
            staleTime: 200000 //20 minutes
        }
    );

    const { data } = useQuery<DataCheckMfa | void>(
        "getMfaActiveFetch",
        getMfaActive,
        { 
            staleTime: 300000 //30 minutes
        }
    );

    useEffect(() => {
        if (mfaHasChange) {
            setMfaHasChange(false);
            updateMfaChecked(data as DataCheckMfa);
        }
    }, [data, mfaHasChange]);

    return (
            <>
            <Card className="mb-4">
                <CardContent>
                    <Row
                        gap={3}
                        justify="center"
                        className="pb-3"
                    >
                        <Col
                            size={12}
                        >
                            <Typography 
                                weight="semibold"
                                size="l"
                            >
                                Segurança
                            </Typography>
                        </Col>
                    </Row>
                    <Row
                        align="center"
                        justify="center"
                    >
                        <Col
                            size="12"
                            className="mt-1 mb-2"
                        >
                            <Typography
                                className="text-muted"
                            >
                                Autenticação de 2 fatores (2FA)
                            </Typography>                            
                        </Col>
                        <Col 
                            size={12}
                        >
                            <Row
                                align="center"
                                justify="between"
                            >
                                <Col
                                    auto
                                    md={"10"}
                                    className={!whatsappActive ? "pb-3" : ""}
                                >
                                    <Row>
                                        <Col
                                            auto
                                        >
                                            <Thumbnail
                                                badge={whatsappActive ? <PillMfa /> : <></>}
                                            >
                                                <i 
                                                    className="custom-icon fa-brands fa-whatsapp fa-lg"
                                                />
                                            </Thumbnail>
                                        </Col>
                                        <Col>
                                            <Typography>
                                                Whatsapp
                                            </Typography>
                                            <br></br>
                                            <Typography
                                                className="text-muted"
                                                size="s"
                                            >
                                                {showPlaceholder ? <Placeholder md={2} /> : phone || "Sem celular cadastrado"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="text-center"
                                >
                                    <Row
                                        align="center"
                                        justify="end"
                                        gap={0}
                                    >
                                        <Col
                                            auto
                                        >
                                            <ProgressIndicatorTimer 
                                                totalSeconds={timerWhatsapp}
                                            />             
                                        </Col>
                                        {showPlaceholder 
                                            ? <Placeholder 
                                                size={5} 
                                                classNamePlaceholderGlow="d-flex justify-content-end"
                                            /> 
                                            : <Col
                                                    auto
                                                >
                                                    <SwitchMfa 
                                                        isSendingCode={loadingWhatsapp}
                                                        callback={() => handleClickSwitch("W")}
                                                        isChecked={whatsappActive}
                                                        isDisabled={timerWhatsapp > 0}
                                                    />
                                                </Col>
                                            }                                     
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col 
                            size={12}
                            className="m-0"
                        >
                            <BreakCol 
                                className="border m-0 mb-3"
                            />
                        </Col>                        
                        <Col 
                            size={12}
                            className="m-0"
                        >
                            <Row
                                align="center"
                                justify="between"
                            >
                                <Col
                                    auto
                                    md={10}
                                    className={!smsActive ? "pb-3" : ""}
                                >
                                    <Row>
                                        <Col
                                            auto
                                        >
                                            <Thumbnail
                                                badge={smsActive ? <PillMfa /> : <></>}
                                            >
                                                <Smartphone24 
                                                    className="custom-icon"
                                                />
                                            </Thumbnail>
                                        </Col>
                                        <Col>
                                            <Typography>
                                                SMS
                                            </Typography>
                                            <br></br>
                                            <Typography
                                                className="text-muted"
                                                size="s"
                                            >
                                                {showPlaceholder ? <Placeholder md={2} /> : phone || "Sem celular cadastrado"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="text-center"    
                                >
                                    <Row
                                        align="center"
                                        justify="end"
                                        gap={0}
                                    >
                                        <Col
                                            auto
                                        >
                                            <ProgressIndicatorTimer 
                                                totalSeconds={timerSMS}
                                            />             
                                        </Col>
                                        {showPlaceholder 
                                            ? <Placeholder 
                                                    size={5} 
                                                    classNamePlaceholderGlow="d-flex justify-content-end"
                                                />  
                                            : <Col
                                                    auto
                                                >
                                                    <SwitchMfa 
                                                        isSendingCode={loadingSms}
                                                        callback={() => handleClickSwitch("S")}
                                                        isChecked={smsActive}
                                                        isDisabled={timerSMS > 0}
                                                    />
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col 
                            size={12}
                            className="m-0"
                        >
                            <BreakCol 
                                className="border m-0 mb-3"
                            />
                        </Col>
                        <Col 
                            size={12}
                            className="m-0"
                        >
                            <Row
                                align="center"
                                justify="between"
                            >
                                <Col
                                    auto
                                    md={10}
                                    className={!emailActive ? "pb-3" : ""}
                                >
                                        <Row>
                                        <Col
                                            auto
                                        >
                                            <Thumbnail
                                                badge={emailActive ? <PillMfa /> : <></>}
                                            >
                                                <i 
                                                    className="custom-icon fa-regular fa-envelope"
                                                />
                                            </Thumbnail>
                                        </Col>
                                        <Col
                                            size={4}
                                            md
                                        >
                                            <Typography>
                                                E-mail
                                            </Typography>
                                            <br></br>
                                            <Typography
                                                className="text-muted"
                                                size="s"
                                            >
                                                {showPlaceholder ? <Placeholder md={2} /> : email || "Sem e-mail cadastrado"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="text-center"
                                >
                                    <Row
                                        align="center"
                                        justify="end"
                                        gap={0}
                                    >
                                        <Col
                                            auto
                                        >
                                            <ProgressIndicatorTimer 
                                                totalSeconds={timerEmail}
                                            />             
                                        </Col>
                                        {showPlaceholder 
                                            ? <Placeholder 
                                                    size={5} 
                                                    classNamePlaceholderGlow="d-flex justify-content-end"
                                                /> 
                                            : <Col
                                                    auto
                                                >
                                                    <SwitchMfa 
                                                        isSendingCode={loadingEmail}
                                                        callback={() => handleClickSwitch("E")}
                                                        isChecked={emailActive}
                                                        isDisabled={timerEmail > 0}
                                                    />
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <ModalMfa 
                openModal={openModal} 
                setOpenModal={setOpenModal}
                typeMfa={typeMfa}
                field={field}
                setMfaHasChange={setMfaHasChange}
                timer={typeMfa === "W" && !!timerWhatsapp ? timerWhatsapp : (typeMfa === "S" && !!timerSMS ? timerSMS : timerEmail)}
                setTimer={typeMfa === "W" ? setTimerWhatsapp : (typeMfa === "S" ? setTimerSMS : setTimerEmail)}
                handleTimer={handleTimer}
            />
            <ModalDisableMfa 
                openModalDisable={openModalDisable} 
                setOpenModalDisable={setOpenModalDisable}
                setMfaHasChange={setMfaHasChange}
                typeMfa={typeMfa}
            />
        </>
    );
};
