import LoginService from "../../services/login/loginService";
import type { CheckBondMeliRequest } from "../../services/login/types";

interface Request {
  readonly data: CheckBondMeliRequest;
  readonly showSnackbarErrors: (error: unknown) => void;
}

export const checkBondMeliAction = async (
  request: Request
): Promise<Boolean | null> => {
  try {
    const response = await LoginService.checkBondMeli(request.data);

    if (!response?.data?.success || response?.data?.error) {
      throw new Error(response?.data?.message);
    }

    return response?.data?.bond!;
  } catch (error) {
    request.showSnackbarErrors(error);
    return null;
  }
};
