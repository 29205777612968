import React from 'react';
import Layout from "../../../components/layout";
import AccountConnectionStatus from "../../../containers/accountConnection/accountConnectionStatus";

const AccountConnection: React.FC = () => {
    return (
        <Layout pageTitle="Vinculo de conta">
            <AccountConnectionStatus />
        </Layout>
    );
};

export default AccountConnection;
