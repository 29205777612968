import { useI18n } from "nordic/i18n";

import Card, { CardContent } from "@andes/card";
import { Title } from "@andes/typography";
import { Col, Flex, Row } from "../../../../components/grid";

import TextField from "@andes/textfield";
import TextFieldWithMask from "../../../../components/textFieldWithMask";

import { useFormContext, Controller } from "react-hook-form";
import { FieldsForMeiType } from "../../../../schemas/onboarding/personData";
import { cnpjMask, cpfMask, dateMask } from "../../../../constants/masks";

const FieldsForMEI = () => {
  const { i18n } = useI18n();

  const { control, formState } = useFormContext<FieldsForMeiType>();

  return (
    <>
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext("Preencha os dados do seu MEI")}
      </Title>

      <Card shadow="elevated">
        <CardContent>
          <Flex gap={4} direction="column">
            <Row gap={4}>
              <Col size={12} sm>
                <Controller
                  name="cnpj"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldWithMask
                      {...field}
                      maskOptions={cnpjMask}
                      label="CNPJ"
                      placeholder="00.000.000/0000-00"
                      modifier={formState.errors.cnpj ? "error" : undefined}
                      helper={formState.errors.cnpj?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>

              <Col size={12} sm>
                <Controller
                  name="razaoSocial"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Razão social"
                      placeholder="Razão social da empresa"
                      modifier={
                        formState.errors.razaoSocial ? "error" : undefined
                      }
                      helper={formState.errors.razaoSocial?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>
            </Row>

            <Row gap={4}>
              <Col size={12} sm>
                <Controller
                  name="cpf"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldWithMask
                      {...field}
                      maskOptions={cpfMask}
                      label="CPF do responsável"
                      placeholder="000.000.000-00"
                      modifier={formState.errors.cpf ? "error" : undefined}
                      helper={formState.errors.cpf?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>

              <Col size={12} sm>
                <Controller
                  name="dataNascimento"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldWithMask
                      {...field}
                      maskOptions={dateMask}
                      label="Data de nascimento"
                      placeholder="00/00/0000"
                      modifier={
                        formState.errors.dataNascimento ? "error" : undefined
                      }
                      helper={formState.errors.dataNascimento?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>
            </Row>
          </Flex>
        </CardContent>
      </Card>
    </>
  );
};

export default FieldsForMEI;
