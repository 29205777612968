import Service from "../Service";

import {
  ProfileInfo,
  ResponseProfileChange,
  RequestRating,
  ResponseRating,
} from "./types";

export default class UseDcService {
  static async getProfile(): Promise<ProfileInfo> {
    const service = new Service();
    const response = (await service.doGet(
      "/ca/seller-troca-perfil/load-dados-troca-perfil"
    )) as unknown;

    return response as Promise<ProfileInfo>;
  }

  static async postProfile(): Promise<ResponseProfileChange> {
    const service = new Service();

    const response = (await service.doGet(
      "/ca/seller-troca-perfil/save-dados-troca-perfil"
    )) as unknown;

    return response as Promise<ResponseProfileChange>;
  }

  static async postRating(data: RequestRating): Promise<ResponseRating> {
    const service = new Service();

    const response = (await service.doPost(
      "/ca/seller-troca-perfil/save-avaliacao",
      data
    )) as unknown;

    return response as Promise<ResponseRating>;
  }
}
