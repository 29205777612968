import React, { memo } from "react";

import PropsSettings from "./PropsSettings";

import type { 
    PropsDataSettingReturn, 
    PlaceholderProps,
} from "./types";

const Placeholder: React.FC<PlaceholderProps> = (props) => {
    const propsSetting = new PropsSettings(props);
    const {
        className,
        classNamePlaceholderGlow,
        colClass,
        smClass,
        mdClass, 
        lgClass,
        xlClass,
        xxlClass
    }: PropsDataSettingReturn = propsSetting.getProps();

    return (
        <div className={`placeholder-glow ${classNamePlaceholderGlow}`}>
            <div 
                className={`placeholder rounded ${colClass} ${smClass} ${mdClass} ${lgClass} ${xlClass} 
                ${xxlClass} ${className}`}
                data-testid="placeholder"
            />
        </div>
    );
};

export default memo(Placeholder);