export const phoneMask = {
  mask: "(ZZ) DDDDD-DDDD",
  eager: true,
  tokens: {
    Z: { pattern: /[1-9]/ },
    D: { pattern: /\d/ },
  },
};

export const rgMask = {
  mask: "DD.DDD.DDD-D",
  eager: true,
  tokens: {
    D: { pattern: /\d/ },
  },
};

export const cnpjMask = {
  mask: "DD.DDD.DDD/DDDD-DD",
  eager: true,
  tokens: {
    D: { pattern: /\d/ },
  },
};

export const cpfMask = {
  mask: "DDD.DDD.DDD-DD",
  eager: true,
  tokens: {
    D: { pattern: /\d/ },
  },
};

export const dateMask = {
  mask: "DD/DD/DDDD",
  tokens: {
    D: { pattern: /\d/ },
  },
  eager: true,
};
