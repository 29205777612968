export function get(
  name: string,
  removeCookie: boolean = false
): string | null {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      if (removeCookie) {
        remove(name);
      }

      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
}

export function remove(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function set(name: string, value: string, days?: number): void {
  if (!days) {
    days = 1;
  }

  const date = new Date();
  const toSetTimeRecatpcha = date.getTime() + days * 24 * 60 * 60 * 1000;
  document.cookie = `${name}=${value}; Expires=${new Date(
    toSetTimeRecatpcha
  ).toUTCString()}; Path=/; SameSite=Strict;`;
}
