export const enum AccountStatusMessages {
    AUTHORIZED_TITLE = "Conexão autorizada",
    REGISTRATION_TITLE = "Dados inseridos com sucesso!",
    AUTHORIZED_MESSAGE = "A partir de agora, você já pode usar a Kangu para fazer envios e contar com a eficiência logística do Mercado Livre.",
    ERROR_TITLE = "Não foi possível vincular as suas contas no momento",
    ERROR_MESSAGE = "Em breve entraremos em contato com novas orientações para que você consiga concluir o vínculo. Não se preocupe, até lá você não irá perder o acesso à sua conta Kangu."
}

export const enum AccountUrls {
    ENDERECO_URL = '/sellers/onboarding-address',
    ONBOARDING_URL = '/sellers/onboarding',
    DASHBOARD_URL = '/seller/dashboard'
}
