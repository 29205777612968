import { memo, useCallback, useState, useEffect } from "react";
import { useQueryClient } from "react-query";

import Button from "@andes/button";
import Textfield from "@andes/textfield";

import { Modal } from "../../components/modal";
import { Col, Row } from "../../components/grid";

import { generateToken } from "../../pages/myAccess/controller";

import type {
  PropsModalCreateCredential,
  DataCreateCredential,
  ResponseCreateCredential,
  PropsDataRow,
} from "./types";

import type { DataFetchService } from "../../services/types";
import { useAtom } from "jotai";
import {
  type SnackbarStateProps,
  snackbarStateAtom,
} from "../../states/snackbarState";
import { sanitizeData } from "../../utils/sanitizeData";

export const ModalCreateCredential: React.FC<PropsModalCreateCredential> = memo(
  ({ openModal, setOpenModal, setTableHasChange }) => {
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [nameCredential, setNameCredential] = useState<string>("");
    const [modifierName, setModifierName] = useState<string>("");
    const [helperName, setHelperName] = useState<string>("");

    const queryClient = useQueryClient();
    const [snackbar, setSnackbar] =
      useAtom<SnackbarStateProps>(snackbarStateAtom);

    const handleCloseModal = useCallback((): void => {
      setOpenModal(false);
    }, [setOpenModal]);

    const validateFields = useCallback(
      (data: DataCreateCredential): boolean => {
        if (!data.descricao) {
          setModifierName("error");
          setHelperName("Necessário informar um nome");

          return false;
        }

        setModifierName("completed");
        setHelperName("");

        return true;
      },
      []
    );

    const getData = useCallback((): DataCreateCredential => {
      return {
        descricao: nameCredential.trim(),
      };
    }, [nameCredential]);

    const updateCacheQuery = useCallback(
      ({ data }: ResponseCreateCredential) => {
        if (!data) {
          return;
        }

        const listCredentials = queryClient.getQueryData<PropsDataRow[]>([
          "listCredentialsFetch",
        ]);

        const { id, descricao, dh_inc, status, token, valid } = data;

        const newCredential = {
            id,
            descricao,
            dh_inc,
            status,
            token,
            dt_exp: valid,
        };
        const newCredentials = listCredentials ? [newCredential, ...listCredentials] : [newCredential];

        queryClient.setQueryData(["listCredentialsFetch"], newCredentials);
      },
      [queryClient]
    );

    const handleCreateCredential = useCallback(async (): Promise<void> => {
      try {
        setLoadingButton(true);

        const data: DataCreateCredential = getData();

        if (!validateFields(data)) {
          setLoadingButton(false);

          return;
        }

        const response: ResponseCreateCredential = await generateToken(data);

        if (response?.error) {
          const message: string | undefined = response?.exception?.message;

          if (message) {
            setSnackbar({
              ...snackbar,
              message,
              color: "red",
              show: true,
            });
          }
        }

        if (response?.success) {
          const message: string = "Credencial cadastrada com sucesso.";

          if (message) {
            setSnackbar({
              ...snackbar,
              message,
              color: "green",
              show: true,
            });
          }

          updateCacheQuery(response);

          handleCloseModal();

          setTableHasChange(true);
        }
      } catch (error: unknown) {
        const getError = error as DataFetchService;

        if (getError?.exception) {
          setSnackbar({
            ...snackbar,
            message: getError?.exception?.message,
            color: "red",
            show: true,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }, [
      getData,
      validateFields,
      setSnackbar,
      snackbar,
      updateCacheQuery,
      handleCloseModal,
      setTableHasChange,
    ]);

    const handleChangeName = (
      e: Event & { target: HTMLInputElement }
    ): void => {
      const name: string = sanitizeData(e.target.value);

      console.log("name", name);

      if (!name) {
        setModifierName("error");
        setHelperName("Necessário informar um nome");
      } else {
        setModifierName("completed");
        setHelperName("");
      }

      setNameCredential(name);
    };

    const cleanStates = useCallback((): void => {
      if (!openModal) {
        setNameCredential("");
        setModifierName("");
        setHelperName("");
      }
    }, [openModal]);

    useEffect(() => {
      cleanStates();
    }, [cleanStates]);

    return (
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        title="Nova Credencial"
        type="medium"
        className="py-2"
        headerClassName="text-center"
      >
        <Col size={12}>
          <Row align="end" justify="center" gap="3">
            <Col size={12}>
              {modifierName ? (
                <Textfield
                  className="flex-fill"
                  label="Nome"
                  placeholder="Nome"
                  value={nameCredential}
                  onChange={handleChangeName}
                  helper={helperName}
                  modifier={modifierName}
                />
              ) : (
                <Textfield
                  className="flex-fill"
                  label="Nome"
                  placeholder="Nome"
                  value={nameCredential}
                  onChange={handleChangeName}
                />
              )}
            </Col>
            <Col auto className="pt-3 pb-2">
              <Button
                type="submit"
                fullWidth
                className="button-quiet-orange rounded"
                hierarchy="quiet"
                srAnnouncement="Criar credencial"
                loading={loadingButton}
                disabled={loadingButton}
                onClick={handleCreateCredential}
              >
                Criar credencial
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal>
    );
  }
);
