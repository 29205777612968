
import { memo } from "react";

import { ModalProps, SizesClass } from "./types";

import "./style.scss";

import Sizes from "./Sizes"

export const Modal = memo((
    { 
        children,
        open = false, 
        onClose, 
        title = undefined,
        id = undefined,
        closeButtonLabel = undefined,
        type = "small",
        className = undefined,
        bodyClassName = undefined,
        headerClassName = undefined
    }: ModalProps
) => {
    const sizes = new Sizes();
    const typeSize: SizesClass = sizes.getSize(type);

    return (
        open && (
            <>
                <div className="modal-backdrop show" data-testid="modal-backdrop"></div>
                <div className="modal fade show" id={id || ""} data-testid="modal">
                    <div className={`modal-dialog modal-${typeSize} modal-dialog-centered`} data-testid="modal-dialog">
                        <div className={`modal-content ${className || ""}`}>
                            <div className={`modal-header ${headerClassName || ""} `}>
                                {title &&
                                    <div className="col">
                                        <h5 className="modal-title modal-title-custom">
                                            {title}
                                        </h5>
                                    </div>
                                }                            
                            {closeButtonLabel 
                                ? (
                                    <button type="button" className="close" data-testid="close-button" onClick={onClose}>
                                        <span aria-hidden="true">{closeButtonLabel}</span>
                                    </button>                                    
                                )
                                : (
                                    <button type="button" data-testid="close-button" className="btn-close" onClick={onClose}></button>
                                )
                            }                            
                            </div>
                            <div className={`modal-body ${bodyClassName || ""}`}>{children}</div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});
