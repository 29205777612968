import Head from "../../components/head";
import Navbar from "../../components/navbar";
import Header from "../../components/header";
import ActionCard from "../../containers/useDc/actionCard";
import { Container } from "../../components/grid";
import type { FC } from "react";

const UseDc: FC = () => {
  return (
    <>
      <Head pageTitle="Utilizar Declaração de conteúdo" />

      <Navbar />

      <Header />

      <Container className="p-4 px-md-5">
        <ActionCard />
      </Container>
    </>
  );
};

export default UseDc;
