import { Profile } from "../../containers/myAccess/profile";
import { ChangePassword } from "../../containers/myAccess/changePassword";
import { MfaCard } from "../../containers/myAccess/mfaCard";
import { Credentials } from "../../containers/myAccess/credentials";

import { Container } from "../../components/grid";

import "./style.scss";

const MyAccess: React.FC = () => {
    return (
        <Container 
            className="mb-4"
        >
            <Profile />
            <ChangePassword />
            <MfaCard />
            <Credentials />
        </Container>
    );
};

export default MyAccess;
