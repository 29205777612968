import Service from "../Service";

export default class AccountConnectionService {
    static async getStatusLoginConnection(): Promise<{isAuthorized: boolean}> {
        const service = new Service();
        const { data } = (await service.doGet(
            "/auth/mercadolivre/seller-verify"
        )) as any;

        return data as Promise<any>;

    }
}
