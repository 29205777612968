import type { FC } from "react";

import { BreakCol, Col, Flex, Row } from "../../components/grid";

import { useI18n } from "nordic/i18n";

import { Thumbnail } from "@andes/thumbnail";
import Package20 from "@andes/icons/Package20";
import PostOfficeShipping24 from "@andes/icons/PostOfficeShipping24";
import ChevronRight20 from "@andes/icons/ChevronRight20";
import { Text } from "@andes/typography";

import type { TypeAccountEnum } from "./types";

interface TypeAccountProps {
  readonly typeAccountSelected?: TypeAccountEnum;
  readonly setTypeAccountSelected: (typeAccount: TypeAccountEnum) => void;
}

export const TYPE_ACCOUNT = {
  seller: "S",
  others: "O",
};

const TypeAccount: FC<TypeAccountProps> = ({
  typeAccountSelected,
  setTypeAccountSelected,
}) => {
  const { i18n } = useI18n();

  return (
    <Row>
      <BreakCol className="border mt-3 mb-1" />
      <Col
        size={12}
        className="col-type-account"
      >
        <Row align="center">
          <Col auto>
            <Thumbnail
              className={`custom-thumbnail selected`}
            >
              <PostOfficeShipping24 />
            </Thumbnail>
          </Col>
          <Col>
            <Row gap={0}>
              <Flex justify="between">
                <Col>
                  <Text className="text-muted text-mfa-option">
                    {i18n.gettext("Ponto Kangu ou motorista")}
                  </Text>
                </Col>
              </Flex>
            </Row>
          </Col>
          <Col auto className={`custom-chevron selected`}>
            <ChevronRight20 className="icon-chevron" />
          </Col>
        </Row>
      </Col>
      <BreakCol className="border mt-3 mb-1" />
    </Row>
  );
};

export default TypeAccount;
