import { type FC, memo, useEffect, useState } from "react";

import { useI18n } from "nordic/i18n";

import { Text, Title } from "@andes/typography";

import { Col, Flex, Row } from "../../../components/grid";

import CardLogin from "../../../components/login/cardLogin";

import { get } from "../../../utils/cookies";

import type { ResponseLogin } from "./types";

export interface FirstStepProps {
  readonly setCurrentUser: (value: string) => void;
  readonly setResponseLogin: (value: ResponseLogin) => void;
}

const FirstStep: FC<FirstStepProps> = ({
  setCurrentUser,
  setResponseLogin,
}) => {
  const [siteKey, setSiteKey] = useState<string>("");
  const [meliCode, setMeliCode] = useState<string>("");

  const { i18n } = useI18n();

  useEffect(() => {
    if (!siteKey) {
      setSiteKey(get("utm_resource")!);
    }

    if (!meliCode) {
        setMeliCode(get("utm")!);
    }
  }, [meliCode, siteKey]);

  return (
    <>
      <Col size={12} md={6} lg={5} className="px-lg-5 mx-xl-5 mt-md-5 pt-lg-2">
        <Flex direction="column">
          <Title className="custom-text" component="h2">
            {i18n.gettext(
              "Para vincular sua conta do Mercado Livre, é necessário que você acesse sua conta Kangu."
            )}
          </Title>
          <Text>
            {i18n.gettext(
              "Para a vinculação ser bem sucedida, é necessário que as duas contas estejam cadastradas com o mesmo documento (CPF ou CNPJ). "
            )}
          </Text>
        </Flex>
      </Col>
      <Col size={12} md={6} lg={5} className="px-lg-5 mx-xl-5 mt-md-5 pt-lg-2">
        <CardLogin
          siteKey={siteKey}
          setCurrentUser={setCurrentUser}
          setResponseLogin={setResponseLogin}
        />
      </Col>
    </>
  );
};

export default memo(FirstStep);
