import { type FC, memo, useCallback, useId } from "react";

import { useAtom, useSetAtom } from "jotai";

import { Col, Flex, Row } from "../grid";

import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";
import { Card, CardContent } from "@andes/card";
import { Text, Title } from "@andes/typography";
import ChevronRight20 from "@andes/icons/ChevronRight20";
import Mail24 from "@andes/icons/Mail24";
import Answer24 from "@andes/icons/Answer24";
import { Thumbnail } from "@andes/thumbnail";

import { sendMfa } from "../../pages/loginMeli/login/controller";

import { snackbarStateAtom } from "../../states/snackbarState";
import { stepsStateAtom } from "../../states/steps";
import { type DataMfaProps, dataMfaStateAtom } from "../../states/dataMfa";

import type { DataFetchService } from "../../services/types";
import type { Options } from "../../containers/login/types";

interface CardOptionsMfaProps {
  readonly option: string;
  readonly value: string;
  readonly tempToken: string;
  readonly isLoading: boolean;
  readonly setIsLoading: (value: boolean) => void;
}

const options: Options = {
  E: {
    text: "e-mail",
    title: "E-mail",
    icon: <Mail24 />,
  },
  W: {
    text: "telefone",
    title: "WhatsApp",
    icon: <i className="fa-brands fa-whatsapp custom-icon"></i>,
  },
  S: {
    text: "telefone",
    title: "SMS",
    icon: <Answer24 />,
  },
};

const CardMfa: FC<CardOptionsMfaProps> = ({
  option,
  value,
  tempToken,
  isLoading,
  setIsLoading,
}) => {
  const setSnackbar = useSetAtom(snackbarStateAtom);

  const [dataMfa, setDataMfa] = useAtom<DataMfaProps>(dataMfaStateAtom);

  const setStep = useSetAtom(stepsStateAtom);

  const showSnackbarErrors = useCallback(
    (error: unknown) => {
      const getError = error as DataFetchService;
      let message = "";

      if (getError?.exception) {
        message = getError?.exception?.message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setSnackbar((prev) => ({
        ...prev,
        message,
        color: "red",
        show: true,
      }));
    },
    [setSnackbar]
  );

  const fetchSendMfa = useCallback(async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      setDataMfa({
        type: option as DataMfaProps["type"],
        title: options[option as keyof Options].title as DataMfaProps["title"],
        text: options[option as keyof Options].text,
        value,
        tempToken,
      });
      const response = await sendMfa({
        temp_token: tempToken,
        opcao_mfa: option,
      });

      if (!response?.data?.success || response?.data?.error) {
        throw new Error(response?.data?.message);
      }

      setSnackbar((prev) => ({
        ...prev,
        message: response?.data?.message as string,
        color: "green",
        show: true,
      }));

      setStep({ current: 3 });
    } catch (error) {
      showSnackbarErrors(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    setIsLoading,
    setDataMfa,
    option,
    value,
    tempToken,
    setSnackbar,
    setStep,
    showSnackbarErrors,
  ]);

  return (
    <Card
      key={useId()}
      className={`shadow-none card-option-mfa ${isLoading ? "blocked" : ""}`}
      onClick={fetchSendMfa}
    >
      <CardContent>
        <Row align="center">
          <Col auto>
            <Thumbnail className="custom-thumbnail">
              {
                options[option as keyof Options].icon as React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              }
            </Thumbnail>
          </Col>
          <Col>
            <Row gap={0}>
              <Col size={12}>
                <Title className="custom-text title-mfa-option" component="h4">
                  {options[option as keyof Options].title}
                </Title>
              </Col>
              <Flex justify="between">
                <Col>
                  <Text className="text-muted text-mfa-option">{`Vamos enviar um código para o ${
                    options[option as keyof Options].text
                  } ${value}`}</Text>
                </Col>
              </Flex>
            </Row>
          </Col>
          <Col auto>
            {isLoading && dataMfa.type === option ? (
              <ProgressIndicatorCircular
                className="ms-1"
                srLabel="Enviando código..."
                srAnnouncement="Enviando código..."
                size="xsmall"
              />
            ) : (
              <ChevronRight20 className="icon-chevron" />
            )}
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};

export default memo(CardMfa);
