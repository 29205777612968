/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

import { useAtom } from "jotai";
import Card, { CardContent } from "@andes/card";
import Typography, { Title, Text } from "@andes/typography";
import DispatchTime24 from "@andes/icons/DispatchTime24";
import Close24 from "@andes/icons/Close24";
import { Flex } from "../../components/grid";
import { Button } from "@andes/button";
import {
  orderListStateAtom,
  type OrderListStateProps,
} from "../../states/orderList";

export const Banner: FC = () => {
  const [orderListState, setOrderListState] =
    useAtom<OrderListStateProps>(orderListStateAtom);

  const handleBanner = () => {
    setOrderListState((prev) => ({
      ...prev,
      isHighLight: false,
    }));
  };
  return (
    <>
      <Card
        color="#3483FA"
        paddingSize={16}
        shadow="flat"
        className={orderListState.isHighLight ? "highlight-order-list" : ""}
      >
        <CardContent>
          <Flex direction="row" justify="start" align="start">
            <DispatchTime24 color="#3483FA" />
            <Flex direction="row" justify="between" fullWidth>
              <div>
                <Title component="h3" size="xs">
                  Postagem Planejada
                </Title>
                <Text component="span" size="m">
                  Você está visualizando datas de postagem posteriores a hoje
                  devido à alta demanda no período.{" "}
                  <Typography
                    component="a"
                    color={"accent" as any}
                    href="https://portal.kangu.com.br/sellers/planned-shipment"
                    target="_blank"
                  >
                    Saiba mais
                  </Typography>
                </Text>
                <br />
                {orderListState.isHighLight && (
                  <Button
                    type="button"
                    size="medium"
                    className="custom-button-banner"
                    onClick={handleBanner}
                  >
                    Entendi
                  </Button>
                )}
              </div>
              <a
                className="icon-dispatch-time"
                onClick={() =>
                  setOrderListState((prev) => ({
                    ...prev,
                    isHighLight: false,
                  }))
                }
              >
                <Close24 />
              </a>
            </Flex>
          </Flex>
        </CardContent>
      </Card>
    </>
  );
};
