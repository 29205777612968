import {
  ErrorCodes,
  ErrorLinks,
  ErrorTexts,
  ErrorTitles,
  ErrorButtons,
} from "./enum";

interface ErrorMessage {
  title: string;
  text: string;
  link: string;
  button: string;
}

export const getErrorMessage = (code: string): ErrorMessage => {
  switch (code) {
    case ErrorCodes.MATCH_FAILED_USER_MATCHED:
      return {
        title: ErrorTitles.MATCH_FAILED_USER_MATCHED,
        text: ErrorTexts.MATCH_FAILED_USER_MATCHED,
        link: ErrorLinks.MATCH_FAILED_USER_MATCHED,
        button: ErrorButtons.MATCH_FAILED_USER_MATCHED,
      };
    case ErrorCodes.MATCH_FAILED_EMAIL_MATCHED:
      return {
        title: ErrorTitles.MATCH_FAILED_EMAIL_MATCHED,
        text: ErrorTexts.MATCH_FAILED_EMAIL_MATCHED,
        link: ErrorLinks.MATCH_FAILED_EMAIL_MATCHED,
        button: ErrorButtons.MATCH_FAILED_EMAIL_MATCHED,
      };
    case ErrorCodes.MATCH_FAILED_NIVEL:
      return {
        title: ErrorTitles.MATCH_FAILED_NIVEL,
        text: ErrorTexts.MATCH_FAILED_NIVEL,
        link: ErrorLinks.MATCH_FAILED_NIVEL,
        button: ErrorButtons.MATCH_FAILED_NIVEL,
      };
    case ErrorCodes.MATCH_FAILED_DATA:
      return {
        title: ErrorTitles.MATCH_FAILED_DATA,
        text: ErrorTexts.MATCH_FAILED_DATA,
        link: ErrorLinks.MATCH_FAILED_DATA,
        button: ErrorButtons.MATCH_FAILED_DATA,
      };
    default:
      return {
        title: ErrorTitles.MATCH_FAILED_DEFAULT,
        text: ErrorTexts.MATCH_FAILED_DEFAULT,
        link: ErrorLinks.MATCH_FAILED_DEFAULT,
        button: ErrorButtons.MATCH_FAILED_DEFAULT,
      };
  }
};
