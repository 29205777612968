import { atom } from "jotai";

export type DataMfaProps = {
  type: "S" | "E" | "W";
  title: "SMS" | "E-mail" | "WhatsApp";
  text: string;
  value: string;
  tempToken: string;
};

export const dataMfaStateAtom = atom<DataMfaProps>({
  type: "S",
  title: "SMS",
  text: "",
  value: "",
  tempToken: "",
});
