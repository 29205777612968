import type { FC } from "react";

import { Container } from "../../components/grid";
import FormUpgradeNivel from "../../containers/upgradeNivel/formUpgradeNivel";

import "./styles.scss";

const Index: FC = () => {
  return (
    <Container className="position-relative page-upgrade-nivel">
      <FormUpgradeNivel />
    </Container>
  );
};

export default Index;
