import { type FC, memo } from "react";

import { ProgressButton } from "@andes/button";

import { useI18n } from "nordic/i18n";

import { Col, Row } from "../../components/grid";

import type { ButtonSendDocProps } from "./types";

const ButtonSendDoc: FC<ButtonSendDocProps> = ({ isLoading }) => {
  const { i18n } = useI18n();

  return (
    <Col size={12} className="mb-md-4">
      <Row justify="end">
        <Col md="auto" size={12}>
          <ProgressButton
            hierarchy="loud"
            className="rounded px-5 w-100"
            progressLabel="Enviando documentos..."
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {i18n.gettext("Enviar documentos")}
          </ProgressButton>
        </Col>
      </Row>
    </Col>
  );
};

export default memo(ButtonSendDoc);
