import { Flex } from "../grid";

import { Text } from "@andes/typography";
import Avatar20 from "@andes/icons/Avatar20";

import KanguGlogo from "../../assets/images/kangu-g-logo.svg";
import { useCallback, useEffect, useState } from "react";
import { getDataProfile } from "../../pages/myAccess/controller";
import type { ProfileInfo } from "./types";
import Avatar from "../avatar";
import { fetchAvatar } from "../../services/avatar";

import "./styles.scss";
import { navbarStateAtom, type NavbarStateProps } from "../../states/navbar";
import { useAtom } from "jotai";

const Navbar: React.FC = () => {
  const [selectedAvatar, setSelectedAvatar] = useState<
    string | null | ArrayBuffer
  >(null);
  const [, setAvatarChanged] = useState<string | null | ArrayBuffer>(null);
  const [navbarState, setNavbarState] =
    useAtom<NavbarStateProps>(navbarStateAtom);

  const handleAvatarImage = async (avatar: string): Promise<void> => {
    const selectedAvatar = await fetchAvatar(avatar);
    setSelectedAvatar(selectedAvatar);
  };

  const RetrieveAvatarInfo = useCallback(async (): Promise<void> => {
    const { data }: ProfileInfo = await getDataProfile();

    if (data) {
      setNavbarState({
        ...navbarState,
        descricao: data.descricao,
        email: data.email,
        funcao: data.funcao,
      });

      handleAvatarImage(data.avatar as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    RetrieveAvatarInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      gap={3}
      align="center"
      justify="between"
      className="bg-white px-3 py-2 rounded-0 navbarCustom"
    >
      <img src={KanguGlogo} alt="Kangu Logo" height={35} />

      <Flex gap={3} direction="row" align="center">
        <Text size="s" color="secondary">
          Olá, {navbarState?.descricao}
        </Text>

        {selectedAvatar ? (
          <Avatar
            selectedAvatar={selectedAvatar}
            setSelectedAvatar={setSelectedAvatar}
            setAvatarChanged={setAvatarChanged}
          />
        ) : (
          <Avatar20 />
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar;
