import { atomWithStorage } from "jotai/utils";

export type HeaderStateProps = {
  state: "default" | "error" | "success";
};

export const headerStateAtom = atomWithStorage<HeaderStateProps>(
  "headerState",
  {
    state: "default",
  }
);
