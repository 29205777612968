import MyAccessService from "../../services/myAccess/MyAccessService";

import type { 
    DataProfileChange,
    ProfileChange,
    ResponseSaveProfile, 
    DataListCredentials, 
    DataChangePassword, 
    ResponseChangePassword,
    ResponseRevokeCredential,
    DataCreateCredential,
    ResponseCreateCredential,
    DataRevokeToken,
    DataInfoAccount,
    DataFlagMfa,
    DataCheckMfa,
    PropsSendDataCode,
    DataSendCodeMfa,
    PropsValidateMfa,
    DataValidateMfa,
    PropsDisableMfa,
    DataDisableMfa
} from "../../containers/myAccess/types";

export const getDataProfile = async (): Promise<DataProfileChange> => {
    const response = await MyAccessService.getProfile();
    return response;
};

export const saveProfile = async (data: ProfileChange): Promise<ResponseSaveProfile> => {
    const response = await MyAccessService.saveProfile(data);
    return response;
};

export const listCredentials = async (): Promise<DataListCredentials> => {
    const response = await MyAccessService.listCredentials();
    return response;
};

export const changePassword = async (data: DataChangePassword): Promise<ResponseChangePassword> => {
    const response = await MyAccessService.changePassword(data);
    return response;
};

export const revokeToken = async(data: DataRevokeToken): Promise<ResponseRevokeCredential> => {
    const response = await MyAccessService.revokeToken(data);
    return response;
};

export const generateToken = async(data: DataCreateCredential): Promise<ResponseCreateCredential> => {
    const response = await MyAccessService.generateToken(data);
    return response;
};

export const getInfoAccount = async(): Promise<DataInfoAccount> => {
    const response = await MyAccessService.getInfoAccount();
    return response;
};

export const getFlagMfa = async(): Promise<DataFlagMfa> => {
    const response = await MyAccessService.getFlagMfa();
    return response;
};

export const checkMfa = async(): Promise<DataCheckMfa> => {
    const response = await MyAccessService.checkMfa();
    return response;
};

export const sendCodeMfa = async(data: PropsSendDataCode): Promise<DataSendCodeMfa> => {
    const response = await MyAccessService.sendCodeMfa(data);
    return response;
};

export const validateMfa = async(data: PropsValidateMfa): Promise<DataValidateMfa> => {
    const response = await MyAccessService.validateMfa(data);
    return response;
};

export const disableMfa = async(data: PropsDisableMfa): Promise<DataDisableMfa> => {
    const response = await MyAccessService.disableMfa(data);
    return response;
};