import { FC, memo, useCallback } from "react";

import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";

import type { PropsProgressIndicatorTimer } from "./types";

const ProgressIndicatorTimer: FC<PropsProgressIndicatorTimer> = ({ totalSeconds }) => {
    const getValueSeconds = useCallback((totalSeconds: number): number => {
        if (totalSeconds <= 30) {
            return totalSeconds * 100 / 30;
        } else if (totalSeconds > 30 && totalSeconds <= 60) {
            return totalSeconds * 100 / 60;
        } 

        return totalSeconds * 100 / 90;
    }, []);
    
    const value: number = getValueSeconds(totalSeconds);

    if (totalSeconds === 0) {
        return <></>;
    }

    return (
        <ProgressIndicatorCircular
            srLabel="2FA Tempo restante para reenviar o código"
            value={value}
            type="determinate"
            continuous={true}
            label={totalSeconds.toString()}
            className="custom-progress-indicator-circular-determinate"
        />
    );
};

export default memo(ProgressIndicatorTimer);
