import { useEffect, useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import Card, { CardContent } from "@andes/card";
import Typography, { Title, Text } from "@andes/typography";
import { Col, Flex, Row } from "../../../components/grid";

import { Button } from "@andes/button";
import { useWizard } from "react-use-wizard";
import { onboardingStateAtom } from "../../../states/onboarding";
import { useAtom } from "jotai";
import {
  saveOnboardingData,
  verifyOnboardingData,
} from "../../../pages/onboarding/controller";
import {
  OnboardingProgressProps,
  OnboardingResponseProps,
} from "../../../services/onboarding/types";
import { ShippingTypeEnum } from "../shippingType/enum";
import { PersonTypeEnum } from "../personType/enum";

const Summary: FC = () => {
  const { i18n } = useI18n();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { previousStep } = useWizard();

  const [onboardingState, setOnboardingState] = useAtom(onboardingStateAtom);

  const handlePreviousStep = () => {
    setOnboardingState((prevState) => ({
      ...prevState,
      stepIndex: 3,
      displayFooter: true,
    }));

    previousStep();
  };

  const handleFinishOnboarding = async () => {
    try {
      if (
        !onboardingState.shippingType ||
        !onboardingState.personType ||
        !onboardingState.personData
      ) {
        setErrorMessage("Erro ao verificar dados de onboarding");
        throw new Error("Erro ao verificar dados de onboarding");
      }

      setErrorMessage("");
      setButtonLoading(true);

      const { cnpj, razaoSocial, cpf, dataNascimento } =
        onboardingState.personData;

      const onboardingData: OnboardingProgressProps = {
        data: {
          st_declaracao:
            onboardingState.personType === PersonTypeEnum.CNPJ ? "N" : "S",
          tp_perfil: onboardingState.personType,
          doc:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? cnpj
              : cpf,
          razao_social: razaoSocial,
          dt_nasc: dataNascimento,
          doc_resp:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? cpf
              : undefined,
          dt_nasc_resp:
            onboardingState.personType === PersonTypeEnum.CNPJ ||
            onboardingState.personType === PersonTypeEnum.MEI
              ? dataNascimento
              : undefined,
        },
      };

      const { data: verifyData }: OnboardingResponseProps =
        await verifyOnboardingData(onboardingData);

      if (!verifyData?.success) {
        setErrorMessage(
          verifyData?.message || "Erro ao verificar dados de onboarding"
        );
        throw new Error(
          verifyData?.message || "Erro ao verificar dados de onboarding"
        );
      }

      const { data: saveData }: OnboardingResponseProps =
        await saveOnboardingData(onboardingData);

      if (!saveData?.success) {
        setErrorMessage(
          saveData?.message || "Erro ao salvar dados de onboarding"
        );
        throw new Error(
          saveData?.message || "Erro ao salvar dados de onboarding"
        );
      }

      window.location.href = "/sellers/onboarding-address";
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    setOnboardingState((prevState) => ({
      ...prevState,
      displayFooter: false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-4 summary">
      <Card shadow="elevated" paddingSize={40}>
        <CardContent>
          <Flex gap={3} direction="column">
            <Title component="h6" size="l" weight="semibold">
              {i18n.gettext(
                `Você selecionou envios com ${
                  onboardingState.shippingType === ShippingTypeEnum.DC
                    ? "Declaração de Conteúdo e Nota Fiscal"
                    : "Nota Fiscal"
                }`
              )}
            </Title>

            <Text size="m">
              {i18n.gettext(
                "Você não vai poder mudar esta escolha depois. Se quiser, antes de continuar, altere os tipos de documentos de envio."
              )}
            </Text>

            <Text size="s" color="secondary">
              {i18n.gettext(
                "Alterações feitas na sua conta Mercado Livre substituirão as informações da sua conta Kangu. "
              )}
              <Typography
                component="a"
                color="link"
                href="#"
                target="_blank"
                size="s"
              >
                {i18n.gettext("Saiba mais")}
              </Typography>
            </Text>

            <Row gap={3} align="center" className="mt-3">
              <Col size={12} sm="auto" className="order-1 order-sm-0">
                <Button
                  size="large"
                  hierarchy="transparent"
                  fullWidth
                  onClick={() => handlePreviousStep()}
                >
                  {i18n.gettext("Alterar documentos")}
                </Button>
              </Col>

              <Col size={12} sm="auto" className="order-0 order-sm-1">
                <Button
                  loading={buttonLoading}
                  size="large"
                  fullWidth
                  onClick={() => handleFinishOnboarding()}
                  srAnnouncement={i18n.gettext("Carregando...")}
                >
                  {i18n.gettext("Continuar")}
                </Button>
              </Col>

              {errorMessage && (
                <Col size={12} className="mt-4">
                  <Text size="s" weight="semibold" color="negative">
                    {i18n.gettext(errorMessage)}
                  </Text>
                </Col>
              )}
            </Row>
          </Flex>
        </CardContent>
      </Card>
    </div>
  );
};

export default Summary;
