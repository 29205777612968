import React, { memo } from "react";

import { Row, Col } from "../../components/grid";

const DataRow: React.FC<{ data: string }> = ({ data }) => {
    return (
        <Col>
            <Row
                align="center"
                justify="center"
                gap={0}
            >
                <Col
                    auto
                    className="pt-4 pt-md-0"
                >
                    {data}
                </Col>
            </Row>
        </Col>
    );
};

export default memo(DataRow);
